import { CandlestickData, LineData } from 'lightweight-charts';
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { /*prices, */Price, selectAvailableQuotes, selectExchanges, selectPrices } from "../../reducers/priceReducer";
import SymbolPriceTradingViewChartMemo from "./SymbolPriceTradingViewChartMemo";
import SymbolPriceTradingViewChartMemo2, { TradeConfig } from "./SymbolPriceTradingViewChartMemo2";
import { DateTime } from "luxon";
import { getCommonDecimals } from '../../utils/NumberUtils';
import { GetPrimaryQuote } from '../../utils/PriceUtils';
import { selectLiveTradeSymbols, selectTrades, status } from "../../reducers/terminalReducer";
import { OrderStatus } from '../../enums/OrderStatus';
import { OrderDirection } from '../../enums/OrderDirection';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Exchange, ExchangeEnum } from '../../enums/Exchange';
import { MarketType } from '../../enums/MarketType';
import { RootState } from '../../store';
import { BotTrade } from '../../types/BotTrade';

interface ComponentProps {
  symbol: string;
  showEMA?: boolean;
  height?: number;
  showAsPercent?: boolean;
  relativeToBuyPrice?: boolean;
  hideExchangeSelection?: boolean;
}

function SymbolPriceTradingViewChart(props: ComponentProps) {

    const availableQuotes = useSelector(selectAvailableQuotes);
    const exchanges = useSelector(selectExchanges);

    const prices:Price[] = useSelector(
        (state) => selectPrices(state as RootState, props.symbol),
        shallowEqual
      );

    //const currentPrices = useSelector(prices);
    const trades:BotTrade[] = useSelector(
        (state) => selectTrades(state as RootState, props.symbol),
        shallowEqual
      );

    let arr = GetPrimaryQuote(props.symbol, exchanges, availableQuotes);
    let exchange = arr[0];
    let quote = arr[1];

    let chartPrices = prices?.find(p => p.exchange === exchange && p.quote === quote)?.history || [];

    const [chartIndex, setChartIndex] = React.useState(-1);

    //const trades = useSelector(selectLiveTradeSymbols, shallowEqual);

    let tradeConfig:TradeConfig = {
        takeProfits: [],
    }


    // Store a chart index in a memo field
    //console.log(`Updating chart for ${props.symbol} at ${new Date().valueOf()}`)


    //const [chartIndex, setChartIndex] = React.useState(trades?.findIndex(t => t.averagePrice !== undefined && t.averagePrice > 0.0));

    if (trades?.length > 0) {

        if (chartIndex === -1) {
            if (trades?.findIndex(t => t.averagePrice !== undefined && t.averagePrice > 0.0) !== -1) {
                setChartIndex(trades?.findIndex(t => t.averagePrice !== undefined && t.averagePrice > 0.0));
            }
            
        }

        if (chartIndex !== -1 && trades[chartIndex]) {       
            // Set the buy timestamp
            tradeConfig.buyTimestamps = [];
            tradeConfig.buyTimestamps.push(DateTime.fromISO(trades[chartIndex].start).valueOf());
            if ((trades[chartIndex].increaseBuyOrderResponses || []).length > 0) {
                (trades[chartIndex].increaseBuyOrderResponses || []).filter(r => r).forEach((r) => {
                    (tradeConfig.buyTimestamps || []).push(DateTime.fromMillis(r.timeStampMs).valueOf());
                });
            }

            // Set the buy price
            tradeConfig.buyPrice = trades[chartIndex].averagePrice;

            // Set the stop loss
            tradeConfig.stopLoss = trades[chartIndex].stopLossTarget;

            // Set the trailing stop loss (coming soon)
            tradeConfig.trailingStopLoss = trades[chartIndex].trailingStopLossTarget;

            if (trades[chartIndex].status === "Cleaned up") {
                // This isn't right, but will have to do for now
                if (trades[chartIndex].cleanUpFinalised !== undefined) {
                    tradeConfig.sellTimestamp = DateTime.fromISO(trades[chartIndex].cleanUpFinalised || "").valueOf();
                }
                tradeConfig.sellPrice = trades[chartIndex].reportingSoldValue / trades[chartIndex].reportingSoldQuantity;
            }

            if (trades[chartIndex].takeProfitOrderResponses?.length > 0) {
                tradeConfig.takeProfits = trades[chartIndex].takeProfitOrderResponses.map((tp, i) => ({ name: "TP #" + (i + 1), value: tp.orderPrice, active: tp.status !== OrderStatus.Cancelled }));
            }
        }
    }

    if (!chartPrices || chartPrices.length < 1) {
        return null;
    }

    return (
    <>
    {
        props.showEMA && !props.hideExchangeSelection
        ?
        
    <Select
        onChange={(event: SelectChangeEvent) => { setChartIndex(parseInt(event.target.value) || 0); }}
        value={chartIndex.toString()}
        size="small"
    >
        {
            trades.map((t, i) => {

                // Map the buyingExchange and walletType values to their enums
                let buyingExchange = ExchangeEnum[t.buyingExchange as keyof typeof ExchangeEnum];
                let walletType = (Object.keys(MarketType) as Array<keyof typeof MarketType>).find(e => MarketType[e] === t.walletType)

                return (<MenuItem value={i.toString()}>{buyingExchange} {walletType}</MenuItem >)
            })
        }
    </Select>
    : null
}
      <div style={{ display: "flex", width: '100%', height: '100%' }}>

        

         {/* 
          <SymbolPriceTradingViewChartMemo
            symbolString={`${exchange.toUpperCase()}:${props.symbol}${quote.toUpperCase()}`}
          ></SymbolPriceTradingViewChartMemo>
   */}
<SymbolPriceTradingViewChartMemo2
            symbolString={`${exchange.toUpperCase()}:${props.symbol}${quote.toUpperCase()}`}
            //data={chartPrices.map(p => ({ time: p.timestamp, open: ((p.open || 0.0) / buyPrice) - 1.0, high: ((p.high || 0.0) / buyPrice) - 1.0, low: ((p.low || 0.0) / buyPrice) - 1.0, close: ((p.close || 0.0) / buyPrice) - 1.0 } as CandlestickData))}
            data={chartPrices.map(p => ({ time: p.timestamp, open: p.open, high: p.high, low: p.low, close: p.close } as CandlestickData))}
            //data={chartPrices.filter((p, i) => chartPrices.findIndex(cp => Math.floor(cp.timestamp / 1000) === Math.floor(p.timestamp / 1000)) === i).map(p => ({ time: Math.floor(p.timestamp / 1000), value: p.price } as LineData))}
            precision={Math.max(2, getCommonDecimals(chartPrices[0].price))}
            tradeConfig={tradeConfig}
            showEMA={props.showEMA}
            height={props.height}
            showAsPercent={props.showAsPercent}
            relativeToBuyPrice={props.relativeToBuyPrice}

            //buyPrice={16750.34}
          ></SymbolPriceTradingViewChartMemo2>
         

    </div>
    </>
  );
}

export default React.memo(SymbolPriceTradingViewChart);
