import React from "react";
import {
  Paper,
  Link as MaterialLink,
  Toolbar,
  Typography,
  Box,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip
} from "@mui/material";
import { News, hideNews, selectShowEmbeddedTweet, selectDismissDisableTimeout, selectFadeTimeout } from "../reducers/newsReducer";
import { DateTime } from "luxon";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TwitterIcon from "@mui/icons-material/Twitter";
import { yellow, blueGrey, grey, blue } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { useDispatch, useSelector } from "react-redux";
import { addSymbol } from "../reducers/symbolReducer";
import CloseIcon from '@mui/icons-material/Close';
import { Flag } from "@mui/icons-material";

interface ComponentProps {
  items: News[];
}

const formatTime = (timeVariance: number) => {
  if (timeVariance < 119999) {
    return (timeVariance / 1000.0).toFixed(0) + "s ago";
  } else if (timeVariance < 60000 * 60) {
    return (timeVariance / 60000.0).toFixed(0) + "m ago";
  } else if (timeVariance < 60000 * 60 * 24) {
    return (timeVariance / 60000.0 / 60.0).toFixed(0) + "h ago";
  } else {
    return "Ages ago";
  }
};

function NewsTable(props: ComponentProps) {
  const dispatch = useDispatch();

  const showEmbeddedTweet = useSelector(selectShowEmbeddedTweet);
  const dismissDisableTimeout = useSelector(selectDismissDisableTimeout);
  const fadeTimeout = useSelector(selectFadeTimeout);

  const [date, setDate] = React.useState<number>(new Date().valueOf());

  React.useEffect(() => {
    let interval = setInterval(() => {
      setDate(new Date().valueOf());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    {/* 
                    <TableCell>ID</TableCell>
                    */}
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>Timestamp</TableCell>
                    <TableCell>Coin(s)</TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell>Content</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    [...(props.items || [])].splice(0, 50).map(n => {

                        let current = n.timestamp >= (new Date().valueOf() - (5 * 60 * 1000));

                        return (
                            <TableRow sx={{maxHeight: 100}} key={`${n.id || ""}-${n.rebroadcast || ""}-${n.uri || ""}`}>
                                {/*
                                <TableCell sx={{color: n.important ? yellow[600] : "#fff"}}>{n.id}</TableCell>
                                */}
                                <TableCell sx={{verticalAlign: "top"}}>
                                    {
                                        n.important  || current
                                            ? <Tooltip title={current ? "New" : "Important keywords found"}><Flag htmlColor={current ? blue[500] : (yellow[current ? 300 : 600])} /></Tooltip>
                                            : null
                                    }
                                </TableCell>
                                <TableCell sx={{ mb: 1, whiteSpace: "nowrap", verticalAlign: "top" }}>
                                    {DateTime.fromMillis(n.timestamp).toFormat("yyyy-MM-dd HH:mm:ss")}<br />
                                    <Typography variant="body2" component="span" sx={{fontWeight: 500, color: current ? blue[500] : (n.important ? yellow[600] : undefined)}} >{formatTime(date - n.timestamp)}</Typography>
                                </TableCell>
                                <TableCell sx={{verticalAlign: "top"}}>
                                    {n.coins.map(c => {
                                        return [<MaterialLink sx={{textDecoration: 'none'}} target="_blank" href={`https://sjoerd.tech/MakerTakerCharts/?date=${DateTime.fromMillis(n.timestamp).toUTC().toFormat("yyyy-MM-dd-HH-mm-ss")}&symbol=${c}USDT&exchange=BINANCE`}>{c}</MaterialLink>, " "]
                                    })}
                                </TableCell>
                                <TableCell sx={{verticalAlign: "top"}}>
                                    <Box>
                                        <MaterialLink
                                            href={n.uri}
                                            target="_blank"
                                            sx={{
                                                textDecoration: "none",
                                                display: "flex",
                                                alignItems: "center",
                                                whiteSpace: "nowrap"
                                            }}>
                                            <Box sx={{display: "flex",
                                                alignItems: "center",
                                                whiteSpace: "nowrap"}}>
                                                <OpenInNewIcon sx={{ fontSize: "1em", mr: 0.5 }} />
                                                {n.source}
                                            </Box>
                                        </MaterialLink>
                                        {n.rebroadcast ? <><Typography variant="body2" color="secondary" sx={{fontSize: '0.8em'}}>{` ${n.rebroadcast}`}</Typography></> : null}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{verticalAlign: "top"}}>
                                    <Box sx={{maxHeight: '100px', overflowY: 'scroll', overflowX: 'scroll'}}>
                                        
                                        <Typography variant="body2" sx={{fontWeight: n.content ? 500 : 400}}>
                                            {n.title}
                                        </Typography>
                                        {n.content ? n.content : null}
                                        {n.sentiment 
                                            ?
                                               n.sentiment.translation
                                                ? <Typography variant="body2" color="text.secondary"><b>EN: </b>{n.sentiment.translation}</Typography>
                                                : null
                                            : null
                                        }
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    </TableContainer>
  );
}

export default NewsTable;
