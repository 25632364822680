import React from "react";
import {
    Alert,
  Box,
  Button,
  ButtonGroup,
  Hidden,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Link,
  Menu,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
  useTheme,
  TextField,
  Chip,
  InputAdornment,
} from "@mui/material";
import { grey, green, red, blue } from "@mui/material/colors";
import SplitButton from "../SplitButton";
import ConfigOption from "../../types/ConfigOption";
import { useSearchParams } from "react-router-dom";
//import {
//  WebSocketContext,
//  WebsSocketContextInterface,
//} from "../../middleware/Websocket";
import TradeSettings from "../../types/TradeSettings";
import { UIOrder } from "../../types/BotOrder";
import TradePropertySelector from "./TradePropertySelector";
import SymbolPrice from "./SymbolPrice";
import SymbolPriceHeading from "./SymbolPriceHeading";
//import { SymbolConfig, SymbolWatcherContext, SymbolWatcherContextInterface } from "../../contexts/SymbolWatcher";
import { SymbolConfig } from "../../reducers/symbolReducer";
import CloseIcon from '@mui/icons-material/Close';
import { DateTime } from 'luxon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
//import { useHotkeys } from "react-hotkeys-hook";
//import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { removeSymbol, updateSymbol, togglePinning } from "../../reducers/symbolReducer";
import { cancelTakeProfit, triggerAutoReducingTakeProfit, cancelAutoReducingTakeProfit, changeTakeProfit, setTakeProfit, extendTimer, forceSell, sendTrade, status, clearTrade, setStopLoss, setTrailingStopLoss, increasePosition, selectTrades, setIncrementalTakeProfit } from "../../reducers/terminalReducer";
import { getCommonDecimals } from "../../utils/NumberUtils";

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PushPinIcon from '@mui/icons-material/PushPin';
import LockIcon from '@mui/icons-material/Lock';
import { Exchange, ExchangeEnum } from "../../enums/Exchange";
import { MarketType } from "../../enums/MarketType";
import { OrderDirection } from "../../enums/OrderDirection";
import { castStringToEnum, getExchangeUri } from "../../utils/LinkUtils";
import { OrderStatus } from "../../enums/OrderStatus";
import { RootState } from "../../store";

interface ComponentProps {
  symbol: string;
  openedOnly?: boolean;
}

interface Trade {
  symbol: string;
  exchange: string;
  market: string;
  direction: "Long" | "Short";
  quote: string;
  quantity: number;
  price: number;
  leverage: number;
  valueBought?: number;
  profitPercent?: number;
  profitValue?: number;
  stopLossPercent?: number;
  takeProfitPercent?: number;
  status:
    | "Submitted"
    | "Opening"
    | "Open"
    | "Closing"
    | "Closed"
    | "Did not fill";
  closePosition?: boolean | undefined;
}

  const usdtLocaleProfit = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const percentProfit = Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

  const MAX_VALUE = 179769313486231570000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000;

function SymbolTrades(props: ComponentProps) {
  let theme = useTheme();

  const dispatch = useDispatch();

  //const wsContext = React.useContext<WebsSocketContextInterface | null>(
//    WebSocketContext
//  );

const botStatus = useSelector(status);

 // const [trades, setTrades] = React.useState<Trade[]>([]);

  const [showMenu, setShowMenu] = React.useState<string>("");
  const [menuClearOnly, setMenuClearOnly] = React.useState<boolean>(false);
  const [actionMenuEl, setActionMenuEl] = React.useState<null | HTMLElement>(null);

  const [showTradingView, setShowTradingView] = React.useState<boolean>(false);

  const trades = useSelector(
    (state) => selectTrades(state as RootState, props.symbol),
    shallowEqual
  ) || [];

  //console.log(`Updated trade container ${props.symbol} at ${new Date().valueOf()}`, trades);

/*
    let trades = botStatus.flatMap(b => b.trades)
        .filter((t) =>
            t.baseCurrency.startsWith(props.symbol) || t.baseCurrency.startsWith("10000000" + props.symbol) || t.baseCurrency.startsWith("10000" + props.symbol) || t.baseCurrency.startsWith("1000" + props.symbol) || t.baseCurrency.startsWith("100" + props.symbol) || t.baseCurrency.endsWith(props.symbol + "1000")
        )
        .sort((a, b) =>
            a.baseCurrency < b.baseCurrency ? -1 : 1
    );
*/
    let openTrades = trades.filter((t) => t.status === "Open");

    /// TAKE PROFIT ///

    const [showSetTakeProfit, setShowSetTakeProfit] = React.useState<string>("");
    const [tempTakeProfit, setTempTakeProfit] = React.useState<number>(0);
    const [tempTakeProfitQuote, setTempTakeProfitQuote] = React.useState<number>(0);

    React.useEffect(() => {
        if (showSetTakeProfit) {

            let takeProfitValue = openTrades.length > 0 ? openTrades[0]?.tradeSettings?.takeProfit : 0;

            if (showMenu !== "All") {
                let trade = trades.find(t => t.id === showMenu);
                if (trade) {
                    takeProfitValue = trade.tradeSettings?.takeProfit || 0;
                }
            }

            setTempTakeProfit(openTrades.length > 0 ? takeProfitValue * 100.0 : 0)

            let referencePrice = (openTrades[0]?.usingTakeProfitVariable || false) 
                ? openTrades[0]?.averagePrice
                : openTrades[0]?.initialPrice;

            if (showMenu !== "All") {
                let trade = trades.find(t => t.id === showMenu);
                if (trade) {
                    referencePrice = (trade.usingTakeProfitVariable || false) 
                        ? trade.averagePrice
                        : trade.initialPrice;
                }
            }

            setTempTakeProfitQuote(openTrades.length > 0 
                ? (
                    openTrades[0]?.direction === OrderDirection.Long
                        ? referencePrice * (1 + takeProfitValue) 
                        : referencePrice / (1 + takeProfitValue) 
                )
                : 0
            )
        }
        else {
            setTempTakeProfit(0);
        }
    }, [showSetTakeProfit]);

    const confirmSetTakeProfit = () => {
        if (tempTakeProfit > 0) {
            if (showMenu === "All") {
                openTrades.map((t) => {dispatch(setTakeProfit({ tradeId: t.id, value: tempTakeProfit / 100.0}))} );
            }
            else {
                dispatch(setTakeProfit({ tradeId: showMenu, value: tempTakeProfit / 100.0}));
            } 
        }
        setShowSetTakeProfit("");
    }

    const [showSetIncrementalTakeProfit, setShowSetIncrementalTakeProfit] = React.useState<string>("");
    const [tempIncrementalTakeProfit, setTempIncrementalTakeProfit] = React.useState<number>(0);

    React.useEffect(() => {
        if (showSetIncrementalTakeProfit) {

            let incrementalTakeProfitValue = openTrades.length > 0 ? openTrades[0]?.tradeSettings?.incrementalTakeProfit : 0;

            if (showMenu !== "All") {
                let trade = trades.find(t => t.id === showMenu);
                if (trade) {
                    incrementalTakeProfitValue = trade.tradeSettings?.incrementalTakeProfit || 0;
                }
            }

            setTempIncrementalTakeProfit(openTrades.length > 0 ? (incrementalTakeProfitValue || 0.0) * 100.0 : 0)
        }
        else {
            setTempTakeProfit(0);
        }
    }, [showSetIncrementalTakeProfit]);

    const confirmSetIncrementalTakeProfit = () => {
        if (tempIncrementalTakeProfit >= 0) {
            if (showMenu === "All") {
                openTrades.map((t) => {dispatch(setIncrementalTakeProfit({ tradeId: t.id, value: tempIncrementalTakeProfit / 100.0}))} );
            }
            else {
                dispatch(setIncrementalTakeProfit({ tradeId: showMenu, value: tempIncrementalTakeProfit / 100.0}));
            } 
        }
        setShowSetIncrementalTakeProfit("");
    }

    /// STOP LOSS ///

    const [showSetStopLoss, setShowSetStopLoss] = React.useState<string>("");
    const [tempStopLoss, setTempStopLoss] = React.useState<number>(0);
    const [tempStopLossQuote, setTempStopLossQuote] = React.useState<number>(0);

    React.useEffect(() => {
        if (showSetStopLoss) {
            setTempStopLoss(openTrades.length > 0 ? (openTrades[0]?.tradeSettings?.stopLoss || 0) * 100.0 : 0)
            setTempTakeProfitQuote(openTrades.length > 0 ? openTrades[0]?.initialPrice * (1 - (openTrades[0]?.tradeSettings?.stopLoss || 0)) : 0)
        }
        else {
            setTempStopLoss(0);
        }
    }, [showSetStopLoss]);

    const confirmSetStopLoss = () => {
        if (tempStopLoss > 0) {
            if (showMenu === "All") {
                openTrades.map((t) => {dispatch(setStopLoss({ tradeId: t.id, value: tempStopLoss / 100.0}))} );
            }
            else {
                dispatch(setStopLoss({ tradeId: showMenu, value: tempStopLoss / 100.0}));
            } 
        }
        setShowSetStopLoss("");
    }

    /// TRAILING STOP LOSS ///

    const [showSetTrailingStopLoss, setShowSetTrailingStopLoss] = React.useState<string>("");
    const [tempTrailingStopLoss, setTempTrailingStopLoss] = React.useState<number>(0);

    React.useEffect(() => {
        if (showSetTrailingStopLoss) {
            setTempTrailingStopLoss(openTrades.length > 0 ? (openTrades[0]?.tradeSettings?.trailingStopLoss || 0) * 100.0 : 0)
        }
        else {
            setTempTrailingStopLoss(0);
        }
    }, [showSetTrailingStopLoss]);

    const confirmSetTrailingStopLoss = () => {
        if (tempTrailingStopLoss > 0) {
            if (showMenu === "All") {
                openTrades.map((t) => {dispatch(setTrailingStopLoss({ tradeId: t.id, value: tempTrailingStopLoss / 100.0}))} );
            }
            else {
                dispatch(setTrailingStopLoss({ tradeId: showMenu, value: tempTrailingStopLoss / 100.0}));
            } 
        }
        setShowSetTrailingStopLoss("");
    }

    /// POSITION SIZE ///

    const [showPositionIncrease, setShowPositionIncrease] = React.useState<boolean>(false);
    const [positionIncreaseAmount, setPositionIncreaseAmount] = React.useState<number>(30);
    const positionIncreaseAmounts = [30, 1000, 2000, 5000, 10000, 250000];

    const [positionIncreaseSlippage, setPositionIncreaseSlippage] = React.useState<number>(0.002);
    //const positionIncreaseSlippages = [-0.01, -0.005, -0.002, 0.002, 0.005, 0.01, 0.2];
    const positionIncreaseSlippages = [-0.01, 0.002, 0.005, 0.01, 0.025, 0.05, 0.2];

    const [showClosePosition, setShowClosePosition] = React.useState<boolean>(false);

    const [showPositionIncreaseTradeId, setShowPositionIncreaseTradeId] = React.useState<string>("");

    const processIncrease = () => {
        if (showPositionIncreaseTradeId !== "") {
            dispatch(increasePosition({ tradeId: showPositionIncreaseTradeId, value: `${positionIncreaseSlippage}|${positionIncreaseAmount}|IOC|` }));
            setShowPositionIncreaseTradeId("");
        }
        else {
            trades?.map((t) => { dispatch(increasePosition({ tradeId: t.id, value: `${positionIncreaseSlippage}|${positionIncreaseAmount}|IOC|` })) });
        }
        setShowPositionIncrease(false);
    }

    let botsPendingPositionIncrease = trades?.filter(b => b.positionIncrease?.processed === false).length;

    const [showWarning, setShowWarning] = React.useState<boolean>(true);
    const [showInfo, setShowInfo] = React.useState<boolean>(true);

    return (
        trades?.length === 0
            ? <Box sx={{ pt: 2, pb: 2 }}></Box>
            :
    <>

        <Dialog
            open={showPositionIncrease}
            onClose={() => {setShowPositionIncrease(false)}}
            >
                <DialogTitle>Increase position</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" sx={{mb: 2}}>Increase by:</Typography>
                        <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
                        {
                            positionIncreaseAmounts.map((amount) => {
                                return (
                                    <Button
                                        variant={positionIncreaseAmount === amount ? "contained" : "outlined"}
                                        sx={{mr: 1}}
                                        onClick={() => {setPositionIncreaseAmount(amount)}}
                                    >
                                        {amount === 0.10 ? "DEGEN" : `$${amount}`}
                                    </Button>
                                )
                            })
                        }
                    </Box>
                    <Typography variant="body2" sx={{mt: 2, mb: 2}}>Slippage:</Typography>
                    <Box sx={{display: 'flex', justifyContent: 'space-around'}}>

                        {
                            positionIncreaseSlippages.map((slippage) => {
                                return (
                                    <Button
                                        variant={positionIncreaseSlippage === slippage ? "contained" : "outlined"}
                                        sx={{mr: 1}}
                                        disabled={slippage < 0 ? true : false}
                                        onClick={() => {setPositionIncreaseSlippage(slippage)}}
                                    >
                                        {slippage === 0.2 ? "DEGEN" : slippage * 100 + "%"}
                                    </Button>
                                )
                            })
                        }
                    </Box>
                    <Typography variant="body2" sx={{mt: 2, mb: 2}}>Time in force:</Typography>
                    <Box sx={{display: 'flex', justifyContent: 'start', width: '100%'}}>
                        <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
                            <Button variant="contained" sx={{mr: 1}}>IOC</Button>
                            <Button variant="outlined" sx={{mr: 1}} disabled={true}>GTC</Button>
                            </Box>
                            <TextField
                            name="value"
                            value={tempTakeProfitQuote}
                            label="GTC timer (s)"
                            variant="outlined"
                            size="small"
                            disabled={true}
                            type="number"
                            onChange={(e:any) => {
                                setTempTakeProfitQuote(parseFloat(e.currentTarget.value || "0"));
                                setTempTakeProfit(((parseFloat(e.currentTarget.value || "0") / openTrades[0]?.initialPrice) - 1.0) * 100.0);
                            }}
                        />
                        </Box>
                    
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        sx={{ mb: 0, mr: 1 }}
                        onClick={() => {setShowPositionIncrease(false)}}
                    >
                        Cancel
                    </Button>
                    
                    <Button
                        variant="contained"
                        onClick={processIncrease}
                        color="success"
                        type="submit"
                        onKeyPress={(e:any) => {
                        if (e.key === "Enter" || e.key === "Space") {
                            processIncrease();
                        }
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        <Dialog
            open={showSetTakeProfit !== ""}
            onClose={() => {setShowSetTakeProfit("")}}
            >
                <DialogTitle>Set take profit</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{ mt: 3 }}
                        name="value"
                        value={tempTakeProfit}
                        label="Take profit (%)"
                        fullWidth
                        autoFocus
                        type="number"
                        onChange={(e:any) => {
                            setTempTakeProfit(parseFloat(e.currentTarget.value || "0"))
                            setTempTakeProfitQuote(openTrades[0]?.initialPrice * (1 + parseFloat(e.currentTarget.value || "0") / 100.0));
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,  
                        }}
                    />
                    <TextField
                        sx={{ mt: 3 }}
                        name="value"
                        value={tempTakeProfitQuote}
                        label="Take profit (quote)"
                        fullWidth
                        autoFocus
                        type="number"
                        onChange={(e:any) => {
                            setTempTakeProfitQuote(parseFloat(e.currentTarget.value || "0"));
                            setTempTakeProfit(((parseFloat(e.currentTarget.value || "0") / openTrades[0]?.initialPrice) - 1.0) * 100.0);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        sx={{ mb: 0, mr: 1 }}
                        onClick={() => {setShowSetTakeProfit("")}}
                    >
                        Cancel
                    </Button>
                    
                    <Button
                        variant="contained"
                        onClick={confirmSetTakeProfit}
                        type="submit"
                        onKeyPress={(e:any) => {
                        if (e.key === "Enter" || e.key === "Space") {
                            confirmSetTakeProfit();
                        }
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
            open={showSetIncrementalTakeProfit !== ""}
            onClose={() => {setShowSetIncrementalTakeProfit("")}}
            >
                <DialogTitle>Set take profit increment</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{ mt: 3 }}
                        name="value"
                        value={tempIncrementalTakeProfit}
                        label="Take profit increment (%)"
                        fullWidth
                        autoFocus
                        type="number"
                        onChange={(e:any) => {
                            setTempIncrementalTakeProfit(parseFloat(e.currentTarget.value || "0"))
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,  
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        sx={{ mb: 0, mr: 1 }}
                        onClick={() => {setShowSetIncrementalTakeProfit("")}}
                    >
                        Cancel
                    </Button>
                    
                    <Button
                        variant="contained"
                        onClick={confirmSetIncrementalTakeProfit}
                        type="submit"
                        onKeyPress={(e:any) => {
                        if (e.key === "Enter" || e.key === "Space") {
                            confirmSetIncrementalTakeProfit();
                        }
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
            open={showSetStopLoss !== ""}
            onClose={() => {setShowSetStopLoss("")}}
            >
                <DialogTitle>Set stop loss</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{ mt: 3 }}
                        name="value"
                        value={tempStopLoss}
                        label="Stop loss (%)"
                        fullWidth
                        autoFocus
                        type="number"
                        onChange={(e:any) => {
                            setTempStopLoss(parseFloat(e.currentTarget.value || "0"))
                            setTempStopLossQuote(openTrades[0]?.initialPrice * (1 - parseFloat(e.currentTarget.value || "0") / 100.0));
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,  
                        }}
                    />
                    <TextField
                        sx={{ mt: 3 }}
                        name="value"
                        value={tempStopLossQuote}
                        label="Stop loss (quote)"
                        fullWidth
                        autoFocus
                        type="number"
                        onChange={(e:any) => {
                            setTempStopLossQuote(parseFloat(e.currentTarget.value || "0"));
                            setTempStopLoss(((openTrades[0]?.initialPrice / parseFloat(e.currentTarget.value || "0") ) - 1.0) * 100.0);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        sx={{ mb: 0, mr: 1 }}
                        onClick={() => {setShowSetStopLoss("")}}
                    >
                        Cancel
                    </Button>
                    
                    <Button
                        variant="contained"
                        onClick={confirmSetStopLoss}
                        type="submit"
                        onKeyPress={(e:any) => {
                        if (e.key === "Enter" || e.key === "Space") {
                            confirmSetStopLoss();
                        }
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
            open={showSetTrailingStopLoss !== ""}
            onClose={() => {setShowSetTrailingStopLoss("")}}
            >
                <DialogTitle>Set trailing stop loss</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{ mt: 3 }}
                        name="value"
                        value={tempTrailingStopLoss}
                        label="Trailing stop loss (%)"
                        fullWidth
                        autoFocus
                        type="number"
                        onChange={(e:any) => {setTempTrailingStopLoss(parseFloat(e.currentTarget.value || "0"))}}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,  
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        sx={{ mb: 0, mr: 1 }}
                        onClick={() => {setShowSetTrailingStopLoss("")}}
                    >
                        Cancel
                    </Button>
                    
                    <Button
                        variant="contained"
                        onClick={confirmSetTrailingStopLoss}
                        type="submit"
                        onKeyPress={(e:any) => {
                        if (e.key === "Enter" || e.key === "Space") {
                            confirmSetTrailingStopLoss();
                        }
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
            open={showClosePosition}
            onClose={() => {setShowClosePosition(false)}}
            >
                <DialogTitle>Close position?</DialogTitle>
                <DialogContent>
                    <Typography variant="body2">Do you want to close {openTrades.length} open trade(s) for {props.symbol}?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        sx={{ mb: 0, mr: 1 }}
                        onClick={() => {setShowClosePosition(false)}}
                    >
                        Cancel
                    </Button>
                    
                    <Button
                        variant="contained"
                        onClick={() => {openTrades.map((t) => { dispatch(forceSell(t.id)); setShowClosePosition(false); })}}
                        type="submit"
                        color="error"
                        onKeyPress={(e:any) => {
                        if (e.key === "Enter" || e.key === "Space") {
                            openTrades.map((t) => { dispatch(forceSell(t.id)); });
                            setShowClosePosition(false);
                        }
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

                  <Box sx={{ pt: 2, pb: 2 }}>
                  
                  <Menu
                        id="basic-menu"
                        anchorEl={actionMenuEl}
                        open={actionMenuEl !== null}
                        onClose={() => setActionMenuEl(null)}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => { showMenu === "All" ? setShowPositionIncrease(true) : setShowPositionIncreaseTradeId(showMenu); setShowPositionIncrease(true); setActionMenuEl(null) } }>Increase size</MenuItem>
                  <Divider />
                  <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? openTrades.map((t) => {dispatch(extendTimer({ tradeId: t.id, value: 24 * 60 * 60 * 1000}))} ) : dispatch(extendTimer({ tradeId: showMenu, value: 24 * 60 * 60 * 1000})); setActionMenuEl(null) } }>Defer timer</MenuItem>
                  <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? openTrades.map((t) => {dispatch(extendTimer({ tradeId: t.id, value: 5 * 60 * 1000}))} ) : dispatch(extendTimer({ tradeId: showMenu, value: 5 * 60 * 1000})); setActionMenuEl(null) } }>Change timer (+5 min)</MenuItem>
                  <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? openTrades.map((t) => {dispatch(extendTimer({ tradeId: t.id, value: -5 * 60 * 1000}))} ) : dispatch(extendTimer({ tradeId: showMenu, value: -5 * 60 * 1000})); setActionMenuEl(null) } }>Change timer (-5 min)</MenuItem>
                        <Divider />
                            <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? setShowSetTakeProfit('All') : setShowSetTakeProfit(showMenu) } }>Set TP</MenuItem>
                            <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? openTrades.map((t) => {dispatch(changeTakeProfit({ tradeId: t.id, value: 0.01}))} ) : dispatch(changeTakeProfit({ tradeId: showMenu, value: 0.01})); setActionMenuEl(null) } }>Change TP (+1%)</MenuItem>
                            <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? openTrades.map((t) => {dispatch(changeTakeProfit({ tradeId: t.id, value: -0.01}))} ) : dispatch(changeTakeProfit({ tradeId: showMenu, value: -0.01})); setActionMenuEl(null) } }>Change TP (-1%)</MenuItem>
                            <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? setShowSetIncrementalTakeProfit('All') : setShowSetIncrementalTakeProfit(showMenu) } }>Set TP increment</MenuItem>
                            <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? openTrades.map((t) => {dispatch(cancelTakeProfit(t.id))} ) : dispatch(cancelTakeProfit(showMenu)); setActionMenuEl(null) } }>Cancel TP</MenuItem>
                            <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? openTrades.map((t) => {dispatch(triggerAutoReducingTakeProfit(t.id))} ) : dispatch(triggerAutoReducingTakeProfit(showMenu)); setActionMenuEl(null) } }>Trigger Auto-TP</MenuItem>
                            <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? openTrades.map((t) => {dispatch(cancelAutoReducingTakeProfit(t.id))} ) : dispatch(cancelAutoReducingTakeProfit(showMenu)); setActionMenuEl(null) } }>Cancel Auto-TP</MenuItem>
                        <Divider />
                        <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? setShowSetStopLoss('All') : setShowSetStopLoss(showMenu) } }>Set SL</MenuItem>
                        <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? setShowSetTrailingStopLoss('All') : setShowSetTrailingStopLoss(showMenu) } }>Set TSL</MenuItem>
                        <Divider />
                  <MenuItem disabled={showMenu === "All" ? openTrades.length === 0 : menuClearOnly} onClick={() => { showMenu === "All" ? openTrades.map((t) => {dispatch(forceSell(t.id));} ) : dispatch(forceSell(showMenu));  setActionMenuEl(null) } }>Close position</MenuItem>
                        <Divider />
                  <MenuItem disabled={showMenu === "All" ? openTrades.length !== 0 : !menuClearOnly} onClick={() => { showMenu === "All" ? trades.map((t) => {dispatch(clearTrade(t.id));} ) : dispatch(clearTrade(showMenu));  setActionMenuEl(null) } }>Clear trade</MenuItem>
                    </Menu>


    <Grid container spacing={1} sx={{ mb: 1, justifyContent: 'space-between' }}>

{
    openTrades.length > 0 || props.openedOnly === false
    ?
    <Grid item xs={6} md={2}>
                <Button
                    
                      variant="outlined"
                      onClick={() => { setShowPositionIncrease(true) }}
                      disabled={botsPendingPositionIncrease > 0}
                      color="success"
                      fullWidth={true}
                  >
                    <Hidden smUp>Increase {botsPendingPositionIncrease > 0 ? ` ... ${botsPendingPositionIncrease}` : null}</Hidden>
                    <Hidden smDown>Increase size {botsPendingPositionIncrease > 0 ? ` ... ${botsPendingPositionIncrease}` : null}</Hidden>
                    
                  </Button>
                  </Grid>
                    : null
}
{
    openTrades.length > 0 || props.openedOnly === false
    ?
                  <Grid item xs={6} md={2}>
                  <Button
                   
                      variant="outlined"
                      onClick={() => { openTrades.map((t) => { dispatch(extendTimer({ tradeId: t.id, value: 24 * 60 * 60 * 1000 })) }) }}
                      disabled={openTrades.length === 0}
                      fullWidth={true}
                  >
                    <Hidden smUp>Defer</Hidden>
                    <Hidden smDown>Defer timer</Hidden>
                  </Button>
                  </Grid>
                  : null
                }
                {
                    openTrades.length > 0 || props.openedOnly === false
                    ?
                    <Grid item xs={6} md={2}>
                  <Button
                 
                      variant="outlined"
                      onClick={() => { openTrades.map((t) => { dispatch(cancelTakeProfit(t.id)) }) }}
                      disabled={openTrades.filter(t => (t.takeProfitOrderResponses || []).filter( tp => tp?.status !== OrderStatus.Cancelled).length > 0).length === 0}
                      fullWidth={true}
                  >
                    Cancel TP
                    
                  </Button>
                  </Grid>
                  : null
                }
                {
                    openTrades.length > 0 || props.openedOnly === false
                    ?
                    <Grid item xs={6} md={2}>
                  <Button
                 
                      variant="outlined"
                      onClick={() => { openTrades.map((t) => { dispatch(triggerAutoReducingTakeProfit(t.id)) }) }}
                      disabled={openTrades.length === 0}
                      fullWidth={true}
                  >
                    <Hidden smUp>Trigger Auto-TP</Hidden>
                    <Hidden smDown>Trigger Auto-TP</Hidden>
                  </Button>
                  </Grid>
                  : null
                }
                {
                    /*
                    openTrades.length > 0 || props.openedOnly === false
                    ?
                    <Grid item xs={6} md={2}>
                  <Button
                  
                      variant="outlined"
                      onClick={() => {  }}
                      disabled={true}
                      fullWidth={true}
                  >
                    <Hidden smUp>Decrease</Hidden>
                    <Hidden smDown>Decrease size</Hidden>
                  </Button>
                  </Grid>
                  : null */
                }
                {
                    openTrades.length > 0 || props.openedOnly === false
                    ?
                    <Grid item xs={12} md={2}>
                  <Button
                
                      variant="contained"
                      onClick={() => { setShowClosePosition(true) }}
                      disabled={openTrades.length === 0}
                      color="error"
                      fullWidth={true}
                  >
                    <Hidden smUp>Close</Hidden>
                    <Hidden smDown>Close position</Hidden>
                  </Button>
                  </Grid>
                  
                  : null
                }
                  </Grid>
                  
        
                    {
                        // Find any notes from the first trade and display Alert if it exists
                        trades.length > 0 && trades[0].tradeSettings?.warning && showWarning
                        ? <Alert variant="outlined" severity="warning" onClose={() => { setShowWarning(false); }}>{trades[0].tradeSettings.warning}</Alert>
                        : null
                    }

                    {
                        // Find any notes from the first trade and display Alert if it exists
                        trades.length > 0 && trades[0].tradeSettings?.notes && showInfo
                        ? <Alert variant="outlined" sx={{mt: trades[0].tradeSettings?.warning ? 1 : 0}} severity="info" onClose={() => { setShowInfo(false); }}>{trades[0].tradeSettings.notes}</Alert>
                        : null
                    }

                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{fontSize: '0.625em'}}>
                                <Tooltip title="Apply to all">
                                    <IconButton
                                          disabled={trades.length === 0}
                                        onClick={(e:any) => { setShowMenu("All"); setActionMenuEl(e.currentTarget) }}
                                    >
                                        <MoreHorizIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell sx={{fontSize: '0.625em', textAlign: 'center', display: {xs: undefined, md: 'none'}}}>Profit</TableCell>
                            <TableCell sx={{fontSize: '0.625em'}}>Exchange</TableCell>
                            <TableCell sx={{fontSize: '0.625em'}}>Market type</TableCell>
                            <TableCell sx={{fontSize: '0.625em'}}>Direction</TableCell>
                            <TableCell sx={{fontSize: '0.625em'}}>Symbol</TableCell>
                            <TableCell sx={{fontSize: '0.625em', textAlign: 'right'}}>Total</TableCell>
                            <TableCell sx={{fontSize: '0.625em', textAlign: 'right'}}>Avg. Price</TableCell>
                            <TableCell sx={{fontSize: '0.625em', textAlign: 'right'}}>Qty</TableCell>
                            <TableCell sx={{fontSize: '0.625em'}}>Leverage</TableCell>
                            <TableCell sx={{fontSize: '0.625em', textAlign: 'center', display: {xs: 'none', md: 'table-cell'}}}>Profit</TableCell>
                            <TableCell sx={{fontSize: '0.625em'}}>SL</TableCell>
                            <TableCell sx={{fontSize: '0.625em'}}>TSL</TableCell>
                            <TableCell sx={{fontSize: '0.625em'}}>TP</TableCell>
                            <TableCell sx={{fontSize: '0.625em'}}>Timer</TableCell>
                            <TableCell sx={{fontSize: '0.625em', textAlign: 'right'}}>Sold %</TableCell>
                            <TableCell sx={{fontSize: '0.625em', minWidth: '135px'}}>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trades
                            .filter(t => !props.openedOnly || t.status === "Open" || t.status === "Closing" || t.status === "Cleaned up" || t.status === "Processing timed sell")
                            .map((t, tradeIndex) => {
                                try {
                                    // Take profit reduction date is only set if the process has commenced
                                    let targetReductionDate = t.takeProfitReductionDate 
                                        ? DateTime.fromISO(t.takeProfitReductionDate).valueOf() + ((t.maxPumpWindowSeconds || 0) * 1000)
                                        : (DateTime.fromISO(t.start).valueOf() + (t.maxPumpWindowSeconds || 0) * 1000);

                                    let secondsToAutoReducingTakeProfit = (targetReductionDate - DateTime.now().toMillis()) / 1000;

                              return (
                                <TableRow key={tradeIndex}>
                                  <TableCell sx={{ height: 36,fontSize: '0.625em' }}>
                                  <Tooltip title="Actions">
                                    <IconButton
                                        //disabled={t.status !== "Open"}
                                        onClick={(e:any) => { setShowMenu(t.id); setMenuClearOnly(t.status === "Cleaned up" || t.status === "Did not trade" || t.status === "Selling on alternate"); setActionMenuEl(e.currentTarget) }}
                                    >
                                        <MoreHorizIcon fontSize="small" />
                                    </IconButton>
                                    </Tooltip>
                                    {/*t.closed !== "Submitted" &&
                                        t.status !== "Closed" &&
                                        t.status !== "Did not fill" ? (
                                          <Tooltip title="Close">
                                            <IconButton
                                              disabled={t.status === "Closing"}
                                            >
                                              <PriceCheckIcon fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                        ) : (
                                          ""
                                        )*/ }
                                  </TableCell>
                                  <TableCell sx={{fontSize: '0.625em', textAlign: 'right', display: {xs: undefined, md: 'none'} }}>

                                  {
                                        t.buyQuantityFilled && t.status !== "Selling on alternate" && t.status !== "Did not trade"
                                        ? 
                                        <Box sx={{display: 'flex', flexWrap: 'nowrap', alignContent: 'right', alignItems: "right", justifyContent: "end"}}>
                                            <Chip variant="outlined" color={t.grossProfitTracking > 0 ? "success" : (t.grossProfitTracking < 0 ? "error" : "primary")} label={
                                                t.cleanUpConfirmed
                                                ? usdtLocaleProfit.format(((t.reportingSoldValue - t.reportingBuyValue - (t.alternateQuoteBuyValueInUSDT || 0.0)) || 0) * (t.direction === OrderDirection.Long ? 1 : -1))
                                                : usdtLocaleProfit.format((t.grossProfitTracking * (t.buyValueFilled * (t.contractSize || 1.0))) || 0 )
                                            } size="small" sx={{ml: 0, mr: 0, borderRadius: 0, fontSize: '1.2em' }} />

                                                <Chip color={t.grossProfitTracking > 0 ? "success" : (t.grossProfitTracking < 0 ? "error" : "primary")} label={

                                                    percentProfit.format(t.cleanUpConfirmed ? ((t.reportingSoldValue / (t.reportingBuyValue + (t.alternateQuoteBuyValueInUSDT || 0.0)) - 1.0) * (t.direction === OrderDirection.Long ? 1 : -1)) : t.grossProfitTracking)

                                                   } size="small" sx={{ml: 0, mr: 0, borderRadius: 0, fontSize: '1.2em' }} 
                                                />
                                        </Box>
                                            
                                        : ""
                                    }
                                  
                                  </TableCell>
                                  <TableCell sx={{fontSize: '0.625em'}}>{ExchangeEnum[t.buyingExchange]}</TableCell>
                                  <TableCell sx={{fontSize: '0.625em'}}>{MarketType[t.walletType]}</TableCell>
                                  <TableCell sx={{fontSize: '0.625em'}}>{OrderDirection[t.direction]}</TableCell>
                                  <TableCell sx={{fontSize: '0.625em'}}>
                                    {
                                        getExchangeUri(t.buyingExchange, t.walletType, t.baseCurrency, t.quoteCurrency)
                                        ? <Link color={blue[400]} sx={{textDecoration: 'none'}} href={getExchangeUri(t.buyingExchange, t.walletType, t.baseCurrency, t.quoteCurrency)} target="_blank">{t.baseCurrency}</Link>
                                        : t.baseCurrency
                                    }
                                    <Typography sx={{color: grey[700], fontSize: '1em'}} component="span">
                                        {" "}/{" "}{t.quoteCurrency}
                                    </Typography>
                                    
                                  </TableCell>
                                  <TableCell sx={{fontSize: '0.625em', textAlign: 'right'}}>
                                    {t.buyValueFilled 
                                    ? `$${(t.buyValueFilled * (t.contractSize || 1.0)).toFixed(2)}`
                                    :"-"
                                    }
                                    
                                    <Typography variant="body1" sx={{fontSize: '1em'}} color="text.secondary">
                                     ${t.reportingBidValue?.toFixed(2)}
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{fontSize: '0.625em', textAlign: 'right'}}>
                                    {
                                        !isNaN(t.averagePrice)
                                        ? `$${(t.averagePrice).toLocaleString(undefined, {
                                            minimumFractionDigits: Math.max(getCommonDecimals(t.averagePrice), getCommonDecimals(t.bidPrice)),
                                            maximumFractionDigits: Math.max(getCommonDecimals(t.averagePrice), getCommonDecimals(t.bidPrice)),
                                        })}`
                                        : "-"
                                    }
                                    
                                  <Typography variant="body1" sx={{fontSize: '1em'}} color="text.secondary">
                                     ${t.bidPrice.toLocaleString(undefined, {
                                        minimumFractionDigits: Math.max(getCommonDecimals(t.averagePrice || t.bidPrice), getCommonDecimals(t.bidPrice)),
                                        maximumFractionDigits: Math.max(getCommonDecimals(t.averagePrice || t.bidPrice), getCommonDecimals(t.bidPrice)),
                                    })}
                                    </Typography>
                                  </TableCell>
                                  <TableCell sx={{fontSize: '0.625em', textAlign: 'right'}}>
                                    {
                                        t.sellingOnAlternateQuote ? (t.buyQuantityOriginal || 0).toLocaleString(undefined, {
                                            minimumFractionDigits: Math.max(getCommonDecimals(t.buyQuantityOriginal || 0), getCommonDecimals(t.reportingBidQuantity)),
                                            maximumFractionDigits: Math.max(getCommonDecimals(t.buyQuantityOriginal || 0), getCommonDecimals(t.reportingBidQuantity)),
                                        }) : (t.buyQuantityFilled
                                        ? (t.buyQuantityFilled * (t.contractSize || 1.0) ).toLocaleString(undefined, {
                                            minimumFractionDigits: Math.max(getCommonDecimals((t.buyQuantityFilled * (t.contractSize || 1.0)) || t.reportingBidQuantity), getCommonDecimals(t.reportingBidQuantity)),
                                            maximumFractionDigits: Math.max(getCommonDecimals((t.buyQuantityFilled * (t.contractSize || 1.0)) || t.reportingBidQuantity), getCommonDecimals(t.reportingBidQuantity)),
                                        })
                                        : "-")
                                    }
                                  
                                  <Typography variant="body1" sx={{fontSize: '1em'}} color="text.secondary">
                                    {
                                        t.reportingBidQuantity / MAX_VALUE < 0.99 ?
                                    t.reportingBidQuantity?.toLocaleString(undefined, {
                                        minimumFractionDigits: Math.max(getCommonDecimals((t.buyQuantityFilled * (t.contractSize || 1.0)) || t.reportingBidQuantity), getCommonDecimals(t.reportingBidQuantity)),
                                        maximumFractionDigits: Math.max(getCommonDecimals((t.buyQuantityFilled * (t.contractSize || 1.0)) || t.reportingBidQuantity), getCommonDecimals(t.reportingBidQuantity)),
                                    }) : "-"}
                                    </Typography>
                                    </TableCell>
                                  <TableCell sx={{fontSize: '0.625em'}}>{t.appliedLeverage || 1}x</TableCell>
                                  <TableCell sx={{fontSize: '0.625em', textAlign: 'right', display: {xs: 'none', md: 'table-cell'} }}>

                                  {
                                        t.buyQuantityFilled && t.status !== "Selling on alternate" && t.status !== "Did not trade"
                                        ? 
                                        <Box sx={{display: 'flex', flexWrap: 'nowrap', alignContent: 'right', alignItems: "right", justifyContent: "end"}}>
                                            <Chip variant="outlined" color={t.grossProfitTracking > 0 ? "success" : (t.grossProfitTracking < 0 ? "error" : "primary")} label={
                                                t.cleanUpConfirmed
                                                ? usdtLocaleProfit.format(((t.reportingSoldValue - t.reportingBuyValue - (t.alternateQuoteBuyValueInUSDT || 0.0)) || 0) * (t.direction === OrderDirection.Long ? 1 : -1))
                                                : usdtLocaleProfit.format((t.grossProfitTracking * (t.buyValueFilled* (t.contractSize || 1.0) + ((t.alternateQuoteBuyValueInUSDT || 0.0)) )) || 0 )
                                            } size="small" sx={{ml: 0, mr: 0, borderRadius: 0, fontSize: '1.2em', fontWeight: 500 }} />

                                                <Chip color={t.grossProfitTracking > 0 ? "success" : (t.grossProfitTracking < 0 ? "error" : "primary")} label={

                                                    percentProfit.format(t.cleanUpConfirmed ? ((t.reportingSoldValue / (t.reportingBuyValue + (t.alternateQuoteBuyValueInUSDT || 0.0)) - 1.0) * (t.direction === OrderDirection.Long ? 1 : -1)) : t.grossProfitTracking)

                                                   } size="small" sx={{ml: 0, mr: 0, borderRadius: 0, fontSize: '1.2em' }} 
                                                />
                                        </Box>
                                            
                                        : ""
                                    }
                                  
                                  </TableCell>
                                  <TableCell sx={{fontSize: '0.625em'}}>
                                    {t.tradeSettings.stopLoss?.toLocaleString(
                                          undefined,
                                          {
                                            style: "percent",
                                            minimumFractionDigits: 1,
                                            maximumFractionDigits: 1,
                                          }
                                        )}
                                  </TableCell>
                                  <TableCell sx={{fontSize: '0.625em'}}>
                                    {t.tradeSettings.trailingStopLoss?.toLocaleString(
                                          undefined,
                                          {
                                            style: "percent",
                                            minimumFractionDigits: 1,
                                            maximumFractionDigits: 1,
                                          }
                                        )}
                                  </TableCell>
                                  <TableCell sx={{fontSize: '0.625em'}}>
                                    {
                                        t.status !== "Open" || (t.takeProfitOrderResponses || []).filter(tp => tp.status !== OrderStatus.Cancelled).length > 0 ?
                                    ((t.tradeSettings.takeProfit || 0.0) * (t.leverageProfitMultiplier || 1.0)).toLocaleString(
                                          undefined,
                                          {
                                            style: "percent",
                                            minimumFractionDigits: 1,
                                            maximumFractionDigits: 1,
                                          }
                                        )
                                    : "-"}
                                    &nbsp;|&nbsp;
                                    {
                                        t.status !== "Open" || (t.takeProfitOrderResponses || []).filter(tp => tp.status !== OrderStatus.Cancelled).length > 0 ?
                                    ((t.tradeSettings.takeProfitVariable || 0.0) * (t.leverageProfitMultiplier || 1.0)).toLocaleString(
                                          undefined,
                                          {
                                            style: "percent",
                                            minimumFractionDigits: 1,
                                            maximumFractionDigits: 1,
                                          }
                                        )
                                    : "-"}
                                  </TableCell>
                                  <TableCell sx={{fontSize: '0.625em'}}>
                                    {
                                        t.status === "Open"
                                        ? `${Math.round((new Date(t.timedSellTargetDate).valueOf() - (DateTime.now().toMillis())) / 1000 / 60 / 60)}:${DateTime.fromMillis(new Date(t.timedSellTargetDate).valueOf() - (DateTime.now().toMillis())).toFormat("mm:ss")}`
                                        : ""
                                    }
                                    {
                                        t.status === "Open" && secondsToAutoReducingTakeProfit > 0 && secondsToAutoReducingTakeProfit <= 1800
                                        ? <Typography variant="body2" sx={{fontSize: '1em', color: blue[500]}}>
                                            Auto TP {secondsToAutoReducingTakeProfit.toFixed(0) + "s"}
                                        </Typography>
                                        : ""
                                    }
                                  </TableCell>
                                  <TableCell sx={{fontSize: '0.625em', textAlign: 'right'}}>
                                    {
                                         t.buyQuantityFilled && t.status !== "Selling on alternate" && t.status !== "Did not trade"
                                         ? t.percentSold.toLocaleString(undefined, {
                                            style: "percent",
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })
                                         : ""
                                    }
                                    </TableCell>
                                  <TableCell sx={{fontSize: '0.625em'}}>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        {t.status === "Did not trade"
                                        ? <Tooltip title={t.initialBuyOrderResponse?.serverError || ""}>
                                                <span>{t.status}
                                                    {
                                                        t.initialBuyOrderResponse?.serverError
                                                        ? " (API error)"
                                                        : ""
                                                    }</span>
                                        </Tooltip>
                                        : <>{t.status}</>}

                                        {
                                            t.takeProfitOrderResponses?.length > 0 && t.takeProfitOrderResponses.filter((r:any) => r.serverError).length > 0
                                                ? <Tooltip title={`Take profit order errors: ${t.takeProfitOrderResponses.map((o:any) => o.serverError).join(",") || ""}`}>
                                                    <PriorityHighIcon fontSize="small" sx={{m: 0, ml: 1, p: 0}} />
                                                </Tooltip>
                                                : null
                                        }
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              );}
                              catch {
                                return null;
                              }
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                   
                  </Box>

    </>
  );
}

export default SymbolTrades;
