import React from "react";
import Toolbar from "@mui/material/Toolbar";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Hidden,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Link,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  Drawer,
  useTheme,
} from "@mui/material";
import { grey, green, red } from "@mui/material/colors";
import ConfigOption from "./types/ConfigOption";
import SouthIcon from "@mui/icons-material/South";
import CloseIcon from "@mui/icons-material/Close";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SelectInput from "@mui/material/Select/SelectInput";
import { useHotkeys } from "react-hotkeys-hook";
import { useSearchParams } from "react-router-dom";

import { DateTime } from "luxon";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { status, selectLiveTradeSymbols, lastUpdated, clearTrade } from "./reducers/terminalReducer";
//import { prices } from "./reducers/priceReducer";
import { addSymbol } from "./reducers/symbolReducer";
import { BotTrade } from "./types/BotTrade";
import { Price, selectPrices } from "./reducers/priceReducer";
import { RootState } from "./store";

interface ComponentProps {
    isSmallScreen: boolean;
}

// Todo - different precision models for diff currencies
const usdtLocale = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

function BotDisplay(props: ComponentProps) {
  const botStatus = useSelector(status);
  const liveTradeSymbols = useSelector(selectLiveTradeSymbols);
  const botLastUpdated = useSelector(lastUpdated);
  const [showMaxTrades, setShowMaxTrades] = React.useState<string>("");
  //const currentPrices = useSelector(prices);

  const btcPrices:Price[] = useSelector(
    (state) => selectPrices(state as RootState, "BTC"),
    shallowEqual
  );

  const ethPrices:Price[] = useSelector(
    (state) => selectPrices(state as RootState, "ETH"),
    shallowEqual
  );

  const dispatch = useDispatch();

  const totalValue = botStatus.reduce((total, bot) => {
    return total + bot.balances.reduce((botTotal, bal) => {
      if (bal.availableBalance > 0) {
        if (bal.quote.includes("USD") || bal.quote === "PERP") {

            // Don't include bybit perps in total
            if (bal.exchange === "ByBit" && bal.market.includes("Futures")) {
                return botTotal;
            }

          return botTotal + bal.availableBalance;
        } else {

            if (bal.quote === "BTC" && btcPrices?.length > 0 && btcPrices[0].price) {
                return botTotal + bal.availableBalance * btcPrices[0].price;
            }

            if (bal.quote === "ETH" && ethPrices?.length > 0 && ethPrices[0].price) {
                return botTotal + bal.availableBalance * ethPrices[0].price;
            }

        }
      }
      return botTotal;
    }, 0);
  }, 0);

  return (
    //<WebSocketContext.Consumer>
    //      {(context: any) => {
    //      return (
    <Grid container spacing={2}>
        <Grid item xs={12} lg={6} sx={{ m: props.isSmallScreen ? 0 : 2 }}>
        Traded symbols
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="5%">Symbol</TableCell>
                
                    {botStatus.map((bot: any) => {
                        return (
                            <TableCell width="5%" key={bot.botName} sx={{textAlign:'center'}}>{bot.botName}</TableCell>
                        );
                      })}
                
                <TableCell width="5%" sx={{textAlign:'center'}}>All</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                liveTradeSymbols
                    .filter(s => s.endsWith("2L") === false && s.endsWith("3L") === false && s.endsWith("4L") === false && s.endsWith("5L") === false && s.startsWith("100") === false && s.endsWith("1000") === false)
                    .filter(s => s.endsWith("2S") === false && s.endsWith("3S") === false && s.endsWith("4S") === false && s.endsWith("5S") === false)
                    .sort((a, b) => a < b ? -1 : 1)
                    .map((symbol:string) => {
                    return (<TableRow key={symbol}>
                        <TableCell>
                            <Link
                            sx={{cursor:'pointer', textDecoration: 'none', fontWeight: 500}}
                            onClick={() => {dispatch(addSymbol({symbol: symbol, pinned: true}));}}
                            >
                            {symbol}
                            </Link>
                        </TableCell>
                        {botStatus.map((bot: any) => {
                        return (
                            <TableCell key={bot.botName} sx={{textAlign:'center'}}>
                                {
                                    bot.trades.filter((t:BotTrade) => t.baseCurrency.startsWith(symbol)).length > 0
                                    ? <Tooltip title="Clear trade history">
                                        <IconButton
                                            disabled={bot.trades.filter((t:BotTrade) => t.baseCurrency.startsWith(symbol) && t.status === "Open").length > 0}
                                            size="small"
                                            onClick={() => {
                                                let trades = bot.trades.filter((t:BotTrade) => t.baseCurrency.startsWith(symbol));
                                                for (let i = 0; i < trades.length; i++) {
                                                    dispatch(clearTrade(trades[i].id));
                                                }
                                            }}
                                        >
                                        <CloseIcon></CloseIcon>
                                    </IconButton>
                                    </Tooltip>
                                    : null
                                }
                            </TableCell>
                        );
                      })}
                      <TableCell sx={{textAlign:'center'}}>
                      <Tooltip title="Clear trade history">
                        <IconButton
                            disabled={botStatus.flatMap(b => b.trades).filter((t:BotTrade) => t.baseCurrency.startsWith(symbol) && t.status === "Open").length > 0}
                            size="small"
                            onClick={() => {
                                let trades = botStatus.flatMap(b => b.trades).filter((t:BotTrade) => t.baseCurrency.startsWith(symbol));
                                for (let i = 0; i < trades.length; i++) {
                                    dispatch(clearTrade(trades[i].id));
                                }
                            }}
                        >
                            <CloseIcon></CloseIcon>
                        </IconButton>
                        </Tooltip>
                        </TableCell>
                    </TableRow>)
                }
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} lg={6} sx={{ m: props.isSmallScreen ? 0 : 2 }}>
        Instances
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Build</TableCell>
                <TableCell>Last updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {botStatus.map((bot: any) => {
                return (
                  <TableRow key={bot.botName}>
                    <TableCell>{bot.botName}</TableCell>
                    <TableCell>{bot.version}</TableCell>
                    <TableCell>{DateTime.fromISO(botLastUpdated.find(u => u.botName === bot.botName)?.updateTimestamp || "").toFormat("yyyy-MM-dd HH:mm:ss")}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
          <Grid item xs={12} lg={6} sx={{ m: props.isSmallScreen ? 0 : 2 }}>
        Balances: {usdtLocale.format(totalValue)}
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Bot</TableCell>
                <TableCell>Exchange</TableCell>
                <TableCell>Market</TableCell>
                <TableCell sx={{ textAlign: "right" }}>Available</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {botStatus.map((bot: any) => {
                return bot.balances
                  .filter((b: any) => b.availableBalance > 0)
                  .map((bal: any) => {
                    return (
                      <TableRow
                        key={`${bot.botName}|${bal.exchange}|${bal.market}|${bal.quote}`}
                      >
                        <TableCell>{bot.botName}</TableCell>
                        <TableCell>{bal.exchange}</TableCell>
                        <TableCell>{bal.market}</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>
                          {bal.quote.includes("USD") || bal.quote === "PERP"
                            ? usdtLocale.format(bal.availableBalance)
                            : bal.availableBalance.toFixed(6)}{" "}
                          {bal.quote}
                        </TableCell>
                      </TableRow>
                    );
                  });
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
          <Grid item xs={12} sx={{ m: props.isSmallScreen ? 0 : 2 }}>
        Performance
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Exchange</TableCell>
                <TableCell>Market</TableCell>
                <TableCell>Last updated</TableCell>
                <TableCell>Sample size</TableCell>
                <TableCell sx={{ textAlign: "right" }}>Last (ms)</TableCell>
                <TableCell sx={{ textAlign: "right" }}>Avg. (ms)</TableCell>
                <TableCell sx={{ textAlign: "right" }}>Min. (ms)</TableCell>
                <TableCell sx={{ textAlign: "right" }}>Max. (ms)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {botStatus.map((bot: any) => {
                return bot.performance.filter((p:any) => p.sampleSize > 0).map((perf: any, index: number) => {
                  return (
                    <TableRow key={`${perf.exchange}|${perf.market}|${index}`}>
                      <TableCell>{perf.exchange}</TableCell>
                      <TableCell>{perf.market}</TableCell>
                      <TableCell>
                              {DateTime.fromISO(perf.lastUpdated).toFormat("yyyy-MM-dd HH:mm:ss")}
                      </TableCell>
                      <TableCell>{perf.sampleSize}</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {perf.lastRequestMs.toFixed(1)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {perf.averageMs.toFixed(1)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {perf.minMs.toFixed(1)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {perf.maxMs.toFixed(1)}
                      </TableCell>
                    </TableRow>
                  );
                });
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
    //);
    //}}
    //</WebSocketContext.Consumer>
  );
}

export default BotDisplay;
