export const loadState = () => {
    try {
      const serializedState = sessionStorage.getItem('state');
  
      if (serializedState === null) {
        return undefined;
      }
  
      let state = JSON.parse(serializedState);

      // Only return auth / user config
      let reducedState = { authorization: state.authorization, discord: state.discord, symbols: state.symbols };

      return reducedState;

    } catch (error) {
      return undefined;
    }
  };
  
  export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify({ authorization: state.authorization, discord: state.discord, symbols: state.symbols});
      sessionStorage.setItem('state', serializedState);
    } catch (error) {
      // Ignore write errors.
    }
  };