import React from "react";
import Toolbar from "@mui/material/Toolbar";
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Hidden,
  Paper,
  Link as MaterialLink,
  Snackbar,
  Typography,
  Drawer,
  useTheme,
} from "@mui/material";
import { News } from "../reducers/newsReducer";
import { DateTime } from "luxon";
import TwitterIcon from "@mui/icons-material/Twitter";

import { useDispatch, useSelector } from "react-redux";
import { dismissNews } from "../reducers/newsReducer";
import { addSymbol } from "../reducers/symbolReducer";
import { PlayNotificationTone, PlayAlertTone } from "../utils/AudioUtils";
import { Link } from 'react-router-dom'
import { blueGrey } from '@mui/material/colors'
import { selectIsMuted, selectEnableSpeaking } from "../reducers/authorizationSlice";

interface ComponentProps {
  item: News;
}

interface KeyMap {
  slug: string;
  symbol: string;
}

const formatTime = (timeVariance: number) => {
  if (timeVariance < 119999) {
    return (timeVariance / 1000.0).toFixed(0) + "s ago";
  } else if (timeVariance < 60000 * 60) {
    return (timeVariance / 60000.0).toFixed(0) + "m ago";
  } else {
    return "ages ago";
  }
};

const timeout: number = 5000;

function NewsItemAlert(props: ComponentProps) {
  const dispatch = useDispatch();

  const isMuted = useSelector(selectIsMuted);
  const enableSpeaking = useSelector(selectEnableSpeaking);

  const [date, setDate] = React.useState<number>(new Date().valueOf());

  // Play a notification sound
  React.useEffect(() => {
    if (!isMuted && !enableSpeaking) {
        if (props.item.timestamp > new Date().valueOf() - 1000) {
            if (props.item.important) {
                PlayAlertTone();
            }
            else {
                PlayNotificationTone();    
            }
        }
    }
  }, []);

  let timer: NodeJS.Timeout | null = null;
/*
  React.useEffect(() => {

    timer = setTimeout(() => {
      dispatch(dismissNews(props.item.timestamp));
    }, timeout);

    return () => {
      if (timer != null) {
        clearTimeout(timer);
      }
    };
  }, []);
*/
  /*
  const [reRender, setReRender] = React.useState<number>(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      setReRender(reRender + 1);
    }, 500);

    return () => clearInterval(intervalId); //This is important
  }, [, reRender]);
*/
  /*
  React.useEffect(() => {
    if (message1Open === false) {
      setMessage1Dismissed(true);
      setTimeout(() => {
        setMessage2Open(true);
        beep();
        message2Date = new Date().valueOf();
      }, 10000);
    }

    if (message1Open) {
        setTimeout(() => {
            setMessage1Open(false);
        }, 30000);
    }

  }, [message1Open]);

  React.useEffect(() => {
    if (message2Open) {
        setTimeout(() => {
            setMessage2Open(false);
        }, 30000);
    }

  }, [message2Open]);
*/

  const extractKnownWords = (content: string) => {
    // Disable this to validate performance
    return content;
    /*
    return (
      <>
        {content.split(" ").map((text: string) => {
          let matchFound = keywordMap.find(
            (m) =>
              text.toLowerCase().replace("#", "").replace("$", "") === m.slug
          );
          return matchFound ? (
            <>
              {text} [
              <Link
                href="#"
                onClick={() => {
                  dispatch(addSymbol({symbol: matchFound?.symbol || "", pinned: true}));
                }}
              >
                #{matchFound.symbol}
              </Link>
              ]&nbsp;
            </>
          ) : (
            text + " "
          );
        })}
      </>
    );*/
  };

return null;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={true}
      message="Some news goes here"
      onClick={() => {
        if (timer !== null) {
            clearTimeout(timer);
        }
      }}
    >
      <Alert
        onClose={() => {
          dispatch(dismissNews(props.item.timestamp || 0));
        }}
        onClick={() => {
            if (timer !== null) {
                clearTimeout(timer);
            }
          }}
        severity={props.item.important ? "warning" : "info"}
        sx={{ width: "100%", color: "#fff" }}
        icon={false}
      >
        <AlertTitle>
            <Box>
          {props.item.type === "Twitter" ? (<TwitterIcon sx={{ fontSize: "1em", mr: 0.5 }} />) : null}{" "} {props.item.title} &nbsp;
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: "0.7em", mb: 1 }}
          >
            {DateTime.fromMillis(props.item.timestamp).toFormat(
              "yyyy-MM-dd HH:mm:ss"
            )}
            &nbsp; ({formatTime(date - props.item.timestamp)})
          </Typography>
            </Box>
            {
                props.item.rebroadcast
                ? <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "0.7em", mb: 1 }}
              >{props.item.rebroadcast}</Typography>
                : ""
            }
        </AlertTitle>
              <Typography variant="body2" sx={{ overflowWrap: 'break-word' }}>
        {props.item.content ? <div dangerouslySetInnerHTML={{__html: props.item.content}} /> : ""}
        </Typography>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Typography variant="body2" color="text.secondary">
          Related currencies&nbsp;
          {(props.item.coins?.length || 0) > 0
            ? (props.item.coins ?? []).map((c: string) => {
                return (
                  <>
                  <Link
                    to="/"
                    onClick={() => {
                      dispatch(addSymbol({symbol: c || "", pinned: true}));
                    }}
                    
                  ><MaterialLink
                  sx={{
                      color: blueGrey[500],
                      textDecoration: "none"
                    }}
                    component="span"
                    >
                    #{c}</MaterialLink>
                  </Link>{" "}
                  </>
                );
              })
            : null}
        </Typography>
      </Alert>
    </Snackbar>
  );
}

export default NewsItemAlert;
