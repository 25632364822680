import React from "react";
import Toolbar from "@mui/material/Toolbar";
import {
    Alert,
    AlertTitle,
    Box,
    Divider,
    Hidden,
    Paper,
    Link,
    Snackbar,
    Typography,
    Drawer,
    useTheme,
} from "@mui/material";

import NewsItem from "./components/NewsItem";
import NewsItemAlert from "./components/NewsItemAlert";

import { useSelector, useDispatch } from "react-redux";
import { News, news } from "./reducers/newsReducer";

interface ComponentProps {
}

function NewsAlerts(props: ComponentProps) {
    const list = useSelector(news);

    return (
        <>
            {[
                ...(list.filter(
                    (n) => !n.dismissed /* && n.time < (new Date().valueOf() - 15000)*/
                ) || []),
            ]
                .splice(0, 10)
                .map((n: News) => {
                    return <NewsItemAlert key={n.timestamp} item={n} />;
                })}
        </>
    );
}

export default NewsAlerts;
