import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
  Paper,
  List,
  ListItemButton,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import { status } from "./reducers/terminalReducer";

interface ComponentProps {}

function SettingsConfig(props: ComponentProps) {

    const botStatus = useSelector(status);

    const symbolList:string[] = [];

    for (let i = 0; i < botStatus.length; i++) {
        for (let j = 0; j < (botStatus[i].config?.noBuyCoins || []).length; j++) {
            if (symbolList.includes(botStatus[i].config?.noBuyCoins[j]) === false) {
                symbolList.push(botStatus[i].config?.noBuyCoins[j]);
            }
        }
    }

    symbolList.sort((a, b) => a < b ? -1 : 1 );

  return (
    
      <Grid container>
        {
                botStatus.length > 0
                ?
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell width="5%">Symbol</TableCell>
                                    {
                                        botStatus.map(b => {
                                            return (<TableCell width="5%" key={b.botName}>{b.botName}</TableCell>)
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    symbolList.map(s => {
                                        return (
                                            <TableRow key={s}>
                                                <TableCell>{s}</TableCell>
                                                {
                                                    botStatus.map(b => {
                                                        return (<TableCell key={b.botName}>{(b.config?.noBuyCoins || []).findIndex(c => c === s) > -1 ? "x" : ""}</TableCell>)
                                                    })
                                                }
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                : null
            }
      </Grid>
      
  );
}

export default SettingsConfig;
