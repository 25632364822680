import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppDispatch } from '../store';
import {
  setLoggedIn,
  setAccessToken,
  setTokenExpiryDate,
  selectIsLoggedIn,
  selectTokenExpiryDate,
  selectAccessToken
} from '../reducers/authorizationSlice';
//import { setUserProfileAsync } from '../spotifyExample/spotifyExampleSlice';
import { getAuthorizeHref } from '../oauthConfig';
import { getHashParams, removeHashParamsFromUrl } from '../utils/hashUtils';
import { Button, Divider, Paper, SvgIcon, Typography } from '@mui/material';
import { setUserProfileAsync } from '../reducers/discordReducer';

const hashParams = getHashParams();
const access_token = hashParams.access_token;
const expires_in = hashParams.expires_in;
removeHashParamsFromUrl();

const DiscordIcon = () => {
    return (<SvgIcon viewBox="0 0 127.14 96.36">
                <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
            </SvgIcon>
        );
}

export function Authorization() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const tokenExpiryDate = useSelector(selectTokenExpiryDate);
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (access_token) {
      dispatch(setLoggedIn(true));
      dispatch(setAccessToken(access_token));
      dispatch(setTokenExpiryDate(Number(expires_in)));
      dispatch(setUserProfileAsync(access_token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get the user details
  if (accessToken) {
    fetch("https://discord.com/api/users/@me", { headers: { 'Authorization': 'Bearer ' + accessToken }})
    .then(resp => resp.json())
    .then(resp => console.log(resp));
  }
  
  return (
    <div>
      <div>
        {!isLoggedIn &&
            <Paper elevation={3} sx={{p: 2, maxWidth: 300}}>
                <Typography variant="body1">Sign in</Typography>
                <Button
          sx={{pl:2, mt: 3, mb: 3, width: '100%'}}
          onClick={() => window.open(getAuthorizeHref(), '_self')}
          startIcon={<DiscordIcon />}
          variant="outlined"
          >
          Discord auth
          </Button>
          <Divider />
          <Typography sx={{mt: 3, mb: 1}} variant="body2">This will only provide your Discord username and a token, no email or other details are provided</Typography>
            </Paper>
          }
        {isLoggedIn && <div>Token expiry date: {tokenExpiryDate}</div>}
        {isLoggedIn && <div>Access token: {accessToken}</div>}
      </div>
    </div>
  );
}