import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectNewsRatio, selectChartsPerRow, selectMaxCoins, lastWebsocketReceived } from "./reducers/terminalReducer";
import { Badge, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from "@mui/material";
import { Circle, Troubleshoot } from "@mui/icons-material";
//import { setTradeValues, TradeValue, tradeValues } from "./reducers/symbolReducer";
import { selectDefaultSymbols, selectLastUpdated } from "./reducers/priceReducer";
import { green, red } from "@mui/material/colors";
import Diagnostics from "./Diagnostics";
import { selectNewsWebsocketConnected } from "./reducers/newsReducer";

function DiagnosticsButton() {
  
  const [open, setOpen] = React.useState<boolean>(false);

  const newsLastUpdated = useSelector(selectNewsWebsocketConnected);
  const pricesLastUpdated = useSelector(selectLastUpdated);
  const relayLastUpdated = useSelector(lastWebsocketReceived);
  
  const lastNewsUpdate = new Date().valueOf() - newsLastUpdated;
  const lastPriceUpdate = new Date().valueOf() - pricesLastUpdated;
  const lastRelayUpdate = new Date().valueOf() - relayLastUpdated;

  const connectionIssues = (lastNewsUpdate > 5000 ? 1 : 0) + (lastPriceUpdate > 5000 ? 1 : 0) + (lastRelayUpdate > 5000 ? 1 : 0);

  return (
    <React.Fragment>
        <Tooltip title="Diagnostics">
            <IconButton
                onClick={() => {setOpen(true);}}
            >
                <Badge badgeContent={connectionIssues} color="error">
                    <Troubleshoot />
                </Badge>
            </IconButton>
            
        </Tooltip>

        <Dialog
            open={open}
            onClose={() => {setOpen(false)}}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Connection diagnostics</DialogTitle>
            <DialogContent>
                <Diagnostics />
                </DialogContent>
        </Dialog>
        
    </React.Fragment>
  );
}

export default React.memo(DiagnosticsButton);
