import React from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import ConfigOption from "./types/ConfigOption";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
//import { useHotkeys } from "react-hotkeys-hook";
import { useSearchParams } from "react-router-dom";
import TradeSettings from "./types/TradeSettings";
import SymbolContainer from "./components/symbol/SymbolContainer";

import { useSelector, useDispatch } from "react-redux";
import {
  list,
  SymbolConfig,
  selectTradeIsFullscreen,
} from "./reducers/symbolReducer";
import { connectedCount } from "./reducers/terminalReducer";

interface ComponentProps {
  drawerWidth: number;
  side: "left" | "right";
  isSmallScreen: boolean;
}

interface Trade {
  symbol: string;
  exchange: string;
  market: string;
  direction: "Long" | "Short";
  quote: string;
  quantity: number;
  price: number;
  leverage: number | null;
  valueBought?: number;
  profitPercent?: number;
  profitValue?: number;
  stopLossPercent?: number;
  takeProfitPercent?: number;
  status:
    | "Submitted"
    | "Opening"
    | "Open"
    | "Closing"
    | "Closed"
    | "Did not fill";
  closePosition?: boolean | undefined;
}

const initPercentOptions: ConfigOption[] = [
  { label: "2%", value: 0.02 },
  { label: "3%", value: 0.03 },
  { label: "4%", value: 0.04, default: true },
  { label: "10%", value: 0.1 },
  { label: "Market", value: 0.2 },
];

const takeProfitPercentOptions: ConfigOption[] = [
  { label: "3%", value: 0.03 },
  { label: "5%", value: 0.05 },
  { label: "10%", value: 0.1, default: true },
  { label: "15%", value: 0.15 },
  { label: "None", value: 1.0 },
];

const leverageOptions: ConfigOption[] = [
  { label: "1x", value: 1 },
  { label: "5x", value: 5 },
  { label: "10x", value: 10 },
  { label: "20x", value: 20 },
  { label: "Max.", value: null, default: true },
];

const stopLossPercentOptions: ConfigOption[] = [
  { label: "-3%", value: -0.03 },
  { label: "-5%", value: -0.05 },
  { label: "-7%", value: -0.07 },
  { label: "-10%", value: -0.1, default: true },
  { label: "None", value: -1 },
];

// USDT formatter
// Todo - different precision models for diff currencies
const usdtLocale = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumSignificantDigits: 7,
});

interface MovementComponentProps {
  movement: number;
  label: string;
}

const MovementInfo = (props: MovementComponentProps) => {
  return (
    <Typography
      variant="h6"
      sx={{
        ml: 2,
        display: "inline-flex",
        alignItems: "center",
        textAlign: "left",
        color:
          props.movement < 0
            ? red[700]
            : props.movement > 0
            ? green[600]
            : undefined,
      }}
    >
      {props.movement > 0 ? <NorthIcon /> : <SouthIcon />}
      {props.movement.toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: 2,
      })}
      <Typography
        variant="body2"
        component="span"
        color="text.secondary"
        sx={{ ml: 1, whiteSpace: "nowrap" }}
      >
        {" "}
        ({props.label})
      </Typography>
    </Typography>
  );
};

interface OrderDetails {
  index: number;
  side: "Long" | "Short";
  percent: number;
}

interface OrderVolume {
  value: number;
  shortcut: string;
}

const longOrders: OrderVolume[] = [
  { value: 0.25, shortcut: "a" },
  { value: 0.5, shortcut: "s" },
  { value: 0.75, shortcut: "d" },
  { value: 1.0, shortcut: "f" },
];

const shortOrders: OrderVolume[] = [
  { value: 0.25, shortcut: "j" },
  { value: 0.5, shortcut: "k" },
  { value: 0.75, shortcut: "l" },
  { value: 1.0, shortcut: ";" },
];

let message1Date = new Date().valueOf();
let message2Date = new Date().valueOf();

// Connect to MadNews
let socket: WebSocket | null = null;

// Make the function wait until the connection is made...
function waitForSocketConnection(callback: any) {
  setTimeout(function () {
    if (socket?.readyState === 1) {
      console.log("Connection is made");
      if (callback != null) {
        callback();
      }
    } else {
      console.log("wait for connection...");
      waitForSocketConnection(callback);
    }
  }, 5); // wait 5 milisecond for the connection...
}
/*
function connect() {

    console.log('Connecting to Terminal WS server');

    socket = new WebSocket("ws://192.168.0.213:8345");

    console.log('Connected to Terminal WS server');

    waitForSocketConnection(function(){
        let subscriptionMessage = { subscribe: "Terminal", target: "192.168.0.213", auth: "jibberish" };
        if (socket !== null) {
            socket.send(JSON.stringify(subscriptionMessage));
        }
    });

    let reconnecting = false;

    socket.onclose = function (event:any) {
        console.log(event);
        if (!reconnecting) {
            reconnecting = true;
            socket = null;
            setTimeout(() => connect(), 5000);
        }
    };

    socket.onerror = function (event:any) {
        console.log(event);
        if (!reconnecting) {
            reconnecting = true;
            socket = null;
            setTimeout(() => connect(), 5000);
        }
    };

    socket.onmessage = function (event:any) {

        let parsedMessage = JSON.parse(event.data);

        console.log(parsedMessage);
            

    };
}

connect();
*/
function Terminal(props: ComponentProps) {
  let theme = useTheme();

  const terminalConnectedCount = useSelector(connectedCount);

  const tradeIsFullscreen = useSelector(selectTradeIsFullscreen);

  // Just for debugging
  /*
const context = React.useContext<NewsWebSocketContextInterface | null>(NewsWebSocketContext);
*/

  const [searchParams, setSearchParams] = useSearchParams();

  const [opportunities, setOpportunities] = React.useState<TradeSettings[]>(
    searchParams.get("symbols")
      ? searchParams
          .get("symbols")
          ?.split(",")
          .map((s:any) => {
            return {
              symbol: s,
              currentPrice: 1556.13,
              minMovement: 0.0135,
              hrMovement: 0.04234,
              dayMovement: 0.0723,
              initPercent: 0.04,
              takeProfitPercent: 0.1,
              stopLossPercent: -0.05,
              leverage:
                leverageOptions.find((o) => o.default === true)?.value || 0,
            };
          }) || []
      : [
          {
            symbol: "BTC",
            currentPrice: 23556.123,
            minMovement: -0.0245,
            hrMovement: 0.05345,
            dayMovement: 0.0378,
            initPercent: 0.03,
            takeProfitPercent: 0.05,
            stopLossPercent: -0.07,
            leverage:
              leverageOptions.find((o) => o.default === true)?.value || 0,
          },
          {
            symbol: "ETH",
            currentPrice: 1556.13,
            minMovement: 0.0135,
            hrMovement: 0.04234,
            dayMovement: 0.0723,
            initPercent: 0.04,
            takeProfitPercent: 0.1,
            stopLossPercent: -0.05,
            leverage:
              leverageOptions.find((o) => o.default === true)?.value || 0,
          },
        ]
  );

  const [trades, setTrades] = React.useState<Trade[]>([]);

  const setInitPercent = (symbol: string, initPercent: number) => {

    let copiedConfig: TradeSettings[] = JSON.parse(
      JSON.stringify(opportunities)
    );

    let opportunityIndex = copiedConfig.findIndex((c) => c.symbol === symbol);

    if (opportunityIndex === -1) {
      console.error(
        `Could not find ${symbol} in current list of opportunities`
      );
      return;
    }

    copiedConfig[opportunityIndex].initPercent = initPercent;
    if (initPercent >= copiedConfig[opportunityIndex].takeProfitPercent) {
      // Find the current index position
      let nextTakeProfitPosition = takeProfitPercentOptions.findIndex(
        (o) => (o.value || 0) > initPercent
      );

      if (nextTakeProfitPosition === -1) {
        console.error(
          `Could not find the current take profit index - be careful...`
        );
      } else {
        copiedConfig[opportunityIndex].takeProfitPercent =
          takeProfitPercentOptions[nextTakeProfitPosition].value || 0; // TODO
      }
    }

    setOpportunities(copiedConfig);
  };

  const setLeverage = (symbol: string, leverage: number) => {
    console.log(`Updating leverage for ${symbol} to ${leverage}`);

    let copiedConfig: TradeSettings[] = JSON.parse(
      JSON.stringify(opportunities)
    );

    let opportunityIndex = copiedConfig.findIndex((c) => c.symbol === symbol);

    if (opportunityIndex === -1) {
      console.error(
        `Could not find ${symbol} in current list of opportunities`
      );
      return;
    }

    copiedConfig[opportunityIndex].leverage = leverage;
    setOpportunities(copiedConfig);
  };

  const setTakeProfitPercent = (symbol: string, takeProfitPercent: number) => {
    console.log(
      `Updating take profit percent for ${symbol} to ${takeProfitPercent}`
    );

    let copiedConfig: TradeSettings[] = JSON.parse(
      JSON.stringify(opportunities)
    );

    let opportunityIndex = copiedConfig.findIndex((c) => c.symbol === symbol);

    if (opportunityIndex === -1) {
      console.error(
        `Could not find ${symbol} in current list of opportunities`
      );
      return;
    }

    copiedConfig[opportunityIndex].takeProfitPercent = takeProfitPercent;

    // To do - handle if this is lower than TP (UI should prevent with disabled buttons)

    setOpportunities(copiedConfig);
  };

  const setStopLossPercent = (symbol: string, stopLossPercent: number) => {
    console.log(
      `Updating stop loss percent for ${symbol} to ${stopLossPercent}`
    );

    let copiedConfig: TradeSettings[] = JSON.parse(
      JSON.stringify(opportunities)
    );

    let opportunityIndex = copiedConfig.findIndex((c) => c.symbol === symbol);

    if (opportunityIndex === -1) {
      console.error(
        `Could not find ${symbol} in current list of opportunities`
      );
      return;
    }

    copiedConfig[opportunityIndex].stopLossPercent = stopLossPercent;
    setOpportunities(copiedConfig);
  };

  // ORDER CONFIRMATION //
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [orderDetails, setOrderDetails] = React.useState<OrderDetails | null>(
    null
  );

  const requestOrder = (
    index: number,
    side: "Long" | "Short",
    percent: number
  ) => {
    setOrderDetails({ index: index, side: side, percent: percent });
    // setShowConfirmation(true);
  };

  React.useEffect(() => {
    if (orderDetails && !showConfirmation) {
      setShowConfirmation(true);
    } else {
      if (!orderDetails && showConfirmation) {
        setShowConfirmation(false);
      }
    }
  }, [orderDetails]);

  const rejectOrder = () => {
    console.log("Cancel the pending order");
    setOrderDetails(null);
  };

  

  /*  
  const [reRender, setReRender] = React.useState<number>(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
  //    setReRender(reRender + 1);
    }, 500);

    return () => clearInterval(intervalId); //This is important
  }, [, reRender]);
*/
  const addSymbol = (symbol: string) => {
    let tempOpportunities = [
      ...opportunities.filter((o) => o.symbol !== symbol),
    ];
    tempOpportunities.unshift({
      symbol: symbol,
      currentPrice: 0.0734,
      minMovement: 0.003,
      hrMovement: -0.028,
      dayMovement: 0.0185,
      initPercent: 0.03,
      takeProfitPercent: 0.05,
      stopLossPercent: -0.07,
      leverage: leverageOptions.find((o) => o.default === true)?.value || 0,
    });

    setOpportunities(tempOpportunities);
  };

  
  const [showCustom, setShowCustom] = React.useState<boolean>(false);

  const symbolList = useSelector(list);
  const dispatch = useDispatch();

  /*
  React.useEffect(() => {
    console.log('part 1');
    if (!showBot) {
        console.log('part 2');
        window.setTimeout(() => {
            console.log('part 3');
            context?.injectNews({time: new Date().valueOf(),
                title: "Hey man - buy some Bitcoin! It's the only thing in this news, so I'll default it for you. Press 'F' + 'ENTER' to show how fast you can trade",
                body: "",
                coin: "BTC",
                link: "",
                dismissed: false,});
          }, 2000);
        
    }
  }, [showBot]);
  */

  if (terminalConnectedCount === 0) {
    return null;
  }

  return (
    <>
    <Box sx={{ overflowY: "scroll", width: '100%' }}>
      <Grid container sx={{m:0}}>
        {[...symbolList].splice(0, 1).map((o: SymbolConfig, index: number) => {
          return props.isSmallScreen && index > 0 ? null : (
            <Grid
              key={o.symbol}
              item
              xs={12}
              sx={{
                m: 0,
                //mb: 0,
                
                //ml: {
                  //xs: 2,
                  //md: `${props.side === "left" ? props.drawerWidth : 0}px`,
                //},
              }}
            >
              <SymbolContainer
                symbol={o}
                index={index}
                drawerWidth={0}
                isSmallScreen={props.isSmallScreen}
              />
            </Grid>
          );
        })}
      </Grid>

      
    </Box>
    
  </>
  );
}

export default Terminal;
