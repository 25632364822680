import {
  Box,
  Chip,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { status } from "../../reducers/terminalReducer";
import { OrderDirection } from "../../enums/OrderDirection";

interface ComponentProps {
  symbol: string;
}

const usdtLocaleProfit = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const percentProfit = Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

function SymbolProfit(props: ComponentProps) {
  
const botStatus = useSelector(status);

    let trades = botStatus.flatMap(b => b.trades)
        .filter((t) =>
            t.baseCurrency.startsWith(props.symbol) || t.baseCurrency.startsWith("10000000" + props.symbol) || t.baseCurrency.startsWith("10000" + props.symbol) || t.baseCurrency.startsWith("1000" + props.symbol) || t.baseCurrency.startsWith("100" + props.symbol) || t.baseCurrency.endsWith(props.symbol + "1000")
        )
        .sort((a, b) =>
            a.baseCurrency < b.baseCurrency ? -1 : 1
    );

    const profit = (trades || []).map(t => {
        return t.buyQuantityFilled && t.status !== "Selling on alternate" && t.status !== "Did not trade"
            ? 
                t.cleanUpConfirmed
                    ? ((t.reportingSoldValue - t.reportingBuyValue - (t.alternateQuoteBuyValueInUSDT || 0.0)) || 0.0) * (t.direction === OrderDirection.Long ? 1.0 : -1.0) 
                    : (t.grossProfitTracking * ((t.buyValueFilled + (t.alternateQuoteBuyValueInUSDT || 0.0)) * (t.contractSize || 1.0))) || 0.0
            : 0.0
    }).reduce((a, b) => a + (!isNaN(b) ? b : 0.0), 0.0);

    const purchasedAmount = (trades || []).map(t => {
        return t.buyValueFilled 
            ? ((t.buyValueFilled + (t.alternateQuoteBuyValueInUSDT || 0.0)) * (t.contractSize || 1.0))
            : 0
    }).reduce((a, b) => a + (!isNaN(b) ? b : 0.0), 0.0);

    return (
        trades.length === 0
            ? <Box sx={{ pt: 2, pb: 2 }}></Box>
            : <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                
                <Chip variant="outlined" color={profit > 0 ? "success" : (profit < 0 ? "error" : "primary")} label={profit !== 0 
                    ? usdtLocaleProfit.format(profit)
                    : "-"} size="small" sx={{ml: 2, mr: 0, borderRadius: 0, fontWeight: 500 }} />

                <Chip color={profit > 0 ? "success" : (profit < 0 ? "error" : "primary")} label={profit !== 0 
                    ? percentProfit.format(profit / purchasedAmount)
                    : "-"} size="small" sx={{ml: 0, mr: 2, borderRadius: 0 }} />
            </Box>
    );
    }

export default SymbolProfit;
