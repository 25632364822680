import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    connectedCount,
  lastWebsocketReceived,
  selectBots,
  startConnecting,
} from "./reducers/terminalReducer";
import { Box, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import { Circle, SettingsRemote } from "@mui/icons-material";
import { requestConnect, selectLastUpdated } from "./reducers/priceReducer";
import { requestConnect as requestConnectNews, selectNewsWebsocketConnected } from "./reducers/newsReducer";
import { green, red } from "@mui/material/colors";

function Diagnostics() {
  const newsLastUpdated = useSelector(selectNewsWebsocketConnected);
  const pricesLastUpdated = useSelector(selectLastUpdated);
  const relayLastUpdated = useSelector(lastWebsocketReceived);
  const connectedBots = useSelector(selectBots);
  const dispatch = useDispatch();

  // How many trading bots appear connected?
  const botCount = useSelector(connectedCount, shallowEqual);

  const lastNewsUpdate = new Date().valueOf() - newsLastUpdated;
  const lastPriceUpdate = new Date().valueOf() - pricesLastUpdated;
  const lastRelayUpdate = new Date().valueOf() - relayLastUpdated;

    // Build a state update to force a re-render at least every 1 second
    // This is a hack to ensure the Diagnostics component is updated every second
    React.useEffect(() => {
        const interval = setInterval(() => {
            dispatch({ type: "FORCE_UPDATE" });
        }, 1000);
        return () => clearInterval(interval);
    }, [dispatch]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Circle
              fontSize="small"
              htmlColor={lastNewsUpdate < 5000 ? green[500] : red[500]}
            />
            &nbsp;News WS server&nbsp;
            {(lastNewsUpdate / 1000).toFixed(1)}s
          </Box>
          <Box>
            <Tooltip title="Reconnect (TBC)">
              <IconButton onClick={() => {dispatch(requestConnectNews())}} disabled={lastNewsUpdate < 5000}>
                <SettingsRemote />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 1, mb: 1, border: "1px solid #fff" }} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Circle
              fontSize="small"
              htmlColor={lastPriceUpdate < 5000 ? green[500] : red[500]}
            />
            &nbsp;Price WS server&nbsp;
            {(lastPriceUpdate / 1000).toFixed(1)}s
          </Box>
          <Box>
            <Tooltip title="Reconnect (TBC)">
              <IconButton onClick={() => {dispatch(requestConnect())}} disabled={lastPriceUpdate < 5000}>
                <SettingsRemote />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 1, mb: 1, border: "1px solid #fff" }} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Circle
              fontSize="small"
              htmlColor={lastRelayUpdate < 5000 && botCount === connectedBots.length ? green[500] : red[500]}
            />
            &nbsp;Relay WS server&nbsp;
            {(lastRelayUpdate / 1000).toFixed(1)}s
          </Box>
          <Box>
            <Tooltip title="Reconnect">
              <IconButton
                onClick={() => {
                  connectedBots.map((b) => dispatch(startConnecting(b)));
                  console.log(connectedBots);
                }}
                disabled={lastRelayUpdate < 5000 && botCount === connectedBots.length}
              >
                <SettingsRemote />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default React.memo(Diagnostics);
