import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectLiveTradeSymbols } from "./reducers/terminalReducer";
import TradeContainer from "./TradeContainer";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddCircleOutlined, Delete, Edit } from "@mui/icons-material";
import { TradePlan } from "./types/BotOrder";
import SymbolPriceTradingViewChartMemo from "./components/symbol/SymbolPriceTradingViewChartMemo";
import SymbolPriceTradingViewChart from "./components/symbol/SymbolPriceTradingViewChart";
import { addPrice } from "./reducers/priceReducer";

function Plans() {

    const dispatch = useDispatch();

  const liveTradeSymbols = useSelector(selectLiveTradeSymbols, shallowEqual);

  console.log("did plans change?", liveTradeSymbols);

  const [planId, setPlanId] = React.useState<string | null>(null);

  const plans:TradePlan[] = [
    {
        id: "11111111-2222-3333-4444-55555555555",
        name: "XRP case win or dismissed",
        coins: ["XRP"],
        betaCoins: [],
        description: "If the SEC loses the case against Ripple, or the case is dismissed, XRP will likely pump. The opposite is also likely to be true, though the downside is probably more limited. Less play, a direct statement from Trump could have short term price benefits",
        sources: "Brad Garlinghouse (Ripple CEO) tweets, Zoomer/Tree/Phoenix, news articles, court filings, SEC press releases, etc. ",
        duration: "The previous pump latest about 12 hours (TBC)",
        initialRisk: 1.0,
        takeProfit: 2.0,
        takeProfitVariable: 3.0,
        stopLoss: 4.0,
        timedSell: 60000,
        investmentMaxRisk: 5.0,
        investmentMaxAmount: 5.0,
        maxPumpWindowSeconds: 6.0,
        takeProfitReductionPercent: 7.0,
        takeProfitReductionPeriodSeconds: 8.0
    }
  ];

  return (
    <>
      <Dialog
        open={planId !== null}
        onClose={() => {
          setPlanId(null);
        }}
        maxWidth="md"
      >
        <DialogTitle>Add/update trade plan</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{mt: 1}}>
            <Grid item xs={12} md={6}>
              <TextField label="Name" fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Primary coin(s)" fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Beta coin(s)" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Description / narrative / thesis" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Expected sources / triggers" fullWidth placeholder="Where might this information come from first? Will it be expected around a certain date (predictable) or will it be a surprise (potentially lucrative, but a slower play)" />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Expected trade duration" fullWidth placeholder="How long do we think the news will pump for, considering how long it may take to filter out" />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mt: 1, mb: 1 }} />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField label="Init risk (%)" fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Take profit (%)" fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Take profit variable (%)" fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Stop loss (%)" fullWidth />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField label="Max trade ($/%)" fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Auto-TP timer (ms)" fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Auto-TP (%)" fullWidth />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField label="Auto-TP (other)" fullWidth />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
        <Button
            variant="outlined"
            sx={{ mb:2, mr: 2 }}
            onClick={() => {setPlanId(null);}}
            onKeyPress={(e:any) => {
              if (e.key === "Enter" || e.key === "Space") {
                setPlanId(null);
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{mr: 2, mb:2, ml: 2}}
            onClick={() => {}}
            onKeyPress={(e:any) => {
              if (e.key === "Enter" || e.key === "Space") {
                //submitOrder();
              }
            }}
            autoFocus
            //</DialogActions>disabled={botsConnected === 0}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ m: 1 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="body1" color="text.secondary">
              Planned trades
            </Typography>
            <Tooltip title="Add a new trade plan">
              <IconButton
                onClick={() => {
                  setPlanId("1");
                }}
              >
                <AddCircleOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Grid container spacing={1}>
              {plans.map((p) => {

                // Dispatch the 'addPrice' action to the terminalReducer
                

                return (
                  <Grid item xs={12} key={p.id}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                        <Box>
                        <Typography variant="h6" component="span">
                            {p.name}
                        </Typography>
                        <Tooltip title="Edit this trade plan">
                            <IconButton onClick={() => { setPlanId(p.id); }}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete this trade plan">
                            <IconButton onClick={() => { setPlanId(p.id); }}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                        </Box>

                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" color="text.primary"><strong>Thesis<br /></strong>{p.description}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" color="text.primary"><strong>Sources<br /></strong>{p.sources}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" color="text.primary"><strong>Trade duration<br /></strong>{p.duration}</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                        {
                            p.coins.map((c) => {

                                dispatch(addPrice(c));
                                
                                return (
                                    <Grid item xs={12} md={3} key={c}>
                                        <Paper elevation={4} sx={{ p: 2 }}>
                                            <Typography variant="body2">{c}<Typography variant="body2" color="text.secondary" component="span"> (primary)</Typography></Typography>
                                            <SymbolPriceTradingViewChart symbol={c} showEMA={false} height={256} relativeToBuyPrice={false} hideExchangeSelection={true} />

                                            <Box sx={{display:'flex', justifyContent: 'space-around'}}>
                                                <Button color="success" variant="contained" sx={{width: 100}}>LONG</Button>
                                                <Button color="error" variant="contained" sx={{width: 100}}>SHORT</Button>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                );
                            })
                        }
                        
                        {
                            p.betaCoins.map((c) => {

                                dispatch(addPrice(c));

                                return (
                                    <Grid item xs={12} md={3} key={c}>
                                        <Paper elevation={4} sx={{ p: 1 }}>
                                        <Typography variant="body2">{c}<Typography variant="body2" color="text.secondary" component="span"> (beta)</Typography></Typography>
                                            <SymbolPriceTradingViewChart symbol={c} showEMA={false} height={256} relativeToBuyPrice={false} hideExchangeSelection={true} />

                                            <Box sx={{display:'flex', justifyContent: 'space-around'}}>
                                                <Button color="success" variant="contained" sx={{width: 100}}>LONG</Button>
                                                <Button color="error" variant="contained" sx={{width: 100}}>SHORT</Button>
                                            </Box>

                                        </Paper>
                                    </Grid>
                                );
                            })
                        }
                        </Grid>


                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default React.memo(Plans);
