import React from "react";
import {
    Box,
    Paper,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Switch,
} from "@mui/material";

import { blue } from "@mui/material/colors";
interface ComponentProps {
}

interface BinanceCoin {
    coin: string,
    createTime: number,
    mcap: number,
}

interface MarketCap {
    coin: string,
    marketCap: number,
}

const usdtLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    //minimumSignificantDigits: 0,
    //maximumSignificantDigits: 0,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const binanceSpotUri = "https://api.binance.com/api/v3/exchangeInfo";
const binancePerpsUri = "https://fapi.binance.com/fapi/v1/exchangeInfo";
const dwfCoinsUri = "https://api.coinmarketcap.com/data-api/v3/cryptocurrency/listing?start=1&limit=100&sortBy=market_cap&sortType=desc&convert=USD,BTC,ETH&cryptoType=all&tagType=all&audited=false&aux=ath,atl,high24h,low24h,num_market_pairs,cmc_rank,date_added,tags,platform,max_supply,circulating_supply,self_reported_circulating_supply,self_reported_market_cap,total_supply,volume_7d,volume_30d&tagSlugs=dwf-labs-portfolio";
//const dwfCoinsUri = "https://cors-anywhere.herokuapp.com/https://api.coinmarketcap.com/data-api/v3/cryptocurrency/listiny/listing?start=1&limit=100&sortBy=market_cap&sortType=desc&convert=USD,BTC,ETH&cryptoType=all&tagType=all&audited=false&aux=ath,atl,high24h,low24h,num_market_pairs,cmc_rank,date_added,tags,platform,max_supply,circulating_supply,self_reported_circulating_supply,self_reported_market_cap,total_supply,volume_7d,volume_30d&tagSlugs=dwf-labs-portfolio";


function ResearchBinanceNoPerps(props: ComponentProps) {
    
    const [spotCoins, setSpotCoins] = React.useState<BinanceCoin[]>([]);
    const [perpsCoins, setPerpsCoins] = React.useState<BinanceCoin[]>([]);
    const [marketCaps, setMarketCaps] = React.useState<MarketCap[]>([]);
    const [dwfCoins, setDwfCoins] = React.useState<string[]>([]);
    const [dwfOnly, setDwfOnly] = React.useState<boolean>(false);

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch(binancePerpsUri, {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let coins: BinanceCoin[] = [];
            data.symbols.forEach((s: any) => {
                if (s.contractType === "PERPETUAL" && coins.findIndex(c => c.coin === s.baseAsset) === -1 && s.underlyingType === "COIN") {
                    coins.push({
                        coin: s.baseAsset.replace("1000", ""),
                        createTime: s.onboardDate,
                        mcap: 0
                    });
                }
            });
            setPerpsCoins(coins.sort((a, b) => a.createTime > b.createTime ? -1 : 1));
        });

        
        
    }, [, ]);

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch(binanceSpotUri, {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let coins: BinanceCoin[] = [];
            data.symbols.forEach((s: any) => {

                if (s.status !== "TRADING") {
                    return;
                }

                if (coins.findIndex(c => c.coin === s.baseAsset) === -1) {
                    coins.push({
                        coin: s.baseAsset,
                        createTime: s.createTime,
                        mcap: 0
                    });
                }
            });
            setSpotCoins(coins);
        });

        
        
    }, [, ]);

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch("https://news.kryptrader.com/mcap", {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let working: MarketCap[] = [];
            data.forEach((s: any) => {
                let existingIndex = working.findIndex(c => c.coin === s.symbol.toUpperCase());
                if (existingIndex === -1) {
                    working.push({
                        coin: s.symbol.toUpperCase(),
                        marketCap: s.market_cap,
                    });
                }
                else {
                    working[existingIndex].marketCap = Math.max(working[existingIndex].marketCap, s.market_cap);
                }
            });
            setMarketCaps(working);
        });

        
        
    }, [, ]);

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch("https://news.kryptrader.com/dwf", {
            method: "GET",
          })
        .then(async response => {
            //console.log(response);
            //console.log(await response.text());
            return await response.json();
        } )
        .then(data => {
            let coins: string[] = [];
            data.data.cryptoCurrencyList.forEach((s: any) => {

                coins.push(s.symbol.toUpperCase());

            });
            setDwfCoins(coins);
        })
        .catch(e => {
            console.log(e);
        })
        ;     

    }, [, ]);

    let spot = spotCoins
        .filter(s => !s.coin.endsWith("DOWN") && !s.coin.endsWith("UP") && !s.coin.endsWith("BULL") && !s.coin.endsWith("BEAR") && !s.coin.endsWith("USD") && !s.coin.startsWith("USD") && !s.coin.endsWith("BTC") && !s.coin.endsWith("ETH") && !s.coin.endsWith("USDT"))
        .map(s => { return {coin: s.coin, createTime: s.createTime, mcap: marketCaps.find(m => m.coin === s.coin)?.marketCap || 0} })
        .filter(s => dwfOnly ? dwfCoins.includes(s.coin) : true)
        .sort((a, b) => a.mcap > b.mcap ? -1 : 1);

    return (
        <Box>
            {
                perpsCoins.length > 0 && spotCoins.length > 0
                ? 
                <Box >
                <Typography sx={{fontWeight: 500, pl: 2, mb: 2, mt: 2}} variant="body2">Binance coins (Spot, no perps)</Typography>
                <Box sx={{alignContent: 'baseline', mb: 2, ml: 2}}>
                    <Typography variant="body2" component="span">DWF coins only?</Typography>
                    <Switch checked={dwfOnly} onChange={() => setDwfOnly(!dwfOnly)} />
                </Box>
                <Paper elevation={3} sx={{m: 2, mt: 0, p: 2, pt: 0}}>
                    
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Coin</TableCell>
                                        <TableCell>DWF Port?</TableCell>
                                        <TableCell sx={{textAlign: "right"}}>Market cap</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        spot.map(l => {

                                            let hasPerps = perpsCoins.find(m => m.coin === l.coin);

                                            if (hasPerps) {
                                                return null;
                                            }
                                            
                                            return (
                                                <TableRow key={l.coin}>
                                                    <TableCell>{l.coin}</TableCell>
                                                    <TableCell>
                                                        {
                                                            dwfCoins.includes(l.coin)
                                                            ? <Typography component="span" sx={{color: blue[500]}}>Yes</Typography>
                                                            : null
                                                        }
                                                    </TableCell>
                                                    <TableCell sx={{textAlign: "right"}}>{usdtLocale.format(l.mcap)}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    </Box>
                : <Typography>Loading coins ...</Typography>
            }

            <Typography sx={{m: 2, borderBottom: "1px solid gray"}}>Notes</Typography>
            <Typography sx={{ml: 2}} variant="body2">
                Binance perps coins from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{binancePerpsUri}</Typography>.<br /><br />
                Binance spot coins from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{binanceSpotUri}</Typography>.<br /><br />
                DWF portfolio coins from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{dwfCoinsUri}</Typography>.<br /><br />
                Underlying type for perps must be "COIN" (not "INDEX") and contract names starting with "1000" were corrected.
            </Typography>
        </Box>
    );
}

export default ResearchBinanceNoPerps;
