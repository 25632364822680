import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import SettingsTradeValue from "./SettingsTradeValue";
import SettingsOptions from "./SettingsOptions";
import SettingsAutoStrategy from "./SettingsAutoStrategy";
import SettingsConfig from "./SettingsConfig";

function Settings() {
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={selectedTab}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setSelectedTab(newValue);
          }}
          aria-label="basic tabs example"
        >
          <Tab label="Order values" />
          <Tab label="Options" />
          <Tab label="Auto strategy" />
          <Tab label="Config" />
        </Tabs>
      </Box>

      <Box sx={{p:2}}>
      {
        {
          0: <SettingsTradeValue />,
          1: <SettingsOptions />,
          2: <SettingsAutoStrategy />,
          3: <SettingsConfig />
        }[selectedTab]
      }
      </Box>
    </Box>
  );
}

export default Settings;
