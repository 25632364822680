import React from "react";
import {
  IconButton,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  Paper,
  Switch,
  FormControlLabel,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { selectColumns, selectFirehoseMode, selectShowEmbeddedTweet, setColumns, setFirehoseMode, setShowEmbeddedTweet, selectFadeTimeout, setFadeTimeout, selectDismissDisableTimeout, setDismissDisableTimeout } from "./reducers/newsReducer";
import { selectIsMuted, setIsMuted } from "./reducers/authorizationSlice";

function SettingsNews() {
    
    const dispatch = useDispatch();
    const columns = useSelector(selectColumns);
    const dismissDisableTimeout = useSelector(selectDismissDisableTimeout);
    const fadeTimeout = useSelector(selectFadeTimeout);
    const showEmbeddedTweet = useSelector(selectShowEmbeddedTweet);
    const firehoseMode = useSelector(selectFirehoseMode);
    const isMuted = useSelector(selectIsMuted);

    const [tempColumns, setTempColumns] = React.useState<number>(columns);
    const [tempFadeTimeout, setTempFadeTimeout] = React.useState<number>(fadeTimeout);
    const [tempDismissDisableTimeout, setTempDismissDisableTimeout] = React.useState<number>(dismissDisableTimeout);
    
    return (
        <Grid container> 
            <Grid item xs={12}>
                <Paper elevation={3} sx={{p:2, m:1 }}>
                    <Typography variant="h6" sx={{mb: 1}}>News settings</Typography>

                    <Grid container>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <TextField
                                variant="standard"
                                value={tempColumns}
                                type="number"
                                sx={{width: 36}}
                                onChange={(e) => { setTempColumns(parseInt(e.currentTarget.value || "0")); }}
                                onBlur={() => { dispatch(setColumns(tempColumns)) }}
                            />
                            <Typography sx={{ml: 1}}>Columns</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <TextField
                                variant="standard"
                                value={tempDismissDisableTimeout}
                                type="number"
                                sx={{width: 36}}
                                onChange={(e) => { setTempDismissDisableTimeout(parseInt(e.currentTarget.value || "0")); }}
                                onBlur={() => { dispatch(setDismissDisableTimeout(tempDismissDisableTimeout)) }}
                            />
                            <Typography sx={{ml: 1}}>Dismiss disable timeout (s)</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <TextField
                                variant="standard"
                                value={tempFadeTimeout}
                                type="number"
                                sx={{width: 36}}
                                onChange={(e) => { setTempFadeTimeout(parseInt(e.currentTarget.value || "0")); }}
                                onBlur={() => { dispatch(setFadeTimeout(tempFadeTimeout)) }}
                            />
                            <Typography sx={{ml: 1}}>Fade timeout (s)</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Switch checked={isMuted} onChange={() => dispatch(setIsMuted(!isMuted))} />} label="Muted?" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Switch checked={showEmbeddedTweet} onChange={() => dispatch(setShowEmbeddedTweet(!showEmbeddedTweet))} />} label="Show embedded tweet? (faster, but no images or reply-to details)" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Switch checked={firehoseMode} onChange={() => dispatch(setFirehoseMode(!firehoseMode))} />} label="Firehose mode? (ignore server-side Twitter filtering)" />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default SettingsNews;
