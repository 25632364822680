import React from "react";
import { Box, Button, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material";
import ResearchBinanceCoinsKoExchanges from "./ResearchBinanceCoinsKoExchanges";
import ResearchBinancePerpsNoSpot from "./ResearchBinancePerpsNoSpot";
import ResearchBinanceNoPerps from "./ResearchBinanceNoPerps";
import ResearchBinanceNoPerpsBybitPerps from "./ResearchBinanceNoPerpsBybitPerps";
import ResearchBinanceOpenInterest from "./ResearchBinanceOpenInterest";
import { ArrowBack } from "@mui/icons-material";

function Research() {
  const [selectedTab, setSelectedTab] = React.useState<number>(-1);

  return (
    <Box>
        {
        selectedTab === -1
        ? 
        <>
        <Typography sx={{m: 2}}>Choose a type</Typography>
      <Grid container>
        <Grid item xs={12} sx={{m: 2}}>
            <Button onClick={() => setSelectedTab(0)}>Binance coins on KO exchanges</Button>
        </Grid>
        <Grid item xs={12} sx={{m: 2, mt: 0}}>
        <Button onClick={() => setSelectedTab(1)}>Binance perps / no spot</Button>
        </Grid>
        <Grid item xs={12} sx={{m: 2, mt: 0}}>
        <Button onClick={() => setSelectedTab(2)}>Binance no perps</Button>
        </Grid>
        <Grid item xs={12} sx={{m: 2, mt: 0}}>
            <Button onClick={() => setSelectedTab(3)}>Bybit perps (no binance)</Button>
        </Grid>
        <Grid item xs={12} sx={{m: 2, mt: 0}}>
            <Button onClick={() => setSelectedTab(4)}>Binance open interest</Button>
        </Grid>
      </Grid>
      </>
: null }


      {
        selectedTab > -1
        ? <Box><IconButton onClick={() => setSelectedTab(-1)} sx={{m: 2, mt: 0, float: 'right'}}>
            <ArrowBack />
        </IconButton></Box>
        : null
      }

      <Box sx={{p:2, pt: 0, mt: 1}}>
      {
        {
          0: <ResearchBinanceCoinsKoExchanges />,
          1: <ResearchBinancePerpsNoSpot />,
          2: <ResearchBinanceNoPerps />,
          3: <ResearchBinanceNoPerpsBybitPerps />,
          4: <ResearchBinanceOpenInterest />,
        }[selectedTab]
      }
      </Box>
    </Box>
  );
}

export default Research;
