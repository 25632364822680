import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { CandlestickData, LineData, createChart, ColorType, LineStyle, IChartApi, ISeriesApi, SeriesMarker, Time, IPriceLine, UTCTimestamp } from 'lightweight-charts';
import { amber, blue, green, grey, orange, purple, red } from "@mui/material/colors";
import { serializeStyles } from "@emotion/serialize";
import { Box, Paper } from "@mui/material";
import { isJsxOpeningElement } from "typescript";

export interface LineConfig {
    name: string,
    value: number,
    active: boolean,
}

export interface TradeConfig {
    takeProfits: LineConfig[],
    stopLoss?: number,
    buyTimestamps?: number[],
    buyPrice?: number,
    sellTimestamp?: number,
    trailingStopLoss?: number
    sellPrice?: number,
}

interface ComponentProps {
    symbolString: string;
    data: CandlestickData[];
    precision: number,
    buyTimestamp?: number,
    buyPrice?: number,
    sellTimestamp?: number,
    takeProfit1?: number,
    takeProfit2?: number,
    takeProfit3?: number,
    stopLoss?: number,
    trailingStopLoss?: number
    tradeConfig: TradeConfig | null,
    height?: number | undefined;
    showAsPercent?: boolean;
    showEMA?: boolean;
    /** If provided, all y-axis values and lines will be calculated relative to this value */
    relativeToBuyPrice?: boolean;
    //data: LineData[];
}

function mapCandlestickToLineData(candle: CandlestickData): LineData {
    return {
        time: candle.time,
        value: candle.close, // Typically, EMA is based on the close price
        //color: candle.color // Using the candle's color for the line data, but you can adjust as needed
    };
}

function addTenSecondsToTime(time: Time): Time {
    const TEN_SECONDS = 10 * 1000;

    if (isUTCTimestamp(time)) {
        return (time as any + TEN_SECONDS) as UTCTimestamp;
    } else if (typeof time === 'string') {
        let date = new Date(time);
        date.setSeconds(date.getSeconds() + 10);
        return date.toISOString();
    } else {
        // For BusinessDay, we're not adding 10 seconds as it doesn't make sense in this context.
        // You might want to handle this differently based on your requirements.
        return time;
    }
}

function isUTCTimestamp(time: Time): time is UTCTimestamp {
    return typeof time === 'number';
}

function fillMissingKlines(data: CandlestickData[]): CandlestickData[] {
    if (data.length === 0) return [];

    const TEN_SECONDS = 10 * 1000; // 10 seconds in milliseconds

    // 1. Build an array of expected times
    let startTime = data[0].time as any as UTCTimestamp;
    let endTime = data[data.length - 1].time as any as UTCTimestamp;
    let expectedTimes: UTCTimestamp[] = [];

    for (let time = startTime as any; time <= endTime as any; time += 10) {
        expectedTimes.push(time);
    }

    // 2. Find missing times
    let actualTimes = data.map(d => d.time as any as UTCTimestamp);
    let missingTimes = expectedTimes.filter(time => !actualTimes.includes(time));

    // 3. Populate the missing times with data
    let missingData: CandlestickData[] = missingTimes.map(time => {
        let lastKnownRecord = data.slice().reverse().find(record => record.time as any < time);
        let lastClose = lastKnownRecord ? lastKnownRecord.close : data[0].close;

        return {
            time: time,
            open: lastClose,
            high: lastClose,
            low: lastClose,
            close: lastClose
        };
    });

    // 4. Merge the original and new data arrays
    let mergedData = [...data, ...missingData];

    // 5. Sort the merged array by time
    mergedData.sort((a, b) => (a.time as any) - (b.time as any));

    return mergedData;
}

function computeEMA(data: CandlestickData[], period: number, color?: string): LineData[] {
    if (data.length === 0) return [];

    let multiplier = 2 / (period + 1);
    let emaValues: LineData[] = [];
    let previousEMA = data[0].close; // Starting with the first data point as the initial EMA value

    emaValues.push({
        time: data[0].time,
        value: previousEMA,
        color: color
    });

    for (let i = 1; i < data.length; i++) {
        let currentEMA = ((data[i].close - previousEMA) * multiplier) + previousEMA;
        emaValues.push({
            time: data[i].time,
            value: currentEMA,
            color: color
        });
        previousEMA = currentEMA;
    }

    return emaValues;
}

function SymbolPriceTradingViewChartMemo(props: ComponentProps) {

    const chartContainerRef = React.useRef<HTMLDivElement>(null);

    const [chart, setChart] = React.useState<IChartApi | null>(null);
    const [newSeries, setNewSeries] = React.useState<ISeriesApi<"Candlestick"> | null>(null);
    const [newTechnicalIndicators1, setTechnicalIndicators1] = React.useState<ISeriesApi<"Line"> | null>(null);
    const [newTechnicalIndicators2, setTechnicalIndicators2] = React.useState<ISeriesApi<"Line"> | null>(null);
    const [newTechnicalIndicators3, setTechnicalIndicators3] = React.useState<ISeriesApi<"Line"> | null>(null);
    const [recentTime, setRecentTime] = React.useState<number>(0);

    const [tradeConfig, setTradeConfig] = React.useState<TradeConfig |null>(props.tradeConfig);

    const [showActiveTPOnly, setShowActiveTPOnly] = React.useState<boolean>(true);

    // Check if we are using a small screen device
    let isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

    const handleResize = () => {

        if (chart !== null) 
        {
            chart.applyOptions({ width: chartContainerRef.current !== null ? chartContainerRef.current.clientWidth : 100 });
        }
    };

    const priceLines = React.useRef<IPriceLine[]>([]);
    //const technicalIndicatorLines

    React.useEffect(
		() => {

            if (chartContainerRef.current !== null && chart === null && props.data?.length > 0)
            {
                // Clear the chartContainerRef
                chartContainerRef.current.innerHTML = '';

                // Build a new chart
                let tempChart = createChart(chartContainerRef.current, {
                    layout: {
                        background: { type: ColorType.Solid, color: "transparent" },
                        textColor: grey[50],
                        
                        //textColor,
                    },
                    grid: {
                        vertLines: {
                            style: LineStyle.SparseDotted,
                            color: grey[400],
                        },
                        horzLines: {
                            visible: false
                        }
                    },
                    timeScale: {
                        fixRightEdge: true,
                        //rightOffset: 20,
                        timeVisible: true,
                        barSpacing: chartContainerRef.current.clientWidth / 90.0,
                    },
                    rightPriceScale: {
                        //autoScale: true, // disables auto scaling based on visible content
                        scaleMargins: {
                            top: 0.1,
                            bottom: 0.1,
                        },
                        
                        //borderColor: '#ff44ee',
                        mode: props.showAsPercent ? 2 : 0,
                        
                    },
                    width: chartContainerRef.current.clientWidth,
                    height: props.height || (isSmallScreen ? 256 : 384),
                    localization: {
                      //  priceFormatter: 'price',
                    },
                    
                });

                // Show all available data by default
                tempChart.timeScale().fitContent();
                //chart.priceScale.

                // Fill in any missing klines
                let workingData = props.data;

                // Adjust data to a percentage of the buy price if required
                if (props.relativeToBuyPrice) {

                    let buyPrice = props.tradeConfig?.buyPrice || 1.0;
                    workingData = props.data.map(d => ({
                        time: d.time,
                        open: ((d.open / buyPrice) - 1.0) * 100.0 ,
                        high: ((d.high / buyPrice) - 1.0) * 100.0,
                        low: ((d.low / buyPrice) - 1.0) * 100.0,
                        close: ((d.close / buyPrice) - 1.0) * 100.0
                    }));
                }

                let gapFreeData = fillMissingKlines(workingData);

                if (props.showEMA) {
                    let tempTechnicalIndicators = tempChart.addLineSeries({
                        color: blue[500],
                        lineWidth: 1,
                        lastValueVisible: false,
                        //title: "EMA (1m)",
                        priceLineVisible: false,
                        priceFormat: {
                            type: props.showAsPercent || props.relativeToBuyPrice ? 'percent' : 'price',
                            precision: props.precision,
                            minMove: Math.max(0.00000001, 1.0 / Math.pow(10.0, (props.precision))),
                        },
                        
                    });

                    tempTechnicalIndicators.setData(computeEMA(gapFreeData, 6, blue[500]));

                    let tempTechnicalIndicators2 = tempChart.addLineSeries({
                        color: purple[500],
                        lineWidth: 1,
                        lastValueVisible: false,
                        //title: "EMA (2m)",
                        priceLineVisible: false,
                        priceFormat: {
                            type: props.showAsPercent || props.relativeToBuyPrice ? 'percent' : 'price',
                            precision: props.precision,
                            minMove: Math.max(0.00000001, 1.0 / Math.pow(10.0, (props.precision))),
                        },
                        
                    });

                    tempTechnicalIndicators2.setData(computeEMA(gapFreeData, 18, purple[500]));

                    let tempTechnicalIndicators3 = tempChart.addLineSeries({
                        color: amber[500],
                        lineWidth: 1,
                        lastValueVisible: false,
                        //title: "EMA (3m)",
                        priceLineVisible: false,
                        priceFormat: {
                            type: props.showAsPercent || props.relativeToBuyPrice ? 'percent' : 'price',
                            precision: props.precision,
                            minMove: Math.max(0.00000001, 1.0 / Math.pow(10.0, (props.precision))),

                        },
                        
                    });

                    tempTechnicalIndicators3.setData(computeEMA(gapFreeData, 30, amber[500]));
                    
                    setTechnicalIndicators1(tempTechnicalIndicators);
                    setTechnicalIndicators2(tempTechnicalIndicators2);
                    setTechnicalIndicators3(tempTechnicalIndicators3);
                    }
                // Add this last so that it's in front
                let tempSeries = tempChart.addCandlestickSeries({
                    wickUpColor: green[500],
                    upColor: green[500],
                    wickDownColor: red[500],
                    downColor: red[500],
                    borderVisible: false,
                    
                    priceFormat: {
                        type: props.showAsPercent || props.relativeToBuyPrice ? 'percent' : 'price',
                        precision: props.precision,
                        minMove: Math.max(0.00000001, 1.0 / Math.pow(10.0, (props.precision))),
                    },
                    priceLineVisible: true,
                });
            

                tempSeries.setData(gapFreeData);

                  setChart(tempChart);
                  setNewSeries(tempSeries);
                  

                return () => {
                    window.removeEventListener('resize', handleResize);
    
                    if (chart !== null) {
                       // chart.current.remove();
                    }
                };

            }
			
            return;
		},
		[props.data]
	);

    React.useEffect(() => {

        
        if (newSeries && newSeries !== null) {
        //if (JSON.stringify(props.tradeConfig) !== JSON.stringify(tradeConfig)) {

        
            // Snap a copy of the old config (for removing values)
            // let copyConfig = JSON.parse(JSON.stringify(tradeConfig)) as TradeConfig;

            // Update the config
            setTradeConfig(props.tradeConfig);

            // Add the new config



            priceLines.current.map(t => {
                newSeries.removePriceLine(t);
            })
            
            // Remove everything from the old config

            // Add the new config
            props.tradeConfig?.takeProfits.map(tp => {

                if (showActiveTPOnly && !tp.active) {
                    return;
                }

                priceLines.current.push(newSeries.createPriceLine({
                    price: props.relativeToBuyPrice ? (tp.value / (props.tradeConfig?.buyPrice || 1.0) - 1.0) * 100.0 : tp.value,
                    color: tp.active ? blue[500] : blue[900],
                    lineWidth: 2,
                    lineStyle: LineStyle.Dotted,
                    lineVisible: true,
                    axisLabelVisible: true,
                    title: tp.name,
                    //draggable: true,
                }));
            });

            if (props.tradeConfig?.buyPrice) {
                priceLines.current.push(newSeries.createPriceLine({
                    price: props.relativeToBuyPrice ? 0.0 : props.tradeConfig?.buyPrice,
                    color: grey[500],
                    lineWidth: 2,
                    lineStyle: LineStyle.Dotted,
                    lineVisible: true,
                    axisLabelVisible: true,
                    title: 'Open',
                    //draggable: true,
                }));
            }

            if (props.tradeConfig?.sellPrice) {
                priceLines.current.push(newSeries.createPriceLine({
                    price: props.relativeToBuyPrice ? (props.tradeConfig?.sellPrice / (props.tradeConfig?.buyPrice || 1.0) - 1.0) * 100.0 : props.tradeConfig?.sellPrice,
                    color: grey[500],
                    lineWidth: 2,
                    lineStyle: LineStyle.Dotted,
                    lineVisible: true,
                    axisLabelVisible: true,
                    title: 'Close',
                    //draggable: true,
                }));
            }
            
            if (props.tradeConfig?.stopLoss) {
                priceLines.current.push(newSeries.createPriceLine({
                    price: props.relativeToBuyPrice ? (props.tradeConfig?.stopLoss / (props.tradeConfig?.buyPrice || 1.0) - 1.0) * 100.0 : props.tradeConfig?.stopLoss,
                color: orange[500],
                lineWidth: 2,
                lineStyle: LineStyle.Dotted,
                lineVisible: true,
                axisLabelVisible: true,
                title: 'SL',
                
            }));
        }

            if (props.tradeConfig?.trailingStopLoss) {
                priceLines.current.push(newSeries.createPriceLine({
                price: props.relativeToBuyPrice ? (props.tradeConfig?.trailingStopLoss / (props.tradeConfig?.buyPrice || 1.0) - 1.0) * 100.0 : props.tradeConfig?.trailingStopLoss,
                color: orange[500],
                lineWidth: 2,
                lineStyle: LineStyle.Dotted,
                lineVisible: true,
                axisLabelVisible: true,
                title: 'TSL',
                //draggable: true,
                }));
        }
                
            const markers:SeriesMarker<Time>[] = [];

            if ((props.tradeConfig?.buyTimestamps || []).length > 0) {
                (props.tradeConfig?.buyTimestamps || []).map((t, i) => {
                    markers.push({
                        time: Math.floor(Math.floor(t / 10000.0) * 10.0) as Time,
                        position: 'belowBar',
                        color: i == 0 ? grey[200] : grey[300],
                        shape: 'arrowUp',
                        text: i == 0 ? 'Open' : '+Open',
                    });
                });
                /*
                markers.push({
                    time: Math.floor(Math.floor((props.tradeConfig?.buyTimestamp || 0.0) / 10000.0) * 10.0) as Time,
                    position: 'belowBar',
                    color: grey[200],
                    shape: 'arrowUp',
                    text: 'Buy',
                });
                */
                // Add extra buy markers
                
            }

            if (props.tradeConfig?.sellTimestamp) {
                markers.push({
                    time: Math.floor(Math.floor((props.tradeConfig?.sellTimestamp || 0.0) / 10000.0) * 10.0) as Time,
                    position: 'aboveBar',
                    color: orange[700],
                    shape: 'arrowDown',
                    text: 'Close',
                })
            }

            newSeries.setMarkers(markers);
    }
    }, [tradeConfig, props.data])
    
    React.useEffect(
		() => {

            if (newSeries && newSeries !== null) {
                // Update the config
                if (JSON.stringify(props.tradeConfig) !== JSON.stringify(tradeConfig)) {
                    setTradeConfig(props.tradeConfig);
                }
            }
			
            return;
		},
		[props.tradeConfig]
	);

    React.useEffect(
		() => {

            if (newSeries && newSeries !== null) {
                // A bit shit, we shouldn't be directly updating a state object
                // Not quite sure what side effects will come from this
                if (props.data.length > 0) {

//                    console.log(props.data[props.data.length-1])

                    if (props.data[props.data.length-1].time as number >= recentTime) {

                        // If required, change values to be relative to buy price
                        // Fill in any missing klines
                        let workingData = props.data;

                        // Adjust data to a percentage of the buy price if required
                        if (props.relativeToBuyPrice) {
                            let buyPrice = props.tradeConfig?.buyPrice || 1.0;
                            workingData = props.data.map(d => ({
                                time: d.time,
                                open: ((d.open / buyPrice) - 1.0) * 100.0,
                                high: ((d.high / buyPrice) - 1.0) * 100.0,
                                low: ((d.low / buyPrice) - 1.0) * 100.0,
                                close: ((d.close / buyPrice) - 1.0) * 100.0
                            }));
                        }



                        // In case we have missing klines, just do a set
                        // This is more expensive than an update, but we don't have a great range of choices without a smart function 
                        // that adds a range on the end of the data in the correct sequence
                        let gapFreeData = fillMissingKlines(workingData);
                        newSeries.setData(gapFreeData);
                        //newSeries.update(props.data[props.data.length-1]);

                        if (props.showEMA) {
                        
                            // EMA 1m
                            if (newTechnicalIndicators1 && newTechnicalIndicators1 !== null) {

                                // This isn't great, but we need to recalc
                                if (gapFreeData.length >= 20) {
                                    let tempEMA = computeEMA(gapFreeData.slice(-20), 6, blue[500]);
                                    newTechnicalIndicators1.update(tempEMA[tempEMA.length-1]);
                                }
                            }
                            
                            // EMA 2m
                            if (newTechnicalIndicators2 && newTechnicalIndicators2 !== null) {
                                // This isn't great, but we need to recalc
                                if (gapFreeData.length >= 20) {
                                    let tempEMA = computeEMA(gapFreeData.slice(-20), 18, purple[500]);
                                    newTechnicalIndicators2.update(tempEMA[tempEMA.length-1]);
                                }
                            }

                            // EMA 3m
                            if (newTechnicalIndicators3 && newTechnicalIndicators3 !== null) {
                                if (gapFreeData.length >= 20) {
                                    let tempEMA = computeEMA(gapFreeData.slice(-20), 30, amber[500]);
                                    newTechnicalIndicators3.update(tempEMA[tempEMA.length-1]);
                                }
                            }
                        }
                    }

                    //setRecentTime(props.data[props.data.length-1].time.valueOf());

                    // Resize handler was causing memory leaks
                    handleResize();
                }
            }
			
            return;
		},
		[props.data]
	);

  return (
    <Box sx={{ width: '100%', height: isSmallScreen ? '50%' : '100%', p:1, mt: 2  }}>
        {
            props.showEMA 
            ?<Typography variant="body2" sx={{m: 0, p: 0}}>
                <Typography component="span"  variant="body2" sx={{m: 0, p: 0, fontSize: '0.75em', color: blue[500]}}>
                EMA (1m) &nbsp;
                </Typography>
                <Typography component="span"  variant="body2" sx={{m: 0, p: 0, fontSize: '0.75em', color: purple[500]}}>
                EMA (3m) &nbsp;
                </Typography>
                <Typography component="span"  variant="body2" sx={{m: 0, p: 0, fontSize: '0.75em', color: amber[500]}}>
                EMA (5m) &nbsp;
                </Typography>
            </Typography>
            : null
        }
        
        <div style={{ width: '100%', height: isSmallScreen ? '50%' : '100%' }}>
            <div
                ref={chartContainerRef}
            />
        </div>
    </Box>
  );
}

export default React.memo(SymbolPriceTradingViewChartMemo);
