import React from "react";
import { Toolbar } from '@mui/material';
import {
    Alert,
    AlertTitle,
    Box,
    Divider,
    Hidden,
    Paper,
    Link,
    Snackbar,
    Typography,
    Drawer,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { Masonry } from '@mui/lab';

import NewsItem from "./components/NewsItem";
import NewsItemAlert from "./components/NewsItemAlert";

import { useSelector, useDispatch } from "react-redux";
import { News, news, selectColumns } from "./reducers/newsReducer";

interface ComponentProps {
    drawerWidth: number,
    side: "left" | "right",
    isSmallScreen: boolean,
}

function NewsMasonryDisplay(props: ComponentProps) {
    const list = useSelector(news);
    const columns = useSelector(selectColumns);

    const theme = useTheme();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>
            <Masonry columns={isSmall ? Math.min(1, columns) : (isMedium ? Math.min(3, columns) : columns)} sx={{pl: 1, pt: 1}}>
            {[...(list || [])]
                .splice(0, 30)
                .filter(n => (n.dismissed || false) === false)
                .map((n: News, index: number) => {
                    return <NewsItem key={`${n.id || n.uri || n.title}-${n.source}`} item={n} index={index} />;
                })}
            </Masonry>
        </Box>
    );
}

export default NewsMasonryDisplay;
