import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { green, red, grey } from "@mui/material/colors";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { selectPrices, selectAvailableQuotes, selectExchanges, Price } from "../reducers/priceReducer";
import { GetLastPeriodPrice as GetLastPeriodMovement, GetPrimaryQuote } from "../utils/PriceUtils";
import { MovementInfo } from "./symbol/SymbolPrice";
import { addSymbol } from "../reducers/symbolReducer";
import { RootState } from "../store";

interface ComponentProps {
  symbol: string;
}

// USDT formatter
// Todo - different precision models for diff currencies
const usdtLocale = Intl.NumberFormat("en-US", {
  style: "decimal",
  currency: "USD",
  //maximumSignificantDigits: 7,
  //minimumSignificantDigits: 2,
  minimumFractionDigits: 2,
  maximumFractionDigits: 8
  
});

function PriceTicker(props: ComponentProps) {

  //const currentPrices = useSelector(prices);
  const dispatch = useDispatch();

  const availableQuotes = useSelector(selectAvailableQuotes);
  const exchanges = useSelector(selectExchanges);

  let arr = GetPrimaryQuote(props.symbol, exchanges, availableQuotes);
  let exchange = arr[0];
  let quote = arr[1];

  const prices:Price[] = useSelector(
    (state) => selectPrices(state as RootState, props.symbol),
    shallowEqual
  );

  let binancePrice = prices?.find(s => s.exchange === exchange && s.quote === quote);

  let lastMinMovement = GetLastPeriodMovement(prices, exchange, 60, quote);

  return (
    <Paper sx={{ p: 1, mr: 1, mt: 1, minWidth: 210 }} elevation={3}>
        <Grid container>
            <Grid item xs={6}>
            <Typography
        variant="body1"
        component="p"
        sx={{ fontWeight: 600, textAlign:'center' }}
        color={lastMinMovement > 0 ? green[600] : lastMinMovement < 0 ? red[600] : undefined}
      >
        {usdtLocale.format(binancePrice?.price || 0)}
      </Typography>
      <Typography
            variant="body2"
            sx={{ textAlign:'center', mt: 0.33, cursor: "pointer" }}
            onClick={() => { dispatch(addSymbol({ symbol: props.symbol, pinned: true})); }}
        >{props.symbol}<span style={{color: grey[700]}}> / {quote}</span></Typography>
            </Grid>
            <Grid item xs={6}>
            <MovementInfo movement={lastMinMovement} label="1 min" chartDurationChange={() => { }} />
            </Grid>
        </Grid>  
    </Paper>
  );
}

export default PriceTicker;
