import { AvailableQuotes, Price } from "../reducers/priceReducer";

export function GetLastPeriodPrice(prices:Price[], exchange:string, duration:number, quote:string) {

    let binancePrice = prices?.find(s => s.exchange === exchange && s.quote === quote);

    let lastPeriodPrice = binancePrice?.history?.find(
      (p) => p.timestamp < (new Date().valueOf() / 1000) - duration && p.timestamp > (new Date().valueOf() / 1000) - (duration+30)
    );
  
    let lastPeriodMovement = 0;
    if (lastPeriodPrice && binancePrice) {
        lastPeriodMovement = binancePrice.price / lastPeriodPrice.price - 1.0;
    }
  
    return lastPeriodMovement;
  
}

export function GetPrimaryQuote(symbol: string, exchanges:string[], availableQuotes: AvailableQuotes[]) {

    let exchange: string = "";
    let quote: string = "";

// Go through the exchange list in order and find a match
for (let i = 0; i < exchanges.length; i++) {
    let foundQuote = availableQuotes.find(q => q.base === symbol && q.exchange === exchanges[i]);
    if (foundQuote !== undefined) {
        exchange = foundQuote.exchange;
        if (foundQuote.quotes.includes("USDT")) {
            quote = "USDT";
        }
        else if (foundQuote.quotes.includes("BUSD")) {
            quote = "BUSD";
        }
        else if (foundQuote.quotes.includes("BTC")) {
            quote = "BTC";
        }
        i = exchanges.length;
    }
}

//console.log(`${exchange}: ${symbol}/${quote}`)

return [exchange, quote];

}