import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
  Paper,
  List,
  ListItemButton,
  Tooltip,
  IconButton,
  DialogActions,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import { status } from "./reducers/terminalReducer";
import { TradeType } from "./enums/TradeType";
import { Exchange, ExchangeEnum } from "./enums/Exchange";
import { MarketType } from "./enums/MarketType";
import { MaximumTrade } from "./types/BotUpdate";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { amber, red } from "@mui/material/colors";

interface ComponentProps {}

// Todo - different precision models for diff currencies
const usdtLocale = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  notation: 'compact',
  maximumFractionDigits: 0,
});

const FocusText = (props: {text: string}) => {
    return <Typography variant="body2" color="text.primary" component="span"><strong>{props.text}</strong></Typography>
}

function SettingsAutoStrategy(props: ComponentProps) {
  const botStatus = useSelector(status);
  const [showMaxTrades, setShowMaxTrades] = React.useState<MaximumTrade[]>([]);

  const [botInstance, setBotInstance] = React.useState<number>(0);
  const [botChooserOpen, setBotChooserOpen] = React.useState<boolean>(false);

    const getMaxTrades = (strategy:string, range:string ) => {
        let maxTrades = botStatus[botInstance].tradeSettings?.find(t => t.name === strategy)?.ranges.find(r => r.name === range)?.maximumTrades || [];
        setShowMaxTrades(maxTrades);
    }

    const [specificCoins, setSpecificCoins] = React.useState<string[]>([]);

  return (
    
    <>
      <Dialog
        open={specificCoins.length > 0}
        onClose={() => setSpecificCoins([])}
        maxWidth="sm"
        fullWidth={true}
        >
        <DialogTitle>Coins</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableBody>
                {[...specificCoins].sort((a, b) => {return a < b ? -1 : 1}).map((coin, index) => (
                  <TableRow key={index}>
                    <TableCell>{coin}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setSpecificCoins([])}>Close</Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Box sx={{display:'flex', alignItems: 'center' }}>
        Auto trade strategies (viewing {botStatus[botInstance].botName})

        <Tooltip title="View a different bot">
            <IconButton
                onClick={() => setBotChooserOpen(true)}
            >
                <SmartToyIcon />
            </IconButton>
        </Tooltip>
        </Box>
        
        <Dialog
            maxWidth="sm"
            fullWidth
            open={botChooserOpen}
            onClose={() => setBotChooserOpen(false)}
        >
            <DialogTitle>Which bot?</DialogTitle>
            <DialogContent>
                <List>
                {
                    botStatus.map((b, i) => {
                        return (<ListItemButton selected={i === botInstance} onClick={() => {setBotInstance(i); setBotChooserOpen(false)}}>{b.botName}</ListItemButton>)
                    })
                }
                </List>
            </DialogContent>
        </Dialog>

        <Dialog
            maxWidth="sm"
            fullWidth
            open={showMaxTrades.length > 0 ? true : false}
            onClose={() => setShowMaxTrades([])}
        >
            <DialogTitle>Maximum trades</DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Exchange</TableCell>
                            <TableCell>Market</TableCell>
                            <TableCell sx={{ textAlign: "right" }}>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                    showMaxTrades.map((o, i) => {
                        return <TableRow key={i}>
                            <TableCell>{ExchangeEnum[o.exchange]}</TableCell>
                            <TableCell>{MarketType[o.market]}</TableCell>
                            <TableCell sx={{ textAlign: "right" }}>{usdtLocale.format(o.value)}</TableCell>
                        </TableRow>
                    })
                }
                </TableBody>
                </Table>
            </DialogContent>
        </Dialog>

        {
                botStatus.length > botInstance
                ?
                [...(botStatus[botInstance].tradeSettings || [])].sort((a, b) => a.name < b.name ? -1 : 1).filter(a => !a.disabled).map((o) => {
                    return (
                    <Grid item xs={12} key={o.name}>
                        <Paper elevation={3} sx={{mb:1, mt: 1, p:1 }}>
                            <Typography variant="h6" sx={{fontWeight:500}}>
                                {o.name} {o.disabled ? " (disabled)" : ""}
                            </Typography>
                            {
                                o.maxSymbols ?
                                    <Typography variant="body2" color="text.secondary" sx={{pt:1}}>
                                        <FocusText text="Max symbols: " />Will not trade if <FocusText text={`> ${o.maxSymbols.toString()}`} /> symbols
                                    </Typography>
                                    : null
                            }
                            {
                                o.initialPriceSecondsBack ?
                                    <Typography variant="body2" color="text.secondary" sx={{pt:1}}>
                                        For Binance (spot &amp; perps) initial price will lag by {o.initialPriceSecondsBack}s
                                    </Typography>
                                    : null
                            }
                            <Typography variant="body2" color="text.secondary" sx={{pt:1}}>
                                <FocusText text="Applies to: " />{o.appliesTo.map(a => TradeType[a]).join(" | ")}
                            </Typography>
                            {
                                o.maxPumpWindowSeconds
                                ?   <Typography variant="body2" color="text.secondary" sx={{pt:1}}>
                                        <FocusText text="Auto reducing TP: " />After <FocusText text={`${o.maxPumpWindowSeconds.toString()}s`} /> the TP will reduce by <FocusText text={(o.takeProfitReductionPercent || 0.0).toLocaleString(undefined, {style: "percent", minimumFractionDigits: 2, })} /> every <FocusText text={`${(o.takeProfitReductionPeriodSeconds || "").toString()}s`} />
                                    </Typography>
                                : null
                            }
                            <Paper key={`${o.name}|Heading`} elevation={3} sx={{mb:1, mt: 1, p:1 }}>
                                <Grid container sx={{fontWeight:600, fontSize: '0.9em'}}>
                                    <Grid item xs={6} md={1}>Name</Grid>
                                    <Grid item xs={6} md={2}>M Cap</Grid>
                                    <Grid item xs={6} md={2} lg={1}>Init %</Grid>
                                    <Grid item xs={6} md={2} lg={1}>TP %</Grid>
                                    <Grid item xs={6} md={2} lg={1}>TP inc.</Grid>
                                    <Grid item xs={6} md={2} lg={1}>SL %</Grid>
                                    <Grid item xs={6} md={2} lg={1}>TSL %</Grid>
                                    <Grid item xs={6} md={2} lg={1}>TSL delay</Grid>
                                    <Grid item xs={6} md={2} lg={1}>Price delay</Grid>
                                    <Grid item xs={6} md={2} lg={1}>Timer</Grid>
                                    <Grid item xs={6} md={2} lg={1}>Max settings</Grid>
                                </Grid>
                            </Paper>
                            {
                                (o.ranges || []).map((r,i) => {

                                    return (
                                        <Paper key={`${o.name}|${r.name}`} elevation={3} sx={{mb:1, mt: 1, p:1, color: r.notOnBinance === true ? amber[300] : (r.initialRisk < 0.0 ? red[300] : null) }}>
                                            <Grid container sx={{fontSize: '0.9em'}}>
                                                <Grid item xs={6} md={1} sx={{}}>{r.name}</Grid>
                                                <Grid item xs={6} md={2}>
                                                    {r.coins 
                                                        ? <Link sx={{cursor:'pointer'}} onClick={() => setSpecificCoins(r.coins || [])}>{(r.coins || []).length} coin(s)</Link>
                                                        : `${usdtLocale.format(r.fromMarketCap || 0)} to ${usdtLocale.format(r.toMarketCap || 100000000000000)}`}
                                                </Grid>
                                                <Grid item xs={6} md={2} lg={1}>{r.initialRisk.toLocaleString(undefined, {style: "percent", minimumFractionDigits: 2, })}</Grid>
                                                <Grid item xs={6} md={2} lg={1}>{r.takeProfitVariable ? "~" : ""}{(r.takeProfitVariable || r.takeProfit).toLocaleString(undefined, {style: "percent", minimumFractionDigits: 2, })}</Grid>
                                                <Grid item xs={6} md={2} lg={1}>{r.incrementalTakeProfit ? (r.incrementalTakeProfit || 0).toLocaleString(undefined, {style: "percent", minimumFractionDigits: 2, }) : "-"}</Grid>
                                                <Grid item xs={6} md={2} lg={1}>{(r.stopLoss || 0.05).toLocaleString(undefined, {style: "percent", minimumFractionDigits: 2, })}</Grid>
                                                <Grid item xs={6} md={2} lg={1}>{(r.trailingStopLoss || 0.25).toLocaleString(undefined, {style: "percent", minimumFractionDigits: 2, })}</Grid>
                                                <Grid item xs={6} md={2} lg={1}>{(r.trailingStopLossDelayMs || 0) / 1000.0}s</Grid>
                                                <Grid item xs={6} md={2} lg={1}>{(r.initialPriceSecondsBack || 0)}s</Grid>
                                                <Grid item xs={6} md={2} lg={1}>{(r.sellAtMs || 0) / 1000.0}s</Grid>
                                                <Grid item xs={6} md={2} lg={1} sx={{p:0}}>
                                                    {
                                                        r.maximumTrades?.length > 0 
                                                            ? <Link sx={{cursor:'pointer'}} onClick={() => {getMaxTrades(o.name, r.name)}}>View</Link> 
                                                            : null
                                                    }
                                                     
                                                    {
                                                        r.notOnBinance === true 
                                                            ? <><Tooltip title="Not on Binance"><CurrencyExchangeIcon fontSize="small" sx={{p:0,m:0}} /></Tooltip></> 
                                                            : null
                                                        }
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )
                                })
                            }
                        </Paper>
                    </Grid>
                    )
                })
                : null
            }
      </Grid>
      </>      
  );
}

export default SettingsAutoStrategy;
