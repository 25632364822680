import {
  Link as MaterialLink,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import { DateTime } from "luxon";
import { grey, green, amber } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { addSymbol } from "../reducers/symbolReducer";
import { MarketPump } from "../types/Market";
import { Check, Circle, Close } from "@mui/icons-material";

interface ComponentProps {
  items: MarketPump[];
  krwUSDT: number;
  sortByVolume: boolean;
  bybitMarkets: String[];
}

const usdtLocale = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const usdtLocale2 = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumSignificantDigits: 7,
  minimumFractionDigits: 2,
  maximumFractionDigits: 12,
});

const getColor = (movement: number) => {
  if (movement > 0) {
    if (movement >= 0.07) {
      return green[700];
    } else if (movement >= 0.06) {
      return green[600];
    } else if (movement >= 0.05) {
      return green[500];
    } else if (movement >= 0.04) {
      return green[400];
    } else if (movement >= 0.03) {
      return green[300];
    } else if (movement >= 0.02) {
      return green[200];
    } else if (movement >= 0.01) {
      return green[100];
    }
  } else if (movement < 0) {
    if (movement <= -0.07) {
      return amber[700];
    } else if (movement <= -0.06) {
      return amber[600];
    } else if (movement <= -0.05) {
      return amber[500];
    } else if (movement <= -0.04) {
      return amber[400];
    } else if (movement <= -0.03) {
      return amber[300];
    } else if (movement <= -0.02) {
      return amber[200];
    } else if (movement <= -0.01) {
      return amber[100];
    }
  }

  return grey[500];
};

function PumpTable(props: ComponentProps) {
  const dispatch = useDispatch();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Coin</TableCell>
            <TableCell>Latest date</TableCell>
            <TableCell sx={{ textAlign: "right" }}>Price (USDT)</TableCell>
            <TableCell sx={{ textAlign: "right" }}>
              Volume (USDT) [% of 24h]
            </TableCell>
            <TableCell sx={{ textAlign: "right" }}>Movement (%)</TableCell>
            <TableCell sx={{ textAlign: "right" }}>Movement (% max)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((n) => {
            let percent_24h = n.volume / (n.volume_24h - n.volume);

            return (
              <TableRow key={n.coin}>
                <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                    {
                    props.bybitMarkets.includes(n.coin) ? (
                        <Tooltip title="Bybit Market" placement="top">
                            <Check color="success" />
                        </Tooltip>
                    ) : <Tooltip title="No Bybit Market" placement="top">
                            <Close color="error" />
                        </Tooltip>
                  }
                   &nbsp;
                  <MaterialLink
                    sx={{ textDecoration: "none", cursor: "pointer" }}
                    onClick={() => {
                      dispatch(
                        addSymbol({ symbol: n.coin || "", pinned: true })
                      );
                    }}
                  >
                    {n.coin}
                  </MaterialLink>
                 
                  
                  </Box>
                </TableCell>
                <TableCell>
                  {DateTime.fromMillis(n.latest_timestamp)
                    .toUTC()
                    .toFormat("yyyy-MM-dd HH:mm:ss")}
                  Z
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {usdtLocale2.format(n.latest_price / (props.krwUSDT ?? 1))}

                    {n.referencePrice
                        ? <Typography
                        variant="body2"
                        component="span"
                        sx={{ color: grey[500] }}
                      >
                        &nbsp;[
                        {((n.latest_price / (props.krwUSDT ?? 1)) / n.referencePrice - 1.0).toLocaleString(
                          undefined,
                          {
                            style: "percent",
                            minimumFractionDigits: 2,
                          }
                        )}
                        ]
                      </Typography>
                        : null
                    }

                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {usdtLocale.format(
                    (n.volume * n.latest_price) / (props.krwUSDT ?? 1)
                  )}
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ color: percent_24h > 0.02 ? null : grey[500] }}
                  >
                    &nbsp;[
                    {(n.volume / (n.volume_24h - n.volume)).toLocaleString(
                      undefined,
                      {
                        style: "percent",
                        minimumFractionDigits: 2,
                      }
                    )}
                    ]
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    {(n.movement / 100.0).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                    <Circle
                      fontSize="small"
                      sx={{ m: 0, p: 0, ml: 1 }}
                      htmlColor={getColor(n.movement / 100.0)}
                    />
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    {(n.movementMax / 100.0).toLocaleString(undefined, {
                      style: "percent",
                      minimumFractionDigits: 2,
                    })}
                    <Circle
                      fontSize="small"
                      sx={{ m: 0, p: 0, ml: 1 }}
                      htmlColor={getColor(n.movementMax / 100.0)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PumpTable;
