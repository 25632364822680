export enum Exchange {
    Binance = "Binance",
    GateIo = "GateIo",
    KuCoin = "KuCoin",
    UpBit = "UpBit",
    CoinBase = "CoinBase",
    Huobi = "Huobi",
    MEXC = "MEXC",
    Paribu = "Paribu",
    Bithumb = "Bithumb",
    FTX = "FTX",
    BKEX = "BKEX",
    HitBTC = "HitBTC",
    XT = "XT",
    BitMart = "BitMart",
    OKX = "OKX",
    ZB = "ZB",
    ByBit = "ByBit",
    Terminal = "Terminal",
    Bitget = "Bitget",
  }

  export enum ExchangeEnum {
    Binance,
    GateIo,
    KuCoin,
    UpBit,
    CoinBase,
    Huobi,
    MEXC,
    Paribu,
    Bithumb,
    FTX,
    BKEX,
    HitBTC,
    XT,
    BitMart,
    OKX,
    ZB,
    ByBit, 
    Terminal,
    Bitget,
  }