import Toolbar from "@mui/material/Toolbar";
import {
  Box,
  Grid,
} from "@mui/material";

import { useSelector } from "react-redux";
import {
  selectWatchedSymbols,
} from "./reducers/priceReducer";
import ChartsIndividual from "./ChartsIndividual";

interface ComponentProps {
  drawerWidth: number;
  side: "left" | "right";
  isSmallScreen: boolean;
}

function Charts(props: ComponentProps) {

  const symbols = useSelector(selectWatchedSymbols);

  return (
    <Box>
      <Grid container>
        {symbols.map((symbol) => {
          return (<ChartsIndividual key={symbol} symbol={symbol} />);
        })}
      </Grid>
      <Toolbar />
    </Box>
  );
}

export default Charts;
