import React from "react";
import {
    Box,
    Paper,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    sliderClasses,
} from "@mui/material";

import { blue, green, red } from "@mui/material/colors";
import { DateTime } from "luxon";
interface ComponentProps {
}

interface UpbitMarket {
    market: string,
    english_name: string,
    korean_name: string,
}

interface BinanceCoin {
    coin: string,
    baseAsset: string,
    createTime: number,
    status: string,
    openInterest: number,
    openInterestChange: number,
}

interface MarketCap {
    coin: string,
    marketCap: number,
}

const usdtLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    //minimumSignificantDigits: 0,
    //maximumSignificantDigits: 0,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

// const binanceSpotUri = "https://api.binance.com/api/v3/exchangeInfo";
const binancePerpsUri = "https://fapi.binance.com/fapi/v1/exchangeInfo";

const fetchCurrentPrice = async (symbol:string) => {
    const response = await fetch(`https://fapi.binance.com/fapi/v1/ticker/price?symbol=${symbol}`);
    const data = await response.json();
    return parseFloat(data.price);
  };

const fetchOpenInterest = async (symbol:string) => {
    const response = await fetch(`https://fapi.binance.com/fapi/v1/openInterest?symbol=${symbol}`);
    return response.json();
  };
  
  const fetchOpenInterestHist = async (symbol:string, period:string, limit:number) => {
    const response = await fetch(`https://fapi.binance.com/futures/data/openInterestHist?symbol=${symbol}&period=${period}&limit=${limit}`);
    return response.json();
  };
  
  const findClosestOI = (openInterestList:any, targetTimestamp:number) => {
    let closest = null;
    let closestTimeDiff = Number.MAX_VALUE;
  
    for (const oi of openInterestList) {
      const timeDiff = Math.abs(oi.timestamp - targetTimestamp);
      if (timeDiff < closestTimeDiff) {
        closest = oi;
        closestTimeDiff = timeDiff;
      }
    }
  
    return closest;
  };

function ResearchBinanceOpenInterest(props: ComponentProps) {
    
    // const [spotCoins, setSpotCoins] = React.useState<BinanceCoin[]>([]);
    const [perpsCoins, setPerpsCoins] = React.useState<BinanceCoin[]>([]);
    const [marketCaps, setMarketCaps] = React.useState<MarketCap[]>([]);
    const [coinMovements, setCoinMovements] = React.useState(new Map());
    const [openInterest, setOpenInterest] = React.useState(new Map());

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch(binancePerpsUri, {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let coins: BinanceCoin[] = [];
            data.symbols.forEach((s: any) => {
                if (s.contractType === "PERPETUAL" && s.status === "TRADING" && coins.findIndex(c => c.coin === s.baseAsset) === -1 && s.quoteAsset === "USDT" && s.underlyingType === "COIN") {
                    coins.push({
                        coin: s.baseAsset.replace("1000", ""),
                        baseAsset: s.baseAsset,
                        createTime: s.onboardDate,
                        status: s.status,
                        openInterest: 0.0,
                        openInterestChange: 0.0,
                    });
                }
            });
            setPerpsCoins(coins.sort((a, b) => a.createTime > b.createTime ? -1 : 1));
        });
    }, [, ]);

    React.useEffect(() => {
        if (perpsCoins.length === 0) return; // Do nothing if perpsCoins is not set
    
        let isMounted = true;
    
        const updateCoin = async (index:number) => {
          const coin = perpsCoins[index];
    
          const currentPrice = await fetchCurrentPrice(coin.baseAsset + "USDT");
          const currentOI = await fetchOpenInterest(coin.baseAsset + "USDT");
          const historicalOI = await fetchOpenInterestHist(coin.baseAsset + "USDT", '5m', 288);
    
          if (historicalOI.length > 0) {
            const timestamp24hAgo = Date.now() - 24 * 60 * 60 * 1000;
            const closestOI = findClosestOI(historicalOI, timestamp24hAgo);
    
            if (closestOI) {
              const oiChange = ((currentOI.openInterest - closestOI.sumOpenInterest) / closestOI.sumOpenInterest) * 100;
              setOpenInterest(prevOpenInterest => {
                const newOpenInterest = new Map(prevOpenInterest);
                newOpenInterest.set(coin.baseAsset, currentOI.openInterest * currentPrice);
                return newOpenInterest;
              });
              setCoinMovements(prevMovements => {
                const newMovements = new Map(prevMovements);
                newMovements.set(coin.baseAsset, oiChange);
                return newMovements;
              });
              
            }
          }
    
          // Schedule the next update for this coin
          if (isMounted) {
            setTimeout(() => updateCoin((index + 1) % perpsCoins.length), 1000);
          }
        };
    
        // Start the updates
        updateCoin(0);
    
        return () => {
          isMounted = false;
        };
      }, [perpsCoins]);

    /*
    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch(binanceSpotUri, {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let coins: BinanceCoin[] = [];
            data.symbols.forEach((s: any) => {
                if (coins.findIndex(c => c.coin === s.baseAsset) === -1) {
                    coins.push({
                        coin: s.baseAsset,
                        createTime: s.createTime,
                    });
                }
            });
            setSpotCoins(coins);
        });

        
        
    }, [, ]);
    */

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch("https://news.kryptrader.com/mcap", {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let working: MarketCap[] = [];
            data.forEach((s: any) => {
                let existingIndex = working.findIndex(c => c.coin === s.symbol.toUpperCase());
                if (existingIndex === -1) {
                    working.push({
                        coin: s.symbol.toUpperCase(),
                        marketCap: s.market_cap,
                    });
                }
                else {
                    working[existingIndex].marketCap = Math.max(working[existingIndex].marketCap, s.market_cap);
                }
            });
            setMarketCaps(working);
        });

        
        
    }, [, ]);

    return (
        <Box>
            {
                perpsCoins.length > 0 /* && spotCoins.length > 0 */
                ? 
                <>
                <Typography sx={{fontWeight: 500, pl: 2, mb: 4, mt: 2}} variant="body2">10% or more 24h increase</Typography>
                <Paper elevation={3} sx={{m: 2, mt: 0, p: 2, pt: 0}}>

                    <Grid container>
                    {
                        perpsCoins.filter(c => c.status === "TRADING").sort((a, b) => a.coin < b.coin ? -1 : 1).map(l => {
                            let movement = (coinMovements.get(l.baseAsset) || 0.0) / 100.0;

                            if (movement < 0.1) { 
                                return null;
                            }

                            let marketCap = marketCaps.find(m => m.coin === l.coin)?.marketCap || 0;

                            console.log(`key check for ${l.baseAsset}`, perpsCoins.filter(c => c.baseAsset === l.baseAsset).length);

                            return (
                                <Grid item xs={4} md={2} key={l.baseAsset}>
                                    <Paper elevation={3} sx={{m: 1, p: 1}}>
                                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant="body2">{l.coin}</Typography>    
                                            <Typography variant="body2" sx={{color: (coinMovements.get(l.baseAsset) || 0.0) < -10.0 ? red[500] : (coinMovements.get(l.baseAsset) || 0.0) > 10.0 ? green[500] : null}}>{((coinMovements.get(l.baseAsset) || 0.0) / 100.0).toLocaleString(undefined, {
                                                style: "percent",
                                                minimumFractionDigits: 2,
                                            })}</Typography>
                                        </Box>
                                        <Typography variant="body2" sx={{textAlign: 'center'}}>{marketCap ? (openInterest.get(l.baseAsset) / marketCap).toFixed(2) : "-"}x</Typography>    
                                    </Paper>
                                </Grid>
                            )
                    })
                    }
                    </Grid>
                    </Paper>
                    <Typography sx={{fontWeight: 500, pl: 2, mb: 4, mt: 2}} variant="body2">10% or more 24h decrease</Typography>
                <Paper elevation={3} sx={{m: 2, mt: 0, p: 2, pt: 0}}>

                    <Grid container>
                    {
                        perpsCoins.filter(c => c.status === "TRADING").sort((a, b) => a.coin < b.coin ? -1 : 1).map(l => {
                            let movement = (coinMovements.get(l.baseAsset) || 0.0) / 100.0;

                            if (movement > -0.10) { 
                                return null;
                            }

                            let marketCap = marketCaps.find(m => m.coin === l.coin)?.marketCap || 0

                            return (
                                <Grid item xs={4} md={2} key={l.baseAsset}>
                                    <Paper elevation={3} sx={{m: 1, p: 1}}>
                                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography variant="body2">{l.coin}</Typography>    
                                            <Typography variant="body2" sx={{color: (coinMovements.get(l.baseAsset) || 0.0) < -10.0 ? red[500] : (coinMovements.get(l.baseAsset) || 0.0) > 10.0 ? green[500] : null}}>{((coinMovements.get(l.baseAsset) || 0.0) / 100.0).toLocaleString(undefined, {
                                                style: "percent",
                                                minimumFractionDigits: 2,
                                            })}</Typography>
                                        </Box>
                                        <Typography variant="body2" sx={{textAlign: 'center'}}>{marketCap ? (openInterest.get(l.baseAsset) / marketCap).toFixed(2) : "-"}x</Typography>    
                                    </Paper>
                                </Grid>
                            )
                    })
                    }
                    </Grid>
                    </Paper>
                <Typography sx={{fontWeight: 500, pl: 2, mb: 4, mt: 2}} variant="body2">No material change</Typography>
                <Paper elevation={3} sx={{m: 2, mt: 0, p: 2, pt: 0}}>

                    <Grid container>
                    {
                        perpsCoins.filter(c => c.status === "TRADING").sort((a, b) => a.coin < b.coin ? -1 : 1).map(l => {
                            let movement = (coinMovements.get(l.baseAsset) || 0.0) / 100.0;

                            if (movement < -0.10 || movement > 0.10) { 
                                return null;
                            }

                            return (
                                <Grid item xs={4} md={2} key={l.baseAsset}>
                                    <Paper elevation={3} sx={{display: 'flex', justifyContent: 'space-between', m: 1, p: 1}}>
                                        <Typography variant="body2">{l.coin}</Typography>    
                                        <Typography variant="body2" sx={{color: (coinMovements.get(l.baseAsset) || 0.0) < -10.0 ? red[500] : (coinMovements.get(l.baseAsset) || 0.0) > 10.0 ? green[500] : null}}>{((coinMovements.get(l.baseAsset) || 0.0) / 100.0).toLocaleString(undefined, {
                                            style: "percent",
                                            minimumFractionDigits: 2,
                                        })}</Typography>
                                    </Paper>
                                </Grid>
                            )
                    })
                    }
                    </Grid>
                    </Paper>
                    </>
                : <Typography>Loading coins ...</Typography>
            }

            <Typography sx={{m: 2, borderBottom: "1px solid gray"}}>Notes</Typography>
            <Typography sx={{ml: 2}} variant="body2">
                Binance perps coins from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{binancePerpsUri}</Typography>.<br /><br />
                Binance perps OI from <Typography component="span" variant="body2" sx={{color: blue[500]}}>https://fapi.binance.com/fapi/v1/openInterest</Typography>.<br /><br />
                Binance perps current prices from <Typography component="span" variant="body2" sx={{color: blue[500]}}>https://fapi.binance.com/fapi/v1/ticker/price</Typography>.<br /><br />
                Underlying type for perps must be "COIN" (not "INDEX") and contract names starting with "1000" were corrected.
            </Typography>
        </Box>
    );
}

export default ResearchBinanceOpenInterest;
