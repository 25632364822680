import React from "react";
import "./App.css";
import Header from "./Header";
import Terminal from "./Terminal";
import News from "./News";
import NewsMasonry from "./NewsMasonry";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken, selectIsLoggedIn } from "./reducers/authorizationSlice";
import { connectedCount, selectNewsRatio, selectTerminalTab, updateTerminalTab } from "./reducers/terminalReducer";
import { selectTradeIsFullscreen, selectTradeIsPinnned as selectTradeIsPinned } from './reducers/symbolReducer';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Settings from "./Settings";
import { Box, Drawer, Grid, Hidden, IconButton, Tab, Tabs, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import Ticker from "./Ticker";
import NewsAlerts from "./NewsAlerts";
import Charts from "./Charts";
import { selectColumns } from "./reducers/newsReducer";
import Trades from "./Trades";
import NewsSettings from './SettingsNews';

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BotDisplay from "./BotDisplay";
import NewsHistory from "./NewsHistory";
import KPumps from "./KPumps";
import Research from "./Research";
import Plans from "./Plans";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}


function App() {

    const dispatch = useDispatch();

    const isLoggedIn = useSelector(selectIsLoggedIn);
    const tradeIsFullscreen = useSelector(selectTradeIsFullscreen);
    const tradeIsPinned = useSelector(selectTradeIsPinned);
    const botCount = useSelector(connectedCount);
    const terminalTabIndex = useSelector(selectTerminalTab);
    
    const newsRatio = useSelector(selectNewsRatio);
    const newsColumns = useSelector(selectColumns);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const drawerWidth: number = tradeIsFullscreen ? 0 : 298 * (1 + newsColumns);
    const side: "left" | "right" = "left";

    // Tab navigation
    const [tabIndex, setTabIndex] = React.useState<number>(0);
    const [newsTabIndex, setNewsTabIndex] = React.useState<number>(0);

    const [showBot, setShowBot] = React.useState<boolean>(false);

    // Make sure we move to the trade screen if a new symbol is pinned
    React.useEffect(() => {
        if (tradeIsPinned === true && tabIndex === 0) {
            setTabIndex(1);
        }
    }, [tradeIsPinned])

    return (
        <BrowserRouter>
            <Header />

            <NewsAlerts />

            <Grid container>

                <Grid item xs={12}>
                    <Toolbar />
                </Grid>
                
                <Grid item xs={12}>
                    <Hidden smDown>
                        <Ticker drawerWidth={isSmallScreen ? 0 : drawerWidth} side={side} />
                    </Hidden>
                </Grid>

                <Hidden mdUp>
                    <Grid item xs={12}>  
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabIndex} onChange={(e, v) => { setTabIndex(v); } } variant="fullWidth">
                                <Tab label="News feed" />
                                <Tab label="Trading" />
                            </Tabs>
                        </Box>
                    </Grid>
                </Hidden>

                {
                    (isMediumScreen === false || tabIndex === 0) && newsRatio !== 0
                        ?
                        <Grid item xs={12} md={newsRatio * 1.5} lg={newsRatio} sx={{height: isSmallScreen ? null : 'calc(100vh - 225px)', overflowY: 'scroll', borderTop: isMediumScreen ? undefined : '1px solid #a9a9a9', borderRight: isMediumScreen ? undefined : '1px solid #a9a9a9'}}>
                            
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={newsTabIndex} onChange={(e, v) => { setNewsTabIndex(v); } }>
                                        <Tab label="Feed" />
                                        <Tab label="History" />
                                        <Tab label="Settings" />
                                        <Tab label="KPumps" />
                                    </Tabs>
                                </Box>
                                {
                                    {
                                        0: <NewsMasonry drawerWidth={drawerWidth} side={side} isSmallScreen={isSmallScreen} />,
                                        1: <NewsHistory />,
                                        2: <NewsSettings />,
                                        3: <KPumps />
                                    }[newsTabIndex]
                                }
                            
                        </Grid>
                    : null
                }
                
                {
                    (isMediumScreen === false || tabIndex === 1) && newsRatio !== 12
                        ?
                        <Grid item xs={12} md={12 - newsRatio * 1.5} lg={12 - newsRatio} sx={{height: isSmallScreen ? null : 'calc(100vh - 225px)', overflowY: 'scroll', borderTop: isMediumScreen ? undefined : '1px solid #a9a9a9', borderLeft: isMediumScreen ? undefined : '1px solid #a9a9a9'}}>
                            
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={botCount === 0 ? 3 : terminalTabIndex} onChange={(e, v) => { dispatch(updateTerminalTab(v)); } }>
                                        <Tab label="Execute" disabled={botCount === 0} />
                                        <Tab label="Trades" disabled={botCount === 0} />
                                        <Tab label="Plans" disabled={true} />
                                        <Tab label="Charts" />
                                        <Tab label="Settings" disabled={botCount === 0} />
                                        <Tab label="Research" />
                                    </Tabs>
                                </Box>

                                <Box>
                                {
                                    isLoggedIn
                                        ?
                                            {
                                                0: <Terminal drawerWidth={isSmallScreen ? 0 : 0} side={side} isSmallScreen={isSmallScreen } />,
                                                1: <Trades />,
                                                2: <Plans />,
                                                3: <Charts drawerWidth={0} side={side} isSmallScreen={isSmallScreen} />,
                                                4: <Settings />,
                                                5: <Research />
                                            }[botCount === 0 ? 3 : terminalTabIndex]
                                        : null
                                }
                                </Box>
                            
                        </Grid>
                        : null
                }
            </Grid>
        <Drawer
            sx={{
            overflowY: "scroll",
            height: showBot && !tradeIsFullscreen ? "50vh" : isSmallScreen ? 56 : 64,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
                height: showBot && !tradeIsFullscreen ? "50vh" : isSmallScreen ? 56 : 64,
                borderTop: '1px solid #a9a9a9',
                mr: {
                //xs: 2,
                //md: `${props.side === "left" ? 0 : props.drawerWidth}px`,
                },
                ml: {
                //xs: 2,
                //md: `${props.side === "left" ? props.drawerWidth : 0}px`,
                },
            },
            }}
            variant="persistent"
            anchor="bottom"
            open={true}
            transitionDuration={1000}
        >
            <Box
            sx={{
                mr: {
                xs: 0,
                //md: `${(props.side === "left" ? 0 : props.drawerWidth) + 0}px`,
                },
                ml: {
                xs: 0,
                //md: `${(props.side === "left" ? props.drawerWidth : 0) + 0}px`,
                },
            }}
            >
            <Toolbar sx={{ pl: { xs: 2 } }}>
                <IconButton
                onClick={() => {
                    setShowBot(!showBot);
                }}
                >
                {!showBot ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>

                <Typography variant="body1">Trading bot status</Typography>
            </Toolbar>

            <BotDisplay isSmallScreen={isSmallScreen} />
            </Box>
        </Drawer>
            
        </BrowserRouter>
    );
}

export default App;

