import React from "react";
import { Typography } from "@mui/material";

interface ComponentProps {
  symbol: string;
}

const usdtLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    maximumSignificantDigits: 3,
  });

function SymbolMarketCap(props: ComponentProps) {

  const [mcap, setMcap] = React.useState<number>(0);

    React.useEffect(() => {
        fetch(`https://news.kryptrader.com/mcap/${props.symbol}`, {
            method: "GET",
        })
            .then((response) => response.text())
            .then((data) => {
                //let matches = data.filter((d:any) => d.symbol.toUpperCase() === props.symbol);
                try {
                    if (data) {
                        //matches = matches.sort((a:any, b:any) => a.market_cap < b.market_cap ? 1 : -1);
                        setMcap(parseInt(data));
                    }
                }
                catch {}
        });
    }, [])

  return (
    <Typography color="text.secondary" variant="body2" component="span" sx={{ ml: 0 }}>
        {
        usdtLocale.format(mcap)
        }
    </Typography>
  );
}

export default SymbolMarketCap;
