import React from "react";
import Toolbar from "@mui/material/Toolbar";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Hidden,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fade,
  Grid,
  Link,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  Drawer,
  useTheme,
} from "@mui/material";
import { grey, green, red } from "@mui/material/colors";
import SplitButton from "./components/SplitButton";
import ConfigOption from "./types/ConfigOption";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import SelectInput from "@mui/material/Select/SelectInput";
import { useHotkeys } from "react-hotkeys-hook";
import { useSearchParams } from "react-router-dom";
import BotDisplay from "./BotDisplay";
import TradeSettings from "./types/TradeSettings";
import { UIOrder } from "./types/BotOrder";
import SymbolContainer from "./components/symbol/SymbolContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useSelector, useDispatch } from "react-redux";
import { list, removeSymbol, SymbolConfig } from "./reducers/symbolReducer";
import { connectedCount } from "./reducers/terminalReducer";
import { selectWatchedSymbols } from "./reducers/priceReducer";

import PriceTicker from './components/PriceTicker';

interface ComponentProps {
  drawerWidth: number;
  side: "left" | "right";
}

function Ticker(props: ComponentProps) {
  let theme = useTheme();

  const symbols = useSelector(selectWatchedSymbols);

  const terminalConnectedCount = useSelector(connectedCount);

  const [showBot, setShowBot] = React.useState<boolean>(true);

  const [showCustom, setShowCustom] = React.useState<boolean>(false);

  const symbolList = useSelector(list);
  const dispatch = useDispatch();

  return (
    <Box sx={{display:'flex', p: 1, pb: '11px', overflowX: 'scroll' }}>
      
        {
            symbols.map(p => {
                return (<PriceTicker symbol={p} />)
            })
        }

    </Box>
  );
}

export default Ticker;
