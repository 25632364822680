import React from "react";
import {
    Box,
    Paper,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";

import { blue } from "@mui/material/colors";
import { DateTime } from "luxon";
interface ComponentProps {
}

interface UpbitMarket {
    market: string,
    english_name: string,
    korean_name: string,
}

interface BinanceCoin {
    coin: string,
    createTime: number,
}

interface MarketCap {
    coin: string,
    marketCap: number,
}

const usdtLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    //minimumSignificantDigits: 0,
    //maximumSignificantDigits: 0,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const binanceSpotUri = "https://api.binance.com/api/v3/exchangeInfo";
const binancePerpsUri = "https://fapi.binance.com/fapi/v1/exchangeInfo";

function ResearchBinancePerpsNoSpot(props: ComponentProps) {
    
    const [spotCoins, setSpotCoins] = React.useState<BinanceCoin[]>([]);
    const [perpsCoins, setPerpsCoins] = React.useState<BinanceCoin[]>([]);
    const [marketCaps, setMarketCaps] = React.useState<MarketCap[]>([]);


    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch(binancePerpsUri, {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let coins: BinanceCoin[] = [];
            data.symbols.forEach((s: any) => {
                if (s.contractType === "PERPETUAL" && coins.findIndex(c => c.coin === s.baseAsset) === -1 && s.underlyingType === "COIN") {
                    coins.push({
                        coin: s.baseAsset.replace("1000", ""),
                        createTime: s.onboardDate,
                    });
                }
            });
            setPerpsCoins(coins.sort((a, b) => a.createTime > b.createTime ? -1 : 1));
        });

        
        
    }, [, ]);

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch(binanceSpotUri, {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let coins: BinanceCoin[] = [];
            data.symbols.forEach((s: any) => {
                if (coins.findIndex(c => c.coin === s.baseAsset) === -1) {
                    coins.push({
                        coin: s.baseAsset,
                        createTime: s.createTime,
                    });
                }
            });
            setSpotCoins(coins);
        });

        
        
    }, [, ]);

    React.useEffect(() => {

        // Get all binance perpetual futures coins
        fetch("https://news.kryptrader.com/mcap", {
            method: "GET",
          })
        .then(response => response.json())
        .then(data => {
            let working: MarketCap[] = [];
            data.forEach((s: any) => {
                let existingIndex = working.findIndex(c => c.coin === s.symbol.toUpperCase());
                if (existingIndex === -1) {
                    working.push({
                        coin: s.symbol.toUpperCase(),
                        marketCap: s.market_cap,
                    });
                }
                else {
                    working[existingIndex].marketCap = Math.max(working[existingIndex].marketCap, s.market_cap);
                }
            });
            setMarketCaps(working);
        });

        
        
    }, [, ]);

    return (
        <Box>
            {
                perpsCoins.length > 0 && spotCoins.length > 0
                ? 
                <>
                <Typography sx={{fontWeight: 500, pl: 2, mb: 4, mt: 2}} variant="body2">Binance coins (Perps with no spot)</Typography>
                <Paper elevation={3} sx={{m: 2, mt: 0, p: 2, pt: 0}}>
                    
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Coin</TableCell>
                                        <TableCell>Added to perps</TableCell>
                                        <TableCell sx={{textAlign: "right"}}>Market cap</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        perpsCoins.map(l => {

                                            let hasSpot = spotCoins.find(m => m.coin === l.coin);

                                            if (hasSpot) {
                                                return null;
                                            }
                                            
                                            return (
                                                <TableRow key={l.coin}>
                                                    <TableCell>{l.coin}</TableCell>
                                                    <TableCell>{DateTime.fromMillis(l.createTime).toUTC().toFormat("yyyy-MM-dd hh:mm:ss")}Z</TableCell>
                                                    <TableCell sx={{textAlign: "right"}}>{usdtLocale.format(marketCaps.find(m => m.coin === l.coin)?.marketCap || 0)}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    </>
                : <Typography>Loading coins ...</Typography>
            }

            <Typography sx={{m: 2, borderBottom: "1px solid gray"}}>Notes</Typography>
            <Typography sx={{ml: 2}} variant="body2">
                Binance perps coins from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{binancePerpsUri}</Typography>.<br /><br />
                Binance spot coins from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{binanceSpotUri}</Typography>.<br /><br />
                Underlying type for perps must be "COIN" (not "INDEX") and contract names starting with "1000" were corrected.
            </Typography>
        </Box>
    );
}

export default ResearchBinancePerpsNoSpot;
