import React from "react";
import {
    Box,
    Paper,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";

import { Market } from "./types/Market";
import { green, amber, blue, pink, purple } from "@mui/material/colors";
import { DateTime } from "luxon";
interface ComponentProps {
}

interface UpbitMarket {
    market: string,
    english_name: string,
    korean_name: string,
}

interface BinanceCoin {
    coin: string,
    createTime: number,
    type: string,
}

interface MarketCap {
    coin: string,
    marketCap: number,
}

const usdtLocale = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    //minimumSignificantDigits: 0,
    //maximumSignificantDigits: 0,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const binanceUri = 'https://launchpad.binance.com/bapi/asset/v2/public/asset/asset/get-all-asset';
const upbitUri = 'https://api.upbit.com/v1/market/all';
const bithumbKRWUri = 'https://api.bithumb.com/public/ticker/ALL_KRW'
const bithumbBTCUri = 'https://api.bithumb.com/public/ticker/ALL_BTC'

function ResearchBinanceCoinsKoExchanges(props: ComponentProps) {
    
    const [launchpools, setLaunchpools] = React.useState<BinanceCoin[]>([]);
    const [launchpads, setLaunchpads] = React.useState<BinanceCoin[]>([]);

    const [upbitKRWMarkets, setUpbitKRWMarkets] = React.useState<Market[]>([]);
    const [upbitBTCMarkets, setUpbitBTCMarkets] = React.useState<Market[]>([]);

    const [bithumbKRWMarkets, setBithumbKRWMarkets] = React.useState<Market[]>([]);
    const [bithumbBTCMarkets, setBithumbBTCMarkets] = React.useState<Market[]>([]);

    const [marketCaps, setMarketCaps] = React.useState<MarketCap[]>([]);

    React.useEffect(() => {
            
        fetch(binanceUri, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            console.log('data.data', data.data);

            let launchpoolCoins:any[] = data.data.filter((m: any) => { return (m.tags || []).includes("Launchpool") });
            let launchpadCoins:any[] = data.data.filter((m: any) => { return (m.tags || []).includes("Launchpad") });
            //console.log('launchpadCoins', launchpadCoins);
            setLaunchpools(launchpoolCoins.map(c => ({ coin: c.assetCode, createTime: c.createTime, type: "Launchpool"})).sort((a, b) => a.createTime > b.createTime ? -1 : 1));
            setLaunchpads(launchpadCoins.map(c => ({ coin: c.assetCode, createTime: c.createTime, type: "Launchpad"})).sort((a, b) => a.createTime > b.createTime ? -1 : 1));

          });
    }, [, ]);

    React.useEffect(() => {
    
        fetch(upbitUri, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            //console.log('data', data);

            let krwMarkets:Market[] = data.filter((m: UpbitMarket) => { return m.market.startsWith('KRW-') })
                .map((m: UpbitMarket) => { return { symbol: m.market, name: m.english_name, coin: m.market.replace("KRW-", "") } as Market; });
            setUpbitKRWMarkets(krwMarkets);

            let btcMarkets:Market[] = data.filter((m: UpbitMarket) => { return m.market.startsWith('BTC-') })
                .map((m: UpbitMarket) => { return { symbol: m.market, name: m.english_name, coin: m.market.replace("BTC-", "") } as Market; });
            setUpbitBTCMarkets(btcMarkets);
          });
    }, [, ])

    React.useEffect(() => {

        // Get all bithumb markets
        fetch(bithumbKRWUri, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            //console.log('data', data);

            let bithumbMarkets:Market[] = Object.keys(data.data).map((m: string) => { return { symbol: m, name: m, coin: m } as Market; });
            setBithumbKRWMarkets(prevMarkets => [...prevMarkets, ...bithumbMarkets]);
          });

        }, [, ]);

        React.useEffect(() => {

            // Get all bithumb markets
            fetch(bithumbBTCUri, {
              method: "GET",
            })
              .then((response) => response.json())
              .then((data) => {
                //console.log('data', data);
    
                let bithumbMarkets:Market[] = Object.keys(data.data).map((m: string) => { return { symbol: m, name: m, coin: m } as Market; });
                setBithumbBTCMarkets(prevMarkets => [...prevMarkets, ...bithumbMarkets]);
              });
    
            }, [, ]);

            React.useEffect(() => {

                // Get all binance perpetual futures coins
                fetch("https://news.kryptrader.com/mcap", {
                    method: "GET",
                  })
                .then(response => response.json())
                .then(data => {
                    let working: MarketCap[] = [];
                    data.forEach((s: any) => {
                        let existingIndex = working.findIndex(c => c.coin === s.symbol.toUpperCase());
                        if (existingIndex === -1) {
                            working.push({
                                coin: s.symbol.toUpperCase(),
                                marketCap: s.market_cap,
                            });
                        }
                        else {
                            working[existingIndex].marketCap = Math.max(working[existingIndex].marketCap, s.market_cap);
                        }
                    });
                    setMarketCaps(working);
                });
        
                
                
            }, [, ]);

    return (
        <Box>
            {
                launchpads.length > 0 && launchpools.length > 0
                ? 
                <>
                <Typography sx={{fontWeight: 500, pl: 2, mb: 4, mt: 2}} variant="body2">Binance coins</Typography>
                <Paper elevation={3} sx={{m: 2, mt: 0, p: 2, pt: 0}}>
                    
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Coin</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Added</TableCell>
                                        <TableCell>Upbit KRW</TableCell>
                                        <TableCell>Upbit BTC</TableCell>
                                        <TableCell>Bithumb KRW</TableCell>
                                        <TableCell>Bithumb BTC</TableCell>
                                        <TableCell>Market cap</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        [...launchpads, ...launchpools].sort((a, b) => a.createTime < b.createTime ? 1 : -1).map(l => {

                                            let hasUpbitKRW = upbitKRWMarkets.find((m: Market) => m.coin === l.coin);
                                            let hasUpbitBTC = upbitBTCMarkets.find((m: Market) => m.coin === l.coin);

                                            let hasBithumbKRW = bithumbKRWMarkets.find((m: Market) => m.coin === l.coin);
                                            let hasBithumbBTC = bithumbBTCMarkets.find((m: Market) => m.coin === l.coin);

                                            return (
                                                <TableRow key={l.coin}>
                                                    <TableCell>{l.coin}</TableCell>
                                                    <TableCell sx={{color: l.type === "Launchpad" ? pink[300] : purple[300]}}>{l.type}</TableCell>
                                                    <TableCell>{DateTime.fromMillis(l.createTime).toUTC().toFormat("yyyy-MM-dd hh:mm:ss")}Z</TableCell>
                                                    <TableCell sx={{color: hasUpbitKRW ? green[500] : amber[500]}}>
                                                        {
                                                            hasUpbitKRW
                                                            ? "Yes"
                                                            : "No"
                                                        }
                                                    </TableCell>
                                                    <TableCell sx={{color: hasUpbitBTC ? green[500] : amber[500]}}>
                                                        {
                                                            hasUpbitBTC
                                                            ? "Yes"
                                                            : "No"
                                                        }
                                                    </TableCell>
                                                    <TableCell sx={{color: hasBithumbKRW ? green[500] : amber[500]}}>
                                                        {
                                                            hasBithumbKRW
                                                            ? "Yes"
                                                            : "No"
                                                        }
                                                    </TableCell>
                                                    <TableCell sx={{color: hasBithumbBTC ? green[500] : amber[500]}}>
                                                        {
                                                            hasBithumbBTC
                                                            ? "Yes"
                                                            : "No"
                                                        }
                                                    </TableCell>
                                                    <TableCell sx={{textAlign: "right"}}>{usdtLocale.format(marketCaps.find(m => m.coin === l.coin)?.marketCap || 0)}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    </>
                : <Typography>Loading launchpads / launchpools ...</Typography>
            }

            <Typography sx={{m: 2, borderBottom: "1px solid gray"}}>Notes</Typography>
            <Typography sx={{ml: 2}} variant="body2">
                Binance API data from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{binanceUri}</Typography> filtered by tags "Launchpad" and "Launchpool".<br /><br />
                Upbit API data from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{upbitUri}</Typography> filtered by markets starting with "KRW-" or "BTC-".<br /><br />
                Bithumb API data from <Typography component="span" variant="body2" sx={{color: blue[500]}}>{bithumbKRWUri}</Typography> or <Typography component="span" variant="body2" sx={{color: blue[500]}}>{bithumbBTCUri}</Typography>.
            </Typography>
        </Box>
    );
}

export default ResearchBinanceCoinsKoExchanges;
