const authEndpoint = 'https://discord.com/api/oauth2/authorize';

const scopes = [
  'identify',
];

export const getAuthorizeHref = (): string => {
  const clientId = "1017298276581642301";
  const redirectUri = process.env.NODE_ENV === 'development' ? "http%3A%2F%2Flocalhost%3A3001%2F" : "https%3A%2F%2Fterminal.kryptrader.com%2F"; // ;"http%3A%2F%2Flocalhost%3A3001%2F"; //
  return `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=token`;
}