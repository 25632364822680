import React from "react";
import { Box, Grid, Hidden, Typography } from "@mui/material";
import { green, red, blue, grey } from "@mui/material/colors";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { shallowEqual, useSelector } from "react-redux";
import { Price, selectAvailableQuotes, selectExchanges, selectPrices } from "../../reducers/priceReducer";
import { GetPrimaryQuote } from "../../utils/PriceUtils";
import { RootState } from "../../store";

interface ComponentProps {
  symbol: string;
}

// USDT formatter
// Todo - different precision models for diff currencies
const usdtLocale = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumSignificantDigits: 7,
  minimumFractionDigits: 2,
  maximumFractionDigits: 7,
});

interface MovementComponentProps {
  movement: number;
  label: string;
  chartDurationChange: Function,
  marginLeft?: number;
}

export const MovementInfo = (props: MovementComponentProps) => {
  return (
    <Box sx={{display:'inline-block', textAlign:'center', width: '100%', ml: props.marginLeft ? props.marginLeft : 0 }}>
    <Typography
      variant="body1"
      onClick={() => { props.chartDurationChange() }}
      sx={{
        cursor: 'pointer',
        mb: 0,
        pb: 0,
        display: "inline-flex",
        alignItems: "center",
        textAlign: "right",
        color:
          props.movement < 0
            ? red[700]
            : props.movement > 0
            ? green[600]
            : undefined,
      }}
    >
      {props.movement > 0 ? <NorthIcon fontSize="small" sx={{m: 0, p: 0}} /> : <SouthIcon fontSize="small" sx={{m: 0, p: 0}} />}
      {props.movement.toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: 2,
      })}

      
    </Typography>
    <Typography
    variant="body2"
    component="div"
    color="text.secondary"
    sx={{ mb: 0, mt: 0, pt: 0, textAlign: 'center', whiteSpace: "nowrap", fontSize: "0.8em" }}
  >
    {props.label}
    </Typography>
    </Box>
  );
};

function SymbolPrice(props: ComponentProps) {

    const prices:Price[] = useSelector(
        (state) => selectPrices(state as RootState, props.symbol),
        shallowEqual
      );

    //const currentPrices = useSelector(prices);
    //const historicalPrices = useSelector(history);

  const [chartDuration, setChartDuration] = React.useState<number>(60);

  const availableQuotes = useSelector(selectAvailableQuotes);
  const exchanges = useSelector(selectExchanges);

  let arr = GetPrimaryQuote(props.symbol, exchanges, availableQuotes);
  let exchange = arr[0];
  let quote = arr[1];

 // let symbolPrices = currentPrices[props.symbol];
  let binancePrice = prices?.find(s => s.exchange === exchange && s.quote === quote);

  let lastMinPrice = binancePrice?.history?.find(
    (p) => p.timestamp < new Date().valueOf() / 1000 - 60 && p.timestamp > new Date().valueOf() / 1000 - 82
  );

  let lastMinMovement = 0;
  if (lastMinPrice && binancePrice) {
    lastMinMovement = binancePrice.price / lastMinPrice.price - 1.0;
  }

  let last5MinPrice = binancePrice?.history?.find(
    (p) => p.timestamp < new Date().valueOf() / 1000 - 2 * 60
  );

  let last5MinMovement = 0;
  if (last5MinPrice && binancePrice) {
    last5MinMovement = binancePrice.price / last5MinPrice.price - 1.0;
  }

  return (
    <>
      <Grid container>{/* 
        <Grid item xs={6}>
          
          <Typography variant="body2" color={blue[600]}>
            A: {usdtLocale.format(binancePrice?.ask || 0)}
            &nbsp;(
            {(
              (binancePrice?.ask || 0) / (binancePrice?.price || 1) -
              1
            ).toLocaleString(undefined, {
              style: "percent",
              minimumFractionDigits: 2,
            })}
            )
          </Typography>
          <Typography variant="body2" color={grey[600]}>
            B: {usdtLocale.format(binancePrice?.bid || 0)}
            &nbsp;(
            {(
              (binancePrice?.bid || 0) / (binancePrice?.price || 1) -
              1
            ).toLocaleString(undefined, {
              style: "percent",
              minimumFractionDigits: 2,
            })}
            )
          </Typography>
        </Grid>
        */}
        <Grid item xs={6}>
        <Box sx={{display: 'flex', /* justifyContent: 'space-between' */}}>
          <MovementInfo movement={lastMinMovement} label="1 min" chartDurationChange={() => { setChartDuration(60) }} />
          <MovementInfo movement={last5MinMovement} label="2 min" chartDurationChange={() => { setChartDuration(120) }} />
          </Box>
         </Grid>
      </Grid>
    </>
  );
}

export default React.memo(SymbolPrice);
