import React from "react";
import {
  IconButton,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  Paper,
} from "@mui/material";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { connectedCount, isConnected } from "./reducers/terminalReducer";
import {
  selectInitPercentOptions,
  selectLeverageOptions,
  selectStopLossPercentOptions,
  selectTakeProfitPercentOptions,
  selectTimerOptions,
  setInitPercentOptions,
  setLeverageOptions,
  setStopLossPercentOptions,
  setTakeProfitPercentOptions,
  setTimerOptions,
} from "./reducers/symbolReducer";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { AnyAction } from "@reduxjs/toolkit";
import ConfigOption from "./types/ConfigOption";

function SettingsOptions() {
  const dispatch = useDispatch();

  const initPercentOptions = useSelector(selectInitPercentOptions);
  const leverageOptions = useSelector(selectLeverageOptions);
  const stopLossPercentOptions = useSelector(selectStopLossPercentOptions);
  const takeProfitPercentOptions = useSelector(selectTakeProfitPercentOptions);
  const timerOptions = useSelector(selectTimerOptions);

  const changeLabel = (
    index: number,
    value: string,
    options: ConfigOption[],
    setter: (options: any) => AnyAction
  ) => {
    if (value) {
      let newOptions = JSON.parse(JSON.stringify(options));
      newOptions[index].label = value;

      dispatch(setter(newOptions));
    }
  };

  const changeValue = (
    index: number,
    value: string,
    options: ConfigOption[],
    setter: (options: any) => AnyAction
  ) => {
    if (value) {
      let newOptions = JSON.parse(JSON.stringify(options));
      newOptions[index].value = parseFloat(value);

      dispatch(setter(newOptions));
    }
    else {
        let newOptions = JSON.parse(JSON.stringify(options));
        newOptions[index].value = null;

      dispatch(setter(newOptions));
    }
  };

  const makeDefault = (
    index: number,
    options: ConfigOption[],
    setter: (options: any) => AnyAction
  ) => {
    let newOptions = JSON.parse(JSON.stringify(options));
    newOptions = newOptions.map((o: any, i: number) => ({
      ...o,
      default: i === index,
    }));

    dispatch(setter(newOptions));
  };

  const settings = [
    {
      name: "Buy risk %",
      options: initPercentOptions,
      setter: setInitPercentOptions,
    },
    { name: "Leverage", options: leverageOptions, setter: setLeverageOptions },
    {
      name: "Stop loss %",
      options: stopLossPercentOptions,
      setter: setStopLossPercentOptions,
    },
    {
      name: "Take profit %",
      options: takeProfitPercentOptions,
      setter: setTakeProfitPercentOptions,
    },
    { name: "Timer", options: timerOptions, setter: setTimerOptions },
  ];

  return (
    <Grid container maxWidth="md">
      {settings.map((s) => {
        return (
          <Grid item xs={12} key={s.name}>
            <Paper elevation={3} sx={{ p: 1, mt: 1, mb: 1 }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {s.name}
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Label</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Default?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {s.options.map((o, i) => {
                    return (
                      <TableRow key={`${s.name}|${i}`}>
                        <TableCell>
                          <TextField
                            variant="standard"
                            value={o.label}
                            onChange={(e) => { changeLabel(i, e.currentTarget.value, s.options, s.setter); }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="standard"
                            value={o.value}
                            type="number"
                            onChange={(e) => { changeValue(i, e.currentTarget.value, s.options, s.setter); }}
                          />
                        </TableCell>
                        <TableCell sx={{ p: 0 }}>
                          {o.default ? (
                            <Tooltip title="Is default">
                              <IconButton>
                                <DoneIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Make default">
                              <IconButton
                                onClick={() => {
                                  makeDefault(i, s.options, s.setter);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default SettingsOptions;
