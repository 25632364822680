import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container, useMediaQuery } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider} from 'react-redux';
import { store } from './store'
import { Authorization } from "./auth/Authorization";

// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: blueGrey[500],
    },
    mode: "dark", // prefersDarkMode ? 'dark' : 'light',
  },
  shape: {
    borderRadius: 0,
  },
  breakpoints: {
    values: {
        xs: 0,
        sm: 600,
        md: 1200,
        lg: 1800,
        xl: 2400,
      },
  }
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<React.StrictMode>
    <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container maxWidth={false} sx={{width: 'calc(100vw - 1px)'}} disableGutters={true}>
          <App />
          </Container>
        </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
/*
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container maxWidth={false} sx={{width: 'calc(100vw - 1px)'}} disableGutters={true}>
          <App />
          </Container>
        </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
