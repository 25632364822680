import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setNewsRatio, selectNewsRatio, setChartsPerRow, selectChartsPerRow, selectMaxCoins, setMaxCoins, selectTelegramId } from "./reducers/terminalReducer";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slider, Tooltip, TextField, Typography } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import { selectDefaultSymbols, updateDefaultSymbols } from "./reducers/priceReducer";

function ConnectionStatus() {
  
  const newsRatio = useSelector(selectNewsRatio);
  const chartsPerRow = useSelector(selectChartsPerRow);
  const maxCoins = useSelector(selectMaxCoins);
  const defaultSymbols = useSelector(selectDefaultSymbols);
  const telegramId = useSelector(selectTelegramId);
  const dispatch = useDispatch();

  //const tradeValueOptions = useSelector(tradeValues);
  //const longOrders = JSON.parse(JSON.stringify([...tradeValueOptions.filter(t => t.direction === "Long")])) as TradeValue[];
  //const shortOrders = JSON.parse(JSON.stringify([...tradeValueOptions.filter(t => t.direction === "Short")])) as TradeValue[];

  const [open, setOpen] = React.useState<boolean>(false);

  const changeValue = (index:number, direction:"Long"|"Short", value:string) => {

    if (value) {
        //let newLongValues = [...longOrders];
        //let newShortValues = [...shortOrders];
    
        if (direction === "Long") {
            //newLongValues[index].value = parseFloat(value);
        }
        else {
            //newShortValues[index].value = parseFloat(value);
        }
    
       // dispatch(setTradeValues([...newLongValues, ...newShortValues]));
    }
    
  }

  const changeShortcutKey = (index:number, direction:"Long"|"Short", value:string) => {

    //let newLongValues = [...longOrders];
    //let newShortValues = [...shortOrders];

    if (direction === "Long") {
        //newLongValues[index].shortcutKey = value[0].toUpperCase();
    }
    else {
        //newShortValues[index].shortcutKey = value[0].toUpperCase();
    }

    //dispatch(setTradeValues([...newLongValues, ...newShortValues]));
  }

  const changeActiveStatus = (index:number, direction:"Long"|"Short") => {

    //let newLongValues = [...longOrders];
    //let newShortValues = [...shortOrders];

    if (direction === "Long") {
        //newLongValues[index].active = !newLongValues[index].active;
    }
    else {
        //newShortValues[index].active = !newShortValues[index].active;
    }

    //dispatch(setTradeValues([...newLongValues, ...newShortValues]));
  }

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
      {
        value: 7,
        label: '7',
      },
      {
        value: 8,
        label: '8',
      },
      {
        value: 9,
        label: '9',
      },
      {
        value: 10,
        label: '10',
      },
      {
        value: 11,
        label: '11',
      },
      {
        value: 12,
        label: '12',
      },
  ];
  
  const [tempDefaultSymbols, setTempDefaultSymbols] = React.useState(defaultSymbols);

  React.useEffect(() => {
    if (defaultSymbols !== tempDefaultSymbols) {
        setTempDefaultSymbols(defaultSymbols);
    }
  }, [defaultSymbols])

  const [tempMaxCoins, setTempMaxCoins] = React.useState(maxCoins);

  React.useEffect(() => {
    if (maxCoins !== tempMaxCoins) {
        setTempMaxCoins(maxCoins);
    }
  }, [maxCoins])

  return (
    <React.Fragment>
        <Tooltip title="User settings">
            <IconButton
                onClick={() => {setOpen(true);}}
            >
                <SettingsIcon />
            </IconButton>
        </Tooltip>

        <Dialog
            open={open}
            onClose={() => {setOpen(false)}}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>Terminal settings</DialogTitle>
            <DialogContent>
                <Typography variant="body1">News vs Trading pane ratio</Typography>
                <Slider
                    sx={{mt:2, mb: 2}}
                    aria-label="News pane ratio"
                    value={newsRatio}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={marks}
                    min={0}
                    max={12}
                    onChange={(e: any, v: any) => { dispatch(setNewsRatio(v as number)) }}
                    />

                <Typography variant="body1" sx={{mt: 4}}>Charts per row</Typography>
                <Slider
                    sx={{mt:2}}
                    aria-label="Charts per row"
                    value={chartsPerRow}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={marks.filter(m => m.value !== 0)}
                    min={1}
                    max={12}
                    onChange={(e: any, v: any) => { dispatch(setChartsPerRow(v as number)) }}
                    />

                <TextField
                    variant="outlined"
                    fullWidth
                    sx={{mt: 4}}
                    value={tempDefaultSymbols}
                    onChange={(e:any) => { setTempDefaultSymbols(e.currentTarget.value); }}
                    onBlur={() => { dispatch(updateDefaultSymbols(tempDefaultSymbols)) }}
                    label="Default symbols"
                />

                <TextField
                    variant="outlined"
                    fullWidth
                    sx={{mt: 4}}
                    value={tempMaxCoins}
                    onChange={(e:any) => { setTempMaxCoins(parseInt(e.currentTarget.value) || 6); }}
                    onBlur={() => { dispatch(setMaxCoins(tempMaxCoins)) }}
                    label="Max coins"
                    type="number"
                />

                <TextField
                    variant="outlined"
                    fullWidth
                    sx={{mt: 4}}
                    value={telegramId}
                    label="Telegram Chat ID"
                    type="number"
                    disabled={true}
                />

            </DialogContent>
            <DialogActions>
                <Button
                    sx={{m:2}}
                variant="contained"
                    onClick={() => setOpen(false)}
                >Apply</Button>
            </DialogActions>
        </Dialog>
        
    </React.Fragment>
  );
}

export default ConnectionStatus;
