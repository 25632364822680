import { Middleware } from "redux";
import {
  startConnecting,
  subscribeToBot,
  disconnect,
  connectionEstablished,
  updateBotStatus,
  sendTrade,
  forceSell,
  extendTimer,
  changeTakeProfit,
  setTakeProfit,
  cancelTakeProfit,
  setStopLoss,
  setTrailingStopLoss,
  clearTrade,
  TradeSettingUpdate,
  getPersonalisationSettings,
  storePersonalisationSettings,
  setNewsRatio,
  setChartsPerRow,
  setMaxCoins,
  triggerAutoReducingTakeProfit,
  cancelAutoReducingTakeProfit,
  increasePosition,
  TradeSettingUpdateString,
  setIncrementalTakeProfit,
  setTelegram,
} from "../reducers/terminalReducer";
import { store } from "../store";
import { BotOrder, UIOrder, WebsocketAction } from "../types/BotOrder";
import { TerminalUIUpdate } from "../types/BotUpdate";

/* Settings from user personalisation */
import { requestConnect as requestNewConnectionPrices, updateDefaultSymbols } from "../reducers/priceReducer";
import {
  requestConnect as requestNewConnectionNews,
  setColumns,
  setDismissDisableTimeout,
  setFadeTimeout,
  setFirehoseMode,
  setShowEmbeddedTweet,
} from "../reducers/newsReducer";
import {
  setInitPercentOptions,
  setLeverageOptions,
  setScalp,
  setStopLossPercentOptions,
  setTakeProfitPercentOptions,
  setTest,
  setSmall,
  setBig,
  setTimerOptions
} from "../reducers/symbolReducer";

export function waitForSocketConnection(socket: WebSocket, callback: Function) {

    let retryCounter = 0;

  setTimeout(function () {
    if (socket?.readyState === 1) {
      store.dispatch(connectionEstablished());
      if (callback != null) {
        callback();
      }
    } else {
      console.log("wait for connection...", retryCounter);
      retryCounter++;
      waitForSocketConnection(socket, callback);
    }
  }, 500, retryCounter); // wait 5 milliseconds for the connection...
}

let socket: WebSocket | null = null;
let requestConnect: number = 0;
let targetIps: string[] = [];

/* Store when we first loaded the terminal */
/* Pretty basic, but we can use this to avoid saving changes from new user settings that have been fetched (vs. changed in the terminal) */
const loadDate = new Date();

const botMiddleware: Middleware = (store) => (next) => (action) => {
  if (
    !startConnecting.match(action) &&
    !subscribeToBot.match(action) &&
    !disconnect.match(action) &&
    !sendTrade.match(action) &&
    !forceSell.match(action) &&
    !extendTimer.match(action) &&
    !clearTrade.match(action) &&
    !changeTakeProfit.match(action) &&
    !setTakeProfit.match(action) &&
    !setIncrementalTakeProfit.match(action) &&
    !setStopLoss.match(action) &&
    !setTrailingStopLoss.match(action) &&
    !increasePosition.match(action) &&
    !cancelTakeProfit.match(action) &&
    !triggerAutoReducingTakeProfit.match(action) &&
    !cancelAutoReducingTakeProfit.match(action) &&
    !getPersonalisationSettings.match(action) &&
    !setColumns.match(action) &&
    !setDismissDisableTimeout.match(action) &&
    !setFadeTimeout.match(action) &&
    !setFirehoseMode.match(action) &&
    !setShowEmbeddedTweet.match(action) &&
    !setInitPercentOptions.match(action) &&
    !setLeverageOptions.match(action) &&
    !setStopLossPercentOptions.match(action) &&
    !setTakeProfitPercentOptions.match(action) &&
    !setTimerOptions.match(action) &&
    !setTest.match(action) &&
    !setScalp.match(action) &&
    !setSmall.match(action) &&
    !setBig.match(action) &&
    !setNewsRatio.match(action) &&
    !setChartsPerRow.match(action) &&
    !setMaxCoins.match(action) &&
    !updateDefaultSymbols.match(action)
  ) {
    return next(action);
  }

  if (startConnecting.match(action)) {

    if (socket === null) {
        requestConnect = new Date().valueOf();
        socket = new WebSocket("wss://relay.kryptrader.com");
    }
    else if (socket.CLOSED) {
        if (new Date().valueOf() - requestConnect > 5000) {
            requestConnect = new Date().valueOf();
            socket = new WebSocket("wss://relay.kryptrader.com");
        }
    }

    store.dispatch(subscribeToBot(action.payload));

    socket.onmessage = function (event) {
      let parsedMessage = JSON.parse(event.data);
      // Just a UI update
      if (parsedMessage.type === "Update") {
        store.dispatch(updateBotStatus(parsedMessage as TerminalUIUpdate));
      }
    };
  }

  if (subscribeToBot.match(action)) {
    // Subscribe as a terminal to any bots that will listen
    if (socket && socket !== null) {

       
            // Set connected to true after 200ms
            setTimeout(() => {
                waitForSocketConnection(socket as WebSocket, function () {
                    let subscriptionMessage = {
                      subscribe: "Terminal",
                      target: action.payload.targetIp,
                      auth: action.payload.authCode,
                    };
            
                    targetIps = [...targetIps, action.payload.targetIp];
            
                    if (socket !== null && socket.OPEN && !socket.CONNECTING) {
                      socket.send(JSON.stringify(subscriptionMessage));
                    }
                  });
            }, 200);
        

      
    }
  }

  if (disconnect.match(action)) {
    socket?.close();
  }

  if (sendTrade.match(action)) {

    // Only send to target IPs that are provided in the action payload


    targetIps.filter(ip => action.payload.targetBots === null || action.payload.targetBots.findIndex(b => b.ip === ip) > -1).map((targetIp) => {
      let botOrder: BotOrder = {
        target: targetIp,
        timestamp: new Date().valueOf(),
        ...action.payload,
      };

      socket?.send(JSON.stringify(botOrder));
    });
  }

  if (forceSell.match(action)) {
    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: action.payload,
        actionType: "ForceSell",
        action: "",
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (extendTimer.match(action)) {
    let payload = action.payload as TradeSettingUpdate;

    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: payload.tradeId,
        actionType: "ChangeTimer",
        action: payload.value.toString(),
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (changeTakeProfit.match(action)) {
    let payload = action.payload as TradeSettingUpdate;

    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: payload.tradeId,
        actionType: "ChangeTakeProfit",
        action: payload.value.toString(),
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (setTakeProfit.match(action)) {
    let payload = action.payload as TradeSettingUpdate;

    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: payload.tradeId,
        actionType: "SetTakeProfit",
        action: payload.value.toString(),
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (setIncrementalTakeProfit.match(action)) {
    let payload = action.payload as TradeSettingUpdate;

    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: payload.tradeId,
        actionType: "SetIncrementalTakeProfit",
        action: payload.value.toString(),
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (setStopLoss.match(action)) {
    let payload = action.payload as TradeSettingUpdate;

    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: payload.tradeId,
        actionType: "SetStopLoss",
        action: payload.value.toString(),
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (setTrailingStopLoss.match(action)) {
    let payload = action.payload as TradeSettingUpdate;

    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: payload.tradeId,
        actionType: "SetTrailingStopLoss",
        action: payload.value.toString(),
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (increasePosition.match(action)) {
    let payload = action.payload as TradeSettingUpdateString;

    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: payload.tradeId,
        actionType: "IncreasePosition",
        action: payload.value.toString(),
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (cancelTakeProfit.match(action)) {
    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: action.payload,
        actionType: "CancelTakeProfit",
        action: "",
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (triggerAutoReducingTakeProfit.match(action)) {
    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: action.payload,
        actionType: "TriggerAutoReducingTakeProfit",
        action: "",
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (cancelAutoReducingTakeProfit.match(action)) {
    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: action.payload,
        actionType: "CancelAutoTakeProfit",
        action: "",
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (clearTrade.match(action)) {
    targetIps.map((targetIp) => {
      let botAction: WebsocketAction = {
        target: targetIp,
        id: action.payload,
        actionType: "ClearTrade",
        action: "",
      };

      socket?.send(JSON.stringify(botAction));
    });
  }

  if (getPersonalisationSettings.match(action)) {
    const accessToken = store.getState().authorization.accessToken;

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    fetch("https://relay.kryptrader.com/settings", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (data.bots) {
            for (let i = 0; i < data.bots.length; i++) {

                if (data.bots[i].disabled) {
                    continue;
                }

              setTimeout(() => {
                store.dispatch(
                  startConnecting({
                    targetIp: data.bots[i].targetIp,
                    authCode: data.bots[i].authCode,
                    disabled: data.bots[i].disabled,
                  })
                );
              }, i * 150);
            }

            if (store.getState().terminal.isConnected === false) {
              store.dispatch(requestNewConnectionPrices());
              store.dispatch(requestNewConnectionNews());
            }
          }

          if (data.news) {
            // Just set the ones that differ from the default
            if (data.news.columns !== undefined) {
              store.dispatch(setColumns(data.news.columns));
            }
            if (data.news.showEmbeddedTweet === false) {
              store.dispatch(setShowEmbeddedTweet(data.news.showEmbeddedTweet));
            }
            if (data.news.firehoseMode === true) {
              store.dispatch(setFirehoseMode(data.news.firehoseMode));
            }
            if (data.news.fadeTimeout !== undefined) {
              store.dispatch(setFadeTimeout(data.news.fadeTimeout));
            }
            if (data.news.dismissDisableTimeout !== undefined) {
              store.dispatch(
                setDismissDisableTimeout(data.news.dismissDisableTimeout)
              );
            }
          }

          if (data.telegram !== undefined) {

            // Check if data.telegram is a number
            let telegram = data.telegram;
            if (typeof telegram === "number") {
                store.dispatch(setTelegram(data.telegram as number));
            }
          }

          if (data.newsRatio !== undefined) {
            store.dispatch(setNewsRatio(data.newsRatio));
          }

          if (data.chartsPerRow !== undefined) {
            store.dispatch(setChartsPerRow(data.chartsPerRow));
          }

          if (data.maxCoins !== undefined) {
            store.dispatch(setMaxCoins(data.maxCoins));
          }

          if (data.defaultSymbols !== undefined) {
            store.dispatch(updateDefaultSymbols(data.defaultSymbols));
          }

          if (data.test) {
            store.dispatch(setTest(data.test));
          }

          if (data.scalp) {
            store.dispatch(setScalp(data.scalp));
          }

          if (data.small) {
            store.dispatch(setSmall(data.small));
          }

          if (data.big) {
            store.dispatch(setBig(data.big));
          }

          if (data.initPercentOptions) {
            store.dispatch(setInitPercentOptions(data.initPercentOptions));
          }

          if (data.takeProfitPercentOptions) {
            store.dispatch(
              setTakeProfitPercentOptions(data.takeProfitPercentOptions)
            );
          }

          if (data.leverageOptions) {
            store.dispatch(setLeverageOptions(data.leverageOptions));
          }

          if (data.stopLossPercentOptions) {
            store.dispatch(
              setStopLossPercentOptions(data.stopLossPercentOptions)
            );
          }

          if (data.timerOptions) {
            store.dispatch(setTimerOptions(data.timerOptions));
          }
        }
        //console.log(data);
      });
  }

  if (
    setColumns.match(action) ||
    setDismissDisableTimeout.match(action) ||
    setFadeTimeout.match(action) ||
    setFirehoseMode.match(action) ||
    setShowEmbeddedTweet.match(action) ||
    setInitPercentOptions.match(action) ||
    setLeverageOptions.match(action) ||
    setStopLossPercentOptions.match(action) ||
    setTakeProfitPercentOptions.match(action) ||
    setTimerOptions.match(action) ||
    setTest.match(action) ||
    setScalp.match(action) ||
    setSmall.match(action) ||
    setBig.match(action) ||
    setNewsRatio.match(action) ||
    setChartsPerRow.match(action) ||
    setMaxCoins.match(action) ||
    updateDefaultSymbols.match(action)
  ) {

    if (new Date().valueOf() - loadDate.valueOf() > 5 * 1000) {

      // Proceed through the reducer
      var result = next(action);

      let state = store.getState();

      let data: any = {};
      data.bots = state.terminal.bots;
      data.telegram = state.terminal.telegram;
      data.news = {
        columns: state.news.columns,
        showEmbeddedTweet: state.news.showEmbeddedTweet,
        firehoseMode: state.news.firehoseMode,
        fadeTimeout: state.news.fadeTimeout,
        dismissDisableTimeout: state.news.dismissDisableTimeout,
      };

      // Trade settings
      data.test = state.symbols.test;
      data.scalp = state.symbols.scalp;
      data.small = state.symbols.small;
      data.big = state.symbols.big;

      data.newsRatio = state.terminal.newsRatio;
      data.chartsPerRow = state.terminal.chartsPerRow;
      data.maxCoins = state.terminal.maxCoins;
      data.defaultSymbols = state.prices.defaultSymbols;

      // Trade options
      data.initPercentOptions = state.symbols.initPercentOptions;
      data.takeProfitPercentOptions = state.symbols.takeProfitPercentOptions;
      data.leverageOptions = state.symbols.leverageOptions;
      data.stopLossPercentOptions = state.symbols.stopLossPercentOptions;
      data.timerOptions = state.symbols.timerOptions;

      // Build up the object

      const accessToken = store.getState().authorization.accessToken;

      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      fetch("https://relay.kryptrader.com/settings", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      return result;
    }
  }

  next(action);
};

export default botMiddleware;
