export const getCommonDecimals = (value:number) => {
    if (value > 10000) {
        return 0;
    }
    else if (value > 1000) {
        return 2; // Returning 1 decimal place can mess up currencies
    }
    else if (value > 100) {
        return 2;
    }
    else if (value > 100) {
        return 3;
    }
    else if (value > 10) {
        return 4;
    }
    else if (value > 1) {
        return 5;
    }
    else if (value > 0.1) {
        return 6;
    }
    else if (value > 0.01) {
        return 7;
    }
    else if (value > 0.001) {
        return 8;
    }
    else if (value > 0.0001) {
        return 9;
    }
    else if (value > 0.00001) {
        return 10;
    }
    else if (value > 0.000001) {
        return 11;
    }
    else if (value > 0.0000001) {
        return 12;
    }
    else if (value > 0.00000001) {
        return 13;
    }
    else if (value > 0.000000001) {
        return 14;
    }
    else if (value > 0.0000000001) {
        return 15;
    }

    return 0;
}