/*import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'

import monitorReducersEnhancer from './enhancers/monitorReducers'

import rootReducer from './reducers/rootReducer'

export default function configureStore(preloadedState) {
  const middlewares = [loggerMiddleware, thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const composedEnhancers = compose(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers/rootReducer', () => store.replaceReducer(rootReducer))
  }

  return store
}
*/
import { configureStore, ThunkAction, AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import terminalReducer from './reducers/terminalReducer';
import symbolsReducer from './reducers/symbolReducer';
import pricesReducer from './reducers/priceReducer';
import newsReducer from './reducers/newsReducer';
import authorizationSlice from './reducers/authorizationSlice';
import botMiddleware from './middleware/BotMiddleware'
import priceMiddleware from './middleware/PriceMiddleware'
import newsMiddleware from './middleware/NewsMiddleware'
import discordReducer from './reducers/discordReducer';
import { useDispatch } from 'react-redux';
import { loadState, saveState } from './sessionStorage';

const persistedState = loadState();

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export const store = configureStore({
  reducer: {
    terminal: terminalReducer,
    symbols: symbolsReducer,
    prices: pricesReducer,
    news: newsReducer,
    authorization: authorizationSlice,
    discord: discordReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([botMiddleware, priceMiddleware, newsMiddleware]),
  preloadedState: persistedState,
  devTools: false,
})

store.subscribe(() => {
    let state = store.getState();
    saveState({authorization: state.authorization, discord: state.discord });
  });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

/*
export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
    dispatch: AppThunkDispatch;
  };
*/
// you can also create some redux hooks using the above explicit types
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>