import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
interface NewsState {
  requestNewConnection: boolean;
  isConnected: boolean;
  lastWebsocketReceived: number;
  news: News[];
  columns: number;
  showEmbeddedTweet: boolean;
  firehoseMode: boolean;
  dismissDisableTimeout: number;
  fadeTimeout: number;
}

/** The source news items (standard for all scrapers) */
export interface News {

    /** The news/article ID at the source. Can be used to dedupe. */
    id?: string | undefined;

    /** The type of news (e.g. Twitter, blog, Binance, Coinbase) */
    type?: string | undefined;

    /** A source within the type if needed (e.g. @elonmusk within Twitter, blog author names) */
    source: string;

    /** The title of the news to display */
    title: string;

    /** The body content of the news */
    content: string;

    /** A URI back to the original detection */
    uri?: string | undefined;

    /** If the source has a timestamp, what was it */
    sourceTimestamp?: number | undefined;

    /** When did we detect it (anywhere in the world) */
    detectionTimestamp?: number | undefined;

    /** Any coins mentioned or related to entities in this news */
    coins: string[];

    /** The full entity name for this news (if any) */
    fromName: string;

    /** Any entities referenced in this news item */
    referencedNames: string[];

    /** If the title has been translated, this will be in the original language */
    originalTitle: string | null;

    /** If the content has been translated, this will be in the original language */
    originalContent: string | null;

    /** Does this news meet the criteria for 'important'? */
    important: boolean;

    /** Does this news meet the criteria for 'critical'? To meet this criteria the news could almost be automated 
     * NOT IN USE YET */
    critical?: boolean | undefined;

    /** When did it get to the news service for broadcasting (this should be stamped after all business rule logic is applied) */
    timestamp: number;

    /** If we're re-forwarding another aggregation, list it here */
    rebroadcast?: string | undefined;

    /** Has this news already been dismissed? */
    dismissed?: boolean | undefined;

    sentiment?: NewsSentiment | undefined;
}

/*
{
    "sentiment": "NEUTRAL",
    "bullish": null,
    "buyingSuggestion": [],
    "buyingDirection": "LONG",
    "buyingJustification": "",
    "processingMillis": 1127,
    "cost": 0.001895,
    "partnership": false,
    "partnershipJustification": ""
}
*/
export interface NewsSentiment {
    sentiment: string;
    bullish: boolean | null;
    buyingSuggestion: string[];
    buyingDirection: string;
    buyingJustification: string;
    processingMillis: number;
    cost: number;
    partnership: boolean;
    partnershipJustification: string;
    translation: string;
}

// Define the initial state using that type
const initialState: NewsState = {
  requestNewConnection: false,
  isConnected: false,
  lastWebsocketReceived: new Date().valueOf(),
  news: [],
  columns: 4,
  showEmbeddedTweet: true,
  firehoseMode: false,
  fadeTimeout: 60,
  dismissDisableTimeout: 3,
};

const twitterNewsGapFromOtherNews = 10000;

export const newsSlice = createSlice({
  name: "news",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    requestConnect: (state) => {
      state.requestNewConnection = true;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.requestNewConnection = false;
    },
    lastUpdated: (state) => {
        state.lastWebsocketReceived = new Date().valueOf();
      },
    addNews: (state, action: PayloadAction<News>) => {

        console.log('Add news', action.payload);
        console.log('Add news', [...state.news.filter(n => !n.sentiment)]);

        // Assume the news server is de-duping, otherwise we will miss things like the Binance Blog HTML content
        if (state.news.findIndex(n => 
            (n.id === action.payload.id && action.payload.id && n.sentiment && action.payload.rebroadcast === n.rebroadcast) 
            || (n.uri === action.payload.uri && action.payload.uri && n.sentiment && action.payload.rebroadcast === n.rebroadcast) 
            || (n.title === action.payload.title && action.payload.type !== "Twitter" && n.sentiment && action.payload.rebroadcast === n.rebroadcast)) === -1
        ) {

            // If we have a matching news item, we need to update the sentiment
            let existingNews = state.news.find(n => (n.timestamp === action.payload.timestamp && action.payload.timestamp) || (n.id === action.payload.id && action.payload.id && action.payload.rebroadcast === n.rebroadcast) || (n.uri === action.payload.uri && action.payload.uri && action.payload.rebroadcast === n.rebroadcast));
            console.log('New news, looking for existing news', existingNews);
            if (existingNews && !existingNews.sentiment && action.payload.sentiment) {
                existingNews.sentiment = action.payload.sentiment;
                
                // Update the state now
                state.news = [...state.news.filter(n => n.timestamp !== action.payload.timestamp), existingNews].sort((a, b) => a.timestamp > b.timestamp ? -1 : 1).splice(0, 50);
            }
            else {

            // Find the latest non-Twitter news
            let nonTwitterNews = state.news.filter(n => n.type !== "Twitter");
            let timeSinceLastTwitterNews = twitterNewsGapFromOtherNews + 1;

            console.log(`state.news.length = ${state.news.length} vs nonTwitterNews.length = ${nonTwitterNews.length}`);

            if (nonTwitterNews.length > 0 && action.payload.type === "Twitter") {
                timeSinceLastTwitterNews = new Date().valueOf() - nonTwitterNews[0].timestamp;
            }

            console.log(`timeSinceLastTwitterNews = ${timeSinceLastTwitterNews}`);

            // If the gap isn't big enough, store the news but flag as dismissed
            // This should allow the beeps to happen but not display it
            if (timeSinceLastTwitterNews < twitterNewsGapFromOtherNews) {
                state.news = [{...action.payload, dismissed: true}, ...state.news].sort((a, b) => a.timestamp > b.timestamp ? -1 : 1).splice(0, 50);
                console.log('Added news as dismissed');
            }
            else {
                state.news = [{...action.payload, dismissed: false}, ...state.news].sort((a, b) => a.timestamp > b.timestamp ? -1 : 1).splice(0, 50);
                console.log('Added news normally');

                /*
                if (action.payload.timestamp > (new Date().valueOf() - 5 * 1000)) {
                    if (!("Notification" in window)) {
                        console.log("This browser does not support desktop notification");
                    }
                    else if (Notification.permission !== 'denied') {
                        Notification.requestPermission().then(function (permission) {
                          // If the user accepts, let's create a notification
                          if (permission === "granted") {
                            var notification = new Notification(action.payload.title, { body: action.payload.content });
                            notification.onclick = function(event) {
                                event.preventDefault(); // prevent the browser from focusing the Notification's tab
                                window.focus();
                              }
                          }
                        });
                      }
                }
                */
            }
            }
            
        }
        //else if (action.payload.rebroadcast) {
            //state.news = [{...action.payload, rebroadcast: (action.payload.rebroadcast || "") + " (later)" }, ...state.news.filter(n => n.id !== action.payload.id)].sort((a, b) => a.timestamp > b.timestamp ? -1 : 1).splice(0, 50);
        //}
    },
    dismissNews: (state, action: PayloadAction<number>) => {
        state.news = state.news.map(n => ({...n, dismissed: action.payload === n.timestamp || n.timestamp < (new Date().valueOf() - 15 * 1000) ? true : n.dismissed}));
    },
    hideNews: (state, action: PayloadAction<number>) => {
        state.news = state.news.filter(n => action.payload !== n.timestamp);
    },
    setFirehoseMode: (state, action: PayloadAction<boolean>) => {
        state.firehoseMode = action.payload;
    },
    setColumns: (state, action: PayloadAction<number>) => {
        state.columns = action.payload;
    },
    setShowEmbeddedTweet: (state, action: PayloadAction<boolean>) => {
        state.showEmbeddedTweet = action.payload;
    },
    setFadeTimeout: (state, action: PayloadAction<number>) => {
        state.fadeTimeout = action.payload;
    },
    setDismissDisableTimeout: (state, action: PayloadAction<number>) => {
        state.dismissDisableTimeout = action.payload;
    },
  },
});

export const { requestConnect, connectionEstablished, lastUpdated, addNews, dismissNews, hideNews, setColumns, setFirehoseMode, setShowEmbeddedTweet, setFadeTimeout, setDismissDisableTimeout } = newsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const news = (state: RootState) => state.news.news;
export const selectNewsWebsocketConnected = (state: RootState) => state.news.lastWebsocketReceived;
export const selectColumns = (state: RootState) => state.news.columns;
export const selectShowEmbeddedTweet = (state: RootState) => state.news.showEmbeddedTweet;
export const selectFirehoseMode = (state: RootState) => state.news.firehoseMode;
export const selectFadeTimeout = (state: RootState) => state.news.fadeTimeout;
export const selectDismissDisableTimeout = (state: RootState) => state.news.dismissDisableTimeout;

export default newsSlice.reducer;
