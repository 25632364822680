import React from "react";
import AppBar from "@mui/material/AppBar";
import { Toolbar } from '@mui/material';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormGroup, FormControl, Link as MaterialLink, Hidden, Typography, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ConnectionStatus from "./ConnectionStatus";
//import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import { addSymbol } from "./reducers/symbolReducer";
import Help from "./Help";
import { selectDisplayName } from "./reducers/discordReducer";
import UserSettings from "./UserSettings";
import { Link } from "react-router-dom";
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { selectEnableSpeaking, selectIsMuted, setEnableSpeaking, setIsMuted } from "./reducers/authorizationSlice";
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import Diagnostics from "./Diagnostics";
import DiagnosticsButton from "./DiagnosticsButton";

function Header() {
//  const context = React.useContext<SymbolWatcherContextInterface | null>(SymbolWatcherContext);

  const [showDialog, setShowDialog] = React.useState(false);
  const [value, setValue] = React.useState<string>("");

  const displayName = useSelector(selectDisplayName);
  const isMuted = useSelector(selectIsMuted);
  const enableSpeaking = useSelector(selectEnableSpeaking);

  const dispatch = useDispatch();

  //useHotkeys("ALT + B", () => setShowDialog(true));

    const confirmSymbol = () => {
        dispatch(addSymbol({symbol: value, pinned: true}));
        setShowDialog(false);
        setValue("");
    }

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme:any) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Tooltip title="Add a symbol">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={() => { setShowDialog(true); }}
              >
                <ControlPointIcon />
              </IconButton>
            </Tooltip>

            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, marginLeft: 2, textAlign: "left" }}
            >
                <Link to="/" style={{textDecoration:'none'}}>
                    <MaterialLink color="text.primary" sx={{textDecoration:'none'}}>
                        Terminal
                    </MaterialLink>
              </Link>

            </Typography>

{/* 
            <Help />
*/}
            <Tooltip title={enableSpeaking ? "Shut up" : "Speak to me"}>
                <IconButton
                    onClick={() => { dispatch(setEnableSpeaking(!enableSpeaking)) }}
                >
                    {
                        enableSpeaking ? <VoiceOverOffIcon /> : <RecordVoiceOverIcon />
                    }
                </IconButton>
            </Tooltip>

            <Tooltip title={isMuted ? "Unmute" : "Mute"}>
                <IconButton
                    onClick={() => { dispatch(setIsMuted(!isMuted)) }}
                >
                    {
                        isMuted ? <VolumeOffIcon /> : <VolumeMuteIcon />
                    }
                </IconButton>
            </Tooltip>

            <DiagnosticsButton />

            <UserSettings />

            <ConnectionStatus />

          </Toolbar>
        </AppBar>
      </Box>
      <Dialog
        open={showDialog}
        maxWidth="md"
        sx={{ p: 2 }}
        onClose={() => {
          setShowDialog(false);
        }}
      >
        <form action="javascript:void(0);">
        <DialogTitle>Add a symbol</DialogTitle>
        <DialogContent>
        <TextField
                sx={{ mt: 3 }}
                name="value"
                value={value}
                label="Symbol to add"
                fullWidth
                autoFocus
                onChange={(e:any) => {setValue(e.currentTarget.value.toUpperCase())}}
              />
            </DialogContent>
        <DialogActions>
        <Button
            variant="outlined"
            sx={{ mb: 0, mr: 1 }}
            onClick={() => {setShowDialog(false); setValue(""); }}
            onKeyPress={(e:any) => {
              if (e.key === "Enter" || e.key === "Space") {
                setShowDialog(false); setValue("");
              }
            }}
          >
            Cancel
          </Button>
          
          <Button
            variant="contained"
            onClick={confirmSymbol}
            type="submit"
            onKeyPress={(e:any) => {
              if (e.key === "Enter" || e.key === "Space") {
                confirmSymbol();
              }
            }}
          >
            Confirm
          </Button>
          
        </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default Header;
