import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import {
  setLoggedIn
} from './authorizationSlice';

interface UserProfileState {
  displayName: string,
}

const initialState: UserProfileState = {
  displayName: ''
};

export const discordSlice = createSlice({
  name: 'discord',
  initialState,
  reducers: {
    setDisplayName: (state, action: PayloadAction<string>) => {
      state.displayName = action.payload;
    }
  },
});

export const { setDisplayName } = discordSlice.actions;

export const selectDisplayName = (state: RootState) => state.discord.displayName;

export const setUserProfileAsync = (accessToken: string): AppThunk => async (dispatch) => {

  const myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + accessToken);

  await fetch('https://discord.com/api/users/@me', {
    method: 'GET',
    headers: myHeaders,
  }).then(response => response.json())
    .then((data) => {
      dispatch(setDisplayName(data.username ? data.username : data.id));
    }).catch((error) => {
      console.log(error);
      if (error instanceof XMLHttpRequest) {
        if (error.status === 401) {
          dispatch(setLoggedIn(false));
        }
      }
    });
};

export default discordSlice.reducer;
