import React from "react";
import {
  IconButton,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  Paper,
  Box,
  Switch,
} from "@mui/material";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { connectedCount, isConnected } from "./reducers/terminalReducer";
//import { setTradeValues, TradeValue, tradeValues } from "./reducers/symbolReducer";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { selectTest, selectScalp, selectSmall, selectBig, setTest, setScalp, setSmall, setBig } from "./reducers/symbolReducer";
import { DiscFullTwoTone } from "@mui/icons-material";

function SettingsTradeValue() {
  
    const test = useSelector(selectTest);
    const scalp = useSelector(selectScalp);
    const small = useSelector(selectSmall);
    const big = useSelector(selectBig);

    const dispatch = useDispatch();

    // TEST
    const [testValue, setTestValue] = React.useState(test.value);
    const [testInitPercent, setTestInitPercent] = React.useState(test.initPercent);
    const [testTakeProfit, setTestTakeProfit] = React.useState(test.takeProfit);
    const [testStopLoss, setTestStopLoss] = React.useState(test.stopLoss);
    const [testTrailingStopLoss, setTestTrailingStopLoss] = React.useState(test.trailingStopLoss);
    const [testLeverage, setTestLeverage] = React.useState(test.leverage);
    const [testTimedSell, setTestTimedSell] = React.useState(test.timedSell);
    const [testLongShortcut, setTestLongShortcut] = React.useState(test.shortcutKeys[0]);
    const [testShortShortcut, setTestShortShortcut] = React.useState(test.shortcutKeys[1]);

    // SCALP
    const [scalpValue, setScalpValue] = React.useState(scalp.value);
    const [scalpInitPercent, setScalpInitPercent] = React.useState(scalp.initPercent);
    const [scalpTakeProfit, setScalpTakeProfit] = React.useState(scalp.takeProfit);
    const [scalpStopLoss, setScalpStopLoss] = React.useState(scalp.stopLoss);
    const [scalpTrailingStopLoss, setScalpTrailingStopLoss] = React.useState(scalp.trailingStopLoss);
    const [scalpLeverage, setScalpLeverage] = React.useState(scalp.leverage);
    const [scalpTimedSell, setScalpTimedSell] = React.useState(scalp.timedSell);
    const [scalpLongShortcut, setScalpLongShortcut] = React.useState(scalp.shortcutKeys[0]);
    const [scalpShortShortcut, setScalpShortShortcut] = React.useState(scalp.shortcutKeys[1]);

    // SMALL
    const [smallValue, setSmallValue] = React.useState(small.value);
    const [smallInitPercent, setSmallInitPercent] = React.useState(small.initPercent);
    const [smallTakeProfit, setSmallTakeProfit] = React.useState(small.takeProfit);
    const [smallStopLoss, setSmallStopLoss] = React.useState(small.stopLoss);
    const [smallTrailingStopLoss, setSmallTrailingStopLoss] = React.useState(small.trailingStopLoss);
    const [smallLeverage, setSmallLeverage] = React.useState(small.leverage);
    const [smallTimedSell, setSmallTimedSell] = React.useState(small.timedSell);
    const [smallLongShortcut, setSmallLongShortcut] = React.useState(small.shortcutKeys[0]);
    const [smallShortShortcut, setSmallShortShortcut] = React.useState(small.shortcutKeys[1]);

    // BIG
    const [bigValue, setBigValue] = React.useState(big.value);
    const [bigInitPercent, setBigInitPercent] = React.useState(big.initPercent);
    const [bigTakeProfit, setBigTakeProfit] = React.useState(big.takeProfit);
    const [bigStopLoss, setBigStopLoss] = React.useState(big.stopLoss);
    const [bigTrailingStopLoss, setBigTrailingStopLoss] = React.useState(big.trailingStopLoss);
    const [bigLeverage, setBigLeverage] = React.useState(big.leverage);
    const [bigTimedSell, setBigTimedSell] = React.useState(big.timedSell);
    const [bigLongShortcut, setBigLongShortcut] = React.useState(big.shortcutKeys[0]);
    const [bigShortShortcut, setBigShortShortcut] = React.useState(big.shortcutKeys[1]);
  
  return (
    <Grid container maxWidth="md"> 
        <Grid item xs={12}>
            <Paper elevation={3} sx={{p:1, mt:1, mb: 1}}>
                <Typography variant="h6" sx={{mb: 1}}>Trade config</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Label</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Init %</TableCell>
                            <TableCell>TP %</TableCell>
                            <TableCell>SL %</TableCell>
                            <TableCell>TSL %</TableCell>
                            <TableCell>Leverage</TableCell>
                            <TableCell>Timer (ms)</TableCell>
                            <TableCell>Shortcuts</TableCell>
                            <TableCell>Visible?</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                            <TableRow>
                                    <TableCell>{test.label}</TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={testValue} type="number" onChange={(e) => { setTestValue(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setTest({...test, value: testValue })) }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={testInitPercent} type="number" onChange={(e) => { setTestInitPercent(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setTest({...test, initPercent: testInitPercent })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={testTakeProfit} type="number" onChange={(e) => { setTestTakeProfit(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setTest({...test, takeProfit: testTakeProfit })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={testStopLoss} type="number" onChange={(e) => { setTestStopLoss(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setTest({...test, stopLoss: testStopLoss })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={testTrailingStopLoss} type="number" onChange={(e) => { setTestTrailingStopLoss(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setTest({...test, trailingStopLoss: testTrailingStopLoss })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={testLeverage} type="number" onChange={(e) => { setTestLeverage(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setTest({...test, leverage: testLeverage })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={testTimedSell} type="number" onChange={(e) => { setTestTimedSell(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setTest({...test, timedSell: testTimedSell })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{display:'flex', alignItems: 'center'}}>
                                            <Typography variant="body2" sx={{mr: 1}}>Long:</Typography>
                                            <TextField variant="standard" value={testLongShortcut} inputProps={{maxlength: 1}} onChange={(e) => { setTestLongShortcut(e.currentTarget.value.toUpperCase()) }} onBlur={() => { dispatch(setTest({...test, shortcutKeys: [testLongShortcut, test.shortcutKeys[1]] })) }} />
                                        </Box>
                                        <Box sx={{display:'flex', alignItems: 'center'}}>
                                            <Typography variant="body2" sx={{mr: 1}}>Short: </Typography>
                                            <TextField variant="standard" value={testShortShortcut} inputProps={{maxlength: 1}} onChange={(e) => { setTestShortShortcut(e.currentTarget.value.toUpperCase()) }} onBlur={() => { dispatch(setTest({...test, shortcutKeys: [test.shortcutKeys[0], testShortShortcut] })) }} />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Switch checked={test.visible} onChange={() => dispatch(setTest({...test, visible: !test.visible }))} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{scalp.label}</TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={scalpValue} type="number" onChange={(e) => { setScalpValue(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setScalp({...scalp, value: scalpValue })) }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={scalpInitPercent} type="number" onChange={(e) => { setScalpInitPercent(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setScalp({...scalp, initPercent: scalpInitPercent })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={scalpTakeProfit} type="number" onChange={(e) => { setScalpTakeProfit(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setScalp({...scalp, takeProfit: scalpTakeProfit })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={scalpStopLoss} type="number" onChange={(e) => { setScalpStopLoss(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setScalp({...scalp, stopLoss: scalpStopLoss })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={scalpTrailingStopLoss} type="number" onChange={(e) => { setScalpTrailingStopLoss(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setScalp({...scalp, trailingStopLoss: scalpTrailingStopLoss })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={scalpLeverage} type="number" onChange={(e) => { setScalpLeverage(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setScalp({...scalp, leverage: scalpLeverage })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={scalpTimedSell} type="number" onChange={(e) => { setScalpTimedSell(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setScalp({...scalp, timedSell: scalpTimedSell })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{display:'flex', alignItems: 'center'}}>
                                            <Typography variant="body2" sx={{mr: 1}}>Long:</Typography>
                                            <TextField variant="standard" value={scalpLongShortcut} inputProps={{maxlength: 1}} onChange={(e) => { setScalpLongShortcut(e.currentTarget.value.toUpperCase()) }} onBlur={() => { dispatch(setScalp({...scalp, shortcutKeys: [scalpLongShortcut, scalp.shortcutKeys[1]] })) }} />
                                        </Box>
                                        <Box sx={{display:'flex', alignItems: 'center'}}>
                                            <Typography variant="body2" sx={{mr: 1}}>Short: </Typography>
                                            <TextField variant="standard" value={scalpShortShortcut} inputProps={{maxlength: 1}} onChange={(e) => { setScalpShortShortcut(e.currentTarget.value.toUpperCase()) }} onBlur={() => { dispatch(setScalp({...scalp, shortcutKeys: [scalp.shortcutKeys[0], scalpShortShortcut] })) }} />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Switch checked={scalp.visible} onChange={() => dispatch(setScalp({...scalp, visible: !scalp.visible }))} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{small.label}</TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={smallValue} type="number" onChange={(e) => { setSmallValue(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setSmall({...small, value: smallValue })) }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={smallInitPercent} type="number" onChange={(e) => { setSmallInitPercent(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setSmall({...small, initPercent: smallInitPercent })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={smallTakeProfit} type="number" onChange={(e) => { setSmallTakeProfit(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setSmall({...small, takeProfit: smallTakeProfit })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={smallStopLoss} type="number" onChange={(e) => { setSmallStopLoss(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setSmall({...small, stopLoss: smallStopLoss })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={smallTrailingStopLoss} type="number" onChange={(e) => { setSmallTrailingStopLoss(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setSmall({...small, trailingStopLoss: smallTrailingStopLoss })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={smallLeverage} type="number" onChange={(e) => { setSmallLeverage(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setSmall({...small, leverage: smallLeverage })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={smallTimedSell} type="number" onChange={(e) => { setSmallTimedSell(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setSmall({...small, timedSell: smallTimedSell })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{display:'flex', alignItems: 'center'}}>
                                            <Typography variant="body2" sx={{mr: 1}}>Long:</Typography>
                                            <TextField variant="standard" value={smallLongShortcut} inputProps={{maxlength: 1}} onChange={(e) => { setSmallLongShortcut(e.currentTarget.value.toUpperCase()) }} onBlur={() => { dispatch(setSmall({...small, shortcutKeys: [smallLongShortcut, small.shortcutKeys[1]] })) }} />
                                        </Box>
                                        <Box sx={{display:'flex', alignItems: 'center'}}>
                                            <Typography variant="body2" sx={{mr: 1}}>Short: </Typography>
                                            <TextField variant="standard" value={smallShortShortcut} inputProps={{maxlength: 1}} onChange={(e) => { setSmallShortShortcut(e.currentTarget.value.toUpperCase()) }} onBlur={() => { dispatch(setSmall({...small, shortcutKeys: [small.shortcutKeys[0], smallShortShortcut] })) }} />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Switch checked={small.visible} onChange={() => dispatch(setSmall({...small, visible: !small.visible }))} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{big.label}</TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={bigValue} type="number" onChange={(e) => { setBigValue(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setBig({...big, value: bigValue })) }} />
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={bigInitPercent} type="number" onChange={(e) => { setBigInitPercent(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setBig({...big, initPercent: bigInitPercent })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={bigTakeProfit} type="number" onChange={(e) => { setBigTakeProfit(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setBig({...big, takeProfit: bigTakeProfit })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={bigStopLoss} type="number" onChange={(e) => { setBigStopLoss(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setBig({...big, stopLoss: bigStopLoss })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={bigTrailingStopLoss} type="number" onChange={(e) => { setBigTrailingStopLoss(parseFloat(e.currentTarget.value)) }} onBlur={() => { dispatch(setBig({...big, trailingStopLoss: bigTrailingStopLoss })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={bigLeverage} type="number" onChange={(e) => { setBigLeverage(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setBig({...big, leverage: bigLeverage })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="standard" value={bigTimedSell} type="number" onChange={(e) => { setBigTimedSell(parseInt(e.currentTarget.value)) }} onBlur={() => { dispatch(setBig({...big, timedSell: bigTimedSell })) }}/>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{display:'flex', alignItems: 'center'}}>
                                            <Typography variant="body2" sx={{mr: 1}}>Long:</Typography>
                                            <TextField variant="standard" value={bigLongShortcut} inputProps={{maxlength: 1}} onChange={(e) => { setBigLongShortcut(e.currentTarget.value.toUpperCase()) }} onBlur={() => { dispatch(setBig({...big, shortcutKeys: [bigLongShortcut, big.shortcutKeys[1]] })) }} />
                                        </Box>
                                        <Box sx={{display:'flex', alignItems: 'center'}}>
                                            <Typography variant="body2" sx={{mr: 1}}>Short: </Typography>
                                            <TextField variant="standard" value={bigShortShortcut} inputProps={{maxlength: 1}} onChange={(e) => { setBigShortShortcut(e.currentTarget.value.toUpperCase()) }} onBlur={() => { dispatch(setBig({...big, shortcutKeys: [big.shortcutKeys[0], bigShortShortcut] })) }} />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Switch checked={big.visible} onChange={() => dispatch(setBig({...big, visible: !big.visible }))} />
                                    </TableCell>
                                </TableRow>
                        </TableBody>
                </Table>
            </Paper>
        </Grid>
        
    </Grid>
  );
}

export default SettingsTradeValue;
