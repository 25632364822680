export function PlaySpecificTone(w: any, x: any, y: any, delay?:number | undefined) {
    let a = new AudioContext();
  //console.log(`${new Date().valueOf()}: Gain: ${w}, Hz: ${x}, ms:${y}`);
  let v = a.createOscillator();
  let u = a.createGain();
  v.connect(u);
  v.frequency.value = x;
  //v.type = "square";
  u.connect(a.destination);
  u.gain.value = w * 0.01;
  v.start(a.currentTime + (delay || 0) / 1000.0);
  v.stop(a.currentTime + y * 0.001 + (delay || 0) / 1000.0);
}

let gap = 8;
let duration = 70;
let lowHz = 1400;
let midHz = 1450;
let highHz = 1500;

export function PlayAlertTone() {
    PlaySpecificTone(20, highHz, duration);
    PlaySpecificTone(40, midHz, duration, gap + duration);
    PlaySpecificTone(60, highHz, duration, (gap + duration) * 2);
    PlaySpecificTone(80, midHz, duration, (gap + duration) * 3);
    PlaySpecificTone(100, highHz, duration, (gap + duration) * 4);
    PlaySpecificTone(120, midHz, duration, (gap + duration) * 5);
    PlaySpecificTone(100, highHz, duration, (gap + duration) * 6);
    PlaySpecificTone(120, midHz, duration, (gap + duration) * 7);
    PlaySpecificTone(100, highHz, duration, (gap + duration) * 8);
    PlaySpecificTone(120, midHz, duration, (gap + duration) * 9);
}

export function PlayNotificationTone() {
    PlaySpecificTone(40, midHz / 2, duration * 2);
    PlaySpecificTone(60, midHz / 2, duration * 2, ((gap + duration) * 2));
}