import {
  Box,
  Typography,
  Grid,
  Paper,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Price,
  selectAvailableQuotes,
  selectExchanges,
  selectPrices,
} from "./reducers/priceReducer";
import { CandlestickData } from "lightweight-charts";
import SymbolPriceTradingViewChartMemo2, {
  TradeConfig,
} from "./components/symbol/SymbolPriceTradingViewChartMemo2";
import { getCommonDecimals } from "./utils/NumberUtils";
import { GetPrimaryQuote } from "./utils/PriceUtils";
import { grey } from "@mui/material/colors";
import { addSymbol, removeSymbol } from "./reducers/symbolReducer";
import { selectChartsPerRow } from "./reducers/terminalReducer";
import { RootState } from "./store";

interface ComponentProps {
  symbol: string;
  headerComponent?: JSX.Element | undefined;
  hideCloseButton?: boolean;
}

function ChartsIndividual(props: ComponentProps) {
  const prices: Price[] = useSelector(
    (state) => selectPrices(state as RootState, props.symbol),
    shallowEqual
  );

  const chartsPerRow = useSelector(selectChartsPerRow);

  const dispatch = useDispatch();

  const availableQuotes = useSelector(selectAvailableQuotes);
  const exchanges = useSelector(selectExchanges);

  let arr = GetPrimaryQuote(props.symbol, exchanges, availableQuotes);
  let exchange = arr[0];
  let quote = arr[1];

  let lastHour = (new Date().valueOf() - 1000 * 60 * 60) / 1000;

  let chartPrices = (
    prices?.find((p) => p.exchange === exchange && p.quote === quote)
      ?.history || []
  ).filter((p) => p.timestamp > lastHour);

  let tradeConfig: TradeConfig = {
    takeProfits: [],
  };

  return (
    <Grid key={props.symbol} item xs={12} md={6} lg={12 / chartsPerRow}>
      <Paper elevation={3} sx={{ m: 1, p: 0 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body2"
            sx={{ pl: 1, pt: 1, cursor: "pointer" }}
            onClick={() => {
              dispatch(addSymbol({ symbol: props.symbol, pinned: true }));
            }}
          >
            {props.symbol}
            <span style={{ color: grey[700] }}> / {quote}</span>
          </Typography>
          {
            props.hideCloseButton ? null : (
              <Tooltip title="Hide symbol">
                <IconButton onClick={() => dispatch(removeSymbol(props.symbol))}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )
          }
          
        </Box>

        {
            props.headerComponent ? (
                <Box sx={{ pl: 1, pt: 1 }}>{props.headerComponent}</Box>
            ) : null
        }

        <SymbolPriceTradingViewChartMemo2
          height={200}
          symbolString={`${exchange.toUpperCase()}:${
            props.symbol
          }${quote.toUpperCase()}`}
          data={chartPrices.map(
            (p) =>
              ({
                time: p.timestamp,
                open: p.open,
                high: p.high,
                low: p.low,
                close: p.close,
              } as CandlestickData)
          )}
          precision={Math.max(2, getCommonDecimals(chartPrices[0]?.price))}
          showAsPercent={true}
          tradeConfig={tradeConfig}
        ></SymbolPriceTradingViewChartMemo2>
      </Paper>
    </Grid>
  );
}

export default ChartsIndividual;
