import { Middleware } from "redux";
import { requestConnect, addNews, setFirehoseMode } from "../reducers/newsReducer";
import { addSymbol, addSymbolNews, addSymbolNewsItem, AddSymbolPayload } from "../reducers/symbolReducer";
import { store } from "../store";
import { News, lastUpdated } from "../reducers/newsReducer";
import { addPrice } from "../reducers/priceReducer";
import { waitForSocketConnection } from "./BotMiddleware";

let socket1: WebSocket | null = null;

function connect() {
    socket1 = new WebSocket("wss://news.kryptrader.com");
  
    socket1.onopen = function (event) {
        // Set an interval to send "test" every 5 seconds
        setInterval(() => {
            if (socket1 !== null && socket1.OPEN) {
                socket1.send("test");
            }
        }, 5000);
    }

    socket1.onmessage = function (event) {

        // Check if the event is a simple timestamp check/response like received1712629391384
        if (event.data.includes("received")) {
            store.dispatch(lastUpdated());
            return;
        }

      let news = JSON.parse(event.data) as News;

      if (news.title?.startsWith("ON-CHAIN")) {
        return;
      }

      store.dispatch(
        addNews({
          ...news,
          dismissed: news.timestamp < new Date().valueOf() - 2000,
        } as News)
      );
  
      if ((news.coins || []).length === 1) {
        store.dispatch(addSymbol({symbol: news.coins[0], pinned: false}));
      }

      if ((news.coins || []).length > 0) {
        for (let i = 0; i < news.coins.length; i++) {
            store.dispatch(
                addSymbolNewsItem({ symbol: news.coins[i], news: news })
            );
        }
    }

    };
  
    let reconnecting = false;
  
    socket1.onclose = function (event) {
      console.log(event);
      if (!reconnecting) {
        reconnecting = true;
        socket1 = null;
        setTimeout(() => connect(), 5000);
      }
    };
  
    socket1.onerror = function (event) {
      console.log(event);
      if (!reconnecting) {
        reconnecting = true;
        socket1 = null;
        setTimeout(() => connect(), 5000);
      }
    };
  }

const newsMiddleware: Middleware = (store) => (next) => (action) => {
  if (
    !requestConnect.match(action) 
    && !setFirehoseMode.match(action)
    && !addPrice.match(action)
    && !addSymbol.match(action)
    ) {
    return next(action);
  }

  if (requestConnect.match(action) === true) {
      // Connect to the news service
      // This includes all message handlers & reconnection logic
    connect();
  }

  if (setFirehoseMode.match(action) === true) {
    //if (socket1 !== null) {

    if (socket1 !== null) {
            waitForSocketConnection(socket1, function () {
                if (socket1 !== null) {
                    if (action.payload === true) {
                        socket1.send(`{"subscribe": "firehose"}`);
                    }
                    else {
                        socket1.send(`{"subscribe": "nofirehose"}`);
                    }
                }
            });
          
        }
        
    //}
  }

  if (addPrice.match(action) === true) {
    fetch(`https://news.kryptrader.com/history?coin=${action.payload || ""}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(`Found ${data.length} history items for added price:`, action.payload);
        store.dispatch(addSymbolNews({ symbol: action.payload as string, news: data as News[] }));
      });
  }

  if (addSymbol.match(action) === true) {
    fetch(`https://news.kryptrader.com/history?coin=${(action.payload as AddSymbolPayload).symbol || ""}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(`Found ${data.length} history items for added symbol:`, action.payload);
        store.dispatch(addSymbolNews({ symbol: (action.payload as AddSymbolPayload).symbol as string, news: data as News[] }));
      });
  }

  next(action);
};



export default newsMiddleware;
